import { isMobile } from "react-device-detect";
import product2 from "./assets/NewImageTwo.png";
// import product2 from "./assets/NewImageOne.png";
import product from "./assets/NewImageOne.png";
import product_img from '../src/assets/product_img.png'
import product_img_red from '../src/assets/product_img_red.png'
import precision_product from '../src/assets/precision_product.png'
import precision_product2 from '../src/assets/precision_product_2.png'
export default [
  {
    id: 1,
    price: 85.00,
    shelf_life:"6 months",
    amount: 0,
    amount2:1,
    amount3:10,
    id1:1,
    mainHeading: "",
    pecriptionHeading: "Ideal for individual use",
    main_header: isMobile ? "Scentaware 16-scent Smell Test and Training kit"  : "Scentaware 16-scent Smell Test and Training kit",
    desc:"Smoke, Banana, Cloves, Eucalyptus, Lemon, Peppermint (Weak), Peppermint (Strong), Garlic, Cinnamon, Wood, Pineapple, Orange, Lavender, Coffee, Leather, Rose",
    img: product_img
  },
   {
     id: 2,
     price: 35.00,
     shelf_life:"6 months",
     amount: 0,
     amount2:1,
     amount3:0,
     id1:2,
     mainHeading: "",
     pecriptionHeading: "Ideal for individual use",
     main_header: isMobile ? "Scentaware 8-scent Smell Test and Training kit" : "Scentaware 8-scent Smell Test and Training kit",
     desc:"Banana, Lemon, Peppermint, Garlic, Cinnamon, Orange, Coffee, Rose.",
     img: product_img_red
   },
   {
    id: 3,
    price: 350.00,
    shelf_life:"2 years",
    amount: 0,
    amount2:1,
    amount3:0,
    id1:3,
    pecriptionHeading: "Ideal for research studies.",
    mainHeading: "Precision olfactory testing redefined",
    main_header: isMobile ? "Scentaware Pro 16-item Smell Test and Training kit" : "Scentaware Pro 16-item Smell Test and Training kit",
    desc:"An olfactory assessment kit that can be used on multiple individuals and used in conjunction with the Intellivista data management system for seamless data management and reporting.",
    img: precision_product
   },
   {
    id: 4,
    price: 70.00,
    shelf_life:"--",
    amount: 0,
    amount2:1,
    amount3:0,
    id1:4,
    pecriptionHeading: "Ideal for research studies.",
    mainHeading: "Create your own olfactory test",
    main_header: isMobile ? "Scentaware DIY kit" : "Scentaware DIY kit",
    desc:"Should your research necessitate olfactory stimulants or formats not available in existing tests, the ScentAware DIY kit could be the solution. This kit includes plastic parts and labels for assembling a custom 16-item olfactory test kit. Simply prepare your own scent stimulants, fill the provided tubes, and securely cap them.",
    img: precision_product2
   }
];