import React, { useState } from "react";
import styled from "styled-components";
import art1 from "../../assets/forgot.svg";
import pattern1 from "../../assets/Pattern1.svg";
import pattern2 from "../../assets/pattern2.svg";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Axios from "axios";
import config from "../../config";
import { isMobile } from "react-device-detect";
import logoBlack from "../../assets/logo-black.png";
import forgot_img from "../../assets/forgot-img.png";
import forgot_mobile from "../../assets/mobile_img.png";
import NavFooter from "../Reuseable Components/NavFooter";
import * as Yup from "yup";
import { useFormik } from "formik";

const initialValues = {
  email: "",
};

const ForgetPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailerror, setEmailerror] = useState("");

  const [load, setLoad] = useState(false);
  var obj = {};
  //  const handleSubmit = () => {
  //     navigate(`/Forgot_PasswordLink`)
  //  }

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is required!"),
  });

  // function validate() {
  //   let emailerror = "";

  //   ///VALIDATION CHECKS
  //   if (email.includes("@") === false) {
  //     emailerror = "Email is not valid";
  //   }

  //   if (emailerror !== "") {
  //     setEmailerror(emailerror);

  //     return false;
  //   }

  //   //////////////////////////////////////////////////////////////////

  //   if (emailerror) {
  //     console.log("Email error", emailerror);
  //     setEmailerror(emailerror);
  //     return false;
  //   }

  //   return true;
  // }

  const handleSubmit = (event) => {
    var checkin = false;

    if (formik.isValid) {
      obj = {
        EmailAddress: event.email,
        Url: "/forget-password",
      };
      obj["EmailAddress"] = event.email;

      var stat;
      var api = `${config.BASE_URL}`;
      setLoad(true);
      Axios.post(api, obj)
        .then((res) => {
          console.log("Axios Response: ", res.data);
          stat = res.data;

          console.log("stat", stat["StatusCode"]);

          if (stat["StatusCode"] === 200) {
            checkin = true;
            navigate(`/Forgot_PasswordLink`, { state: { email: event.email } });
            Swal.fire({
              icon: "success",
              title: "Forget Password!",
              text: `Password reset code has been sent to ${event.email}. Kindly Check your Email.`,
              showConfirmButton: false,
              timer: 3500,
            });
            var useridval = stat.Data.UserId;
            console.log("userid", useridval);
            setLoad(false);
            navigate("/Confirmation", {
              state: { userid: useridval, email: event.email },
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "Invalid!",
              text: stat["Message"],
              showCancelButton: false,
              confirmButtonText: "Try Again!",
            });
            setLoad(false);
          }
        })
        .catch((e) => {
          //     Swal.fire({
          //       icon: 'warning',
          //       title: 'Error!',
          //       text: e.Message,
          //       showCancelButton: false,
          //       confirmButtonText: 'Try Again!',
          //   })
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const Clickback = () => {
    //closemodel(false);
    navigate("/userProfile");
  };


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Wrapper>
      {/* <section className="thankyou-section" id="shop">
                <div className="container-fluid p-0">
                    <div>
                        <div className="card">
                            <div className="row">
                                <div className="col left-container">
                                    <section className='col-md-6 new'>
                                        <img src={art1} alt="Cart_icon" className='img-here' />
                                    </section>

                                    <section className='col-md-6 new'>
                                        <img src={pattern2} alt="Cart_icon" className='img-Pattern2' />
                                    </section>
                                </div>
                                <div className="col">
                                    <div className="title">
                                        <h4>Forget Your Password?</h4>
                                    </div>

                                    <div className="discription">
                                        No biggie, let’s fetch a new one for you. Enter your email ID below and we’ll send you a link to reset your password.
                                    </div>


                                    <label for="Email" className="label-align word">
                                        E-mail :
                                    </label>
                                    <div className="row">
                                        <div className="col">
                                            <input
                                                type="email"
                                                name="Email"
                                                placeholder=""
                                                value={email}
                                                onChange={(e) => { setEmail(e.target.value) }}
                                            /></div>
                                    </div>
                                    <div style={{ color: 'red', display: 'flex', width: 'max-content', marginTop: '4px', marginLeft: '55px' }}>{emailerror}</div>


                                    <div className="alignment-order">
                                        <div className="col continue">
                                            <button onClick={handleSubmit} type="submit" value="Submit">
                                                <a
                                                    style={{ textDecoration: "none", color: "white", textAlign: "center" }}
                                                >
                                                    Submit
                                                </a>
                                            </button>
                                            {isMobile ? <div>
                                                {load ? <h1 className={load ? 'loading-spinner' : 'LoginBtn'} disabled={load} style={{ color: 'blue', margin: "10px 0 150px 80px" }}></h1> : ''}
                                            </div>
                                                :
                                                <div>
                                                    {load ? <h1 className={load ? 'loading-spinner' : 'LoginBtn'} disabled={load} style={{ color: 'blue', margin: "10px 0 150px 140px" }}></h1> : ''}
                                                </div>
                                            }


                                        </div>
                                    </div>

                                    <section className='col-md-6 new'>
                                        <img src={pattern1} alt="Cart_icon" className='img-Pattern1' />
                                    </section>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      <body className="renaware">
        <header className="dark-header new_header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav className="navbar navbar-expand-lg p-0">
                  <Link to='/' onClick={() => window.scrollTo(0, 0)} className="navbar-brand" >
                    <img src={logoBlack}
                      width="140px"
                      alt />
                  </Link>
                </nav>
              </div>
            </div>
          </div>
        </header>

        <section className="login_page inner-banner-section sign_up_outer d-flex align-items-center">
          <div className="container">
            <div className="row flex-lg-row flex-md-column-reverse flex-column-reverse">
              <div
                className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center pb-lg-0 pb-md-0 pb-4">
                <div className="card_title w-100">
                  <h2 className="mb-lg-4 mb-md-3 mb-3">Ah, the mysterious
                    case of <br />the forgotten password...</h2>
                  <p className="text-start m-0">Oh no! Looks like your
                    password took a vacation without leaving a
                    forwarding<br />address. Don't panic! Enter your
                    email and we'll resurrect it from the
                    digital<br />Bermuda Triangle. </p>
                  <div className="mt-4 pt-lg-2 form_feild">
                    <form action className="w-100">
                      <div className="row">
                        <div
                          className="col-lg-7 col-md-12 col-sm-12 col-12">
                          <label for className="w-100">Enter your
                            email ID : </label>
                          <input type="mail"
                            className={`form-control ${formik.touched.email && formik.errors.email
                              ? "is-invalid"
                              : ""
                              }`}
                            name="email"
                            placeholder="Email"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            isValid={formik.isValid}
                            isTouched={formik.touched.email}
                            invalidFeedback={formik.errors.email} />
                          <div className="invalid-feedback">
                            {formik.errors.email}
                          </div>
                        </div>
                        <div
                          className="col-lg-7 col-md-12 col-sm-12 col-12 mt-lg-4 mt-4">
                          <button
                            onClick={formik.handleSubmit}
                            className="btn btn-primary mb-0">
                            {load ? (
                              <div className='w-100 align-items-center d-flex justify-content-center'>
                                <h1
                                  class="loader"
                                  disabled={load}
                                  style={{
                                    // color: "#101A36",
                                    margin: "-5px",
                                    width: '30px',
                                    height: '30px',
                                  }}
                                ></h1>
                              </div>
                            ) : (
                              "Send Verification code"
                            )}</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-0 mb-lg-5 pb-lg-4">
                <div className="forgot_img d-flex align-items-center justify-content-center">
                  <img src={forgot_img} className="d-lg-block d-md-none d-none" alt />
                  <img src={forgot_mobile} className="d-lg-none d-md-block d-block w-auto" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <NavFooter />
      </body>


      {/* Old----------------- */}

      {/*      
      <section className="thankyou-section" id="shop">
        <div className="container-fluid p-0 ">
          <div>
            <div className="card">
              <div className="col col-sm-1">
                <div
                  type="button"
                  title="close"
                  className="btn-close"
                  disabled
                  aria-label="Close"
                  onClick={Clickback}
                >
                  {" "}
                </div>
              </div>
              <div className="row text-center">
                <div className="col left-container">
                  <section className="col-md-6 new">
                    <img src={art1} alt="Cart_icon" className="img-here" />
                  </section>

                  <section className="col-md-6 new">
                    <img
                      src={pattern2}
                      alt="Cart_icon"
                      className="img-Pattern2"
                    />
                  </section>
                </div>

                <div className="col">
                  <div className="title">
                    <h4>Forget Your Password?</h4>
                  </div>

                  <div className="discription">
                    No biggie, let’s fetch a new one for you. Enter your email
                    ID below and we’ll send you a link to reset your password.
                  </div>

                  <div className="row email-section">
                    <div className="col col-md-12 col-sm-12 col-xs-12 ">
                      <label for="Email" className="label-align word">
                        E-mail :
                      </label>
                    </div>
                    <div className="col col-md-12 col-sm-12 col-xs-12">
                      <input
                        type="email"
                        name="Email"
                        placeholder=""
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div
                        style={{
                          color: "red",
                          display: "flex",
                          width: "max-content",
                          marginTop: "4px",
                          marginLeft: "55px",
                        }}
                      >
                        {emailerror}
                      </div>
                    </div>
                  </div>

                  <div className="alignment-order">
                    <div className="col continue">
                      <button
                        onClick={handleSubmit}
                        type="submit"
                        value="Submit"
                      >
                        <a
                          style={{
                            textDecoration: "none",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Submit
                        </a>
                      </button>
                      {isMobile ? (
                        <div>
                          {load ? (
                            <h1
                              className={load ? "loading-spinner" : "LoginBtn"}
                              disabled={load}
                              style={{
                                color: "blue",
                                margin: "10px 0 150px 80px",
                              }}
                            ></h1>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <div>
                          {load ? (
                            <h1
                              className={load ? "loading-spinner" : "LoginBtn"}
                              disabled={load}
                              style={{
                                color: "blue",
                                margin: "10px 0 150px 140px",
                              }}
                            ></h1>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <section className="col-md-6 new">
                    <img
                      src={pattern1}
                      alt="Cart_icon"
                      className="img-Pattern1"
                    />
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>  */}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .card {
    // position: absolute;
    width: 968px;
    height: 580px;
    left: 0;
    // top: 151px;

    background: #ffffff;
    box-shadow: 0px 4px 32px 16px rgba(30, 30, 30, 0.25);
    border-radius: 8px;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  .container-fluid {
    width: 63%;
  }
  .btn-close {
    margin-left: 10px;
    margin-top: 10px;
  }

  .thankyou-section {
    padding-top: 150px;
  }

  .title {
    // position: absolute;
    width: 307px;
    height: 28px;
    // left: 530px;
    // top: 50px;

    font-family: "WorkSans";

    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: start;
    color: #4e4e4e;
  }

  .discription {
    // position: absolute;
    width: 307px;
    height: 76px;
    // left: 530px;
    // top: 100px;

    font-family: "WorkSans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-top: 40px;
    margin-bottom: 40px;
    color: #4e4e4e;
    text-align: start;
  }

  .label-align {
    // position: absolute;
    width: 178px;
    height: 19px;
    // left: 533px;
    // top: 220px;

    font-family: "WorkSans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    color: #4e4e4e;
    margin-top: 40px;
  }

  input[type="email"] {
    box-sizing: border-box;
    //    position: absolute;
    width: 320px;
    height: 48px;
    // left: 510px;
    // top: 375px;
    background: #ffffff;
    border: 1px solid #26649d;
    border-radius: 8px;

    margin-bottom: 40px;
  }

  .alignment-order {
    // position: absolute;
    width: 320px;
    height: 48px;
    // left: 545px;
    // top: 350px;

    filter: drop-shadow(0px 16px 24px rgba(38, 100, 157, 0.3));
  }
  .continue {
    // margin-top: 10px;
    // margin-left: 20px;
  }
  .continue button {
    background-color: #22669c;
    /* box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3); */

    border: none;
    padding: 10px 0px;
    color: white;
    width: 100%;
  }

  .img-here {
    position: absolute;
    width: 370px;
    height: 300px;
    left: 80px;
    top: 100px;
  }

  .img-Pattern2 {
    position: absolute;
    width: 165px;
    height: 150px;
    left: 2px;
    top: 450px;

    mix-blend-mode: normal;
    border-radius: 8px 0px 0px 8px;
    transform: rotate(0deg);
  }

  .img-Pattern1 {
    position: absolute;
    width: 480px;
    height: 148px;
    left: 470px;
    top: 424px;

    mix-blend-mode: normal;
    opacity: 0.5;
    border-radius: 8px 0px 0px 8px;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .email-section {
    text-align: start;
  }
  @media Screen and (min-width: 996px) and (max-width: 1150px) {
    .container-fluid {
      width: 75%;
    }
    .card {
      width: auto;
    }
    .img-here {
      width: 320px;
      height: 200px;
    }
    .img-Pattern1 {
      width: 372px;
      height: 148px;
      left: 480px;
      top: 440px;
    }
  }

  @media Screen and (min-width: 320px) and (max-width: 995px) {
    .text-center {
      text-align: center;
    }
    .left-container {
      display: none;
    }

    .card {
      // position: absolute;
      width: auto;
      height: auto;
      left: auto;
      box-shadow: unset;
      border: none;
    }
    .new {
      display: none;
    }
    input[type="email"] {
      box-sizing: border-box;
      //    position: absolute;
      width: 290px;
      height: 48px;
      left: 0px;
      top: 375px;
      background: #ffffff;
      border: 1px solid #26649d;
      border-radius: 8px;
      margin-bottom: 20px;
    }
    .title {
      //   width: 307px;
      width: auto;
      height: 28px;
      margin-top: 10px;
      margin-bottom: 20px;
      font-family: "WorkSans";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #4e4e4e;
    }
    .discription {
      width: 307px;
      //   width: auto;

      font-family: "WorkSans";
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 19px;

      color: #4e4e4e;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
    }

    .label-align {
      //   width: 178px;
      text-align: start;
      width: 290px;
      height: 19px;
      margin-top: 0px;
      font-family: "WorkSans";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */
      color: #4e4e4e;
    }

    .alignment-order {
      //   position: absolute;
      //   width: 306px;
      //   height: 48px;
      //   left: 75px;
      //   top: 350px;
      width: auto;

      filter: drop-shadow(0px 16px 24px rgba(38, 100, 157, 0.3));
    }

    .continue {
      //   margin-top: 10px;
      //   position: absolute;
      margin-left: 0px;
    }
    .continue button {
      background-color: #22669c;
      /* box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3); */

      border: none;
      padding: 10px 0px;
      color: white;
      width: 290px;
    }

    .thankyou-section {
      padding-top: 100px;
    }
    .email-section {
      text-align: center;
    }
  }
`;

export default ForgetPassword;
