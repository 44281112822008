import React, { useState } from "react";
import styled from "styled-components";
import resetPasswordLeft from "../../assets/resetPasswordLeft.svg";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Axios from "axios";
import config from "../../config";
import logoBlack from "../../assets/logo-black.png";
import forgot_img from "../../assets/reset-password.png";
import forgot_mobile from "../../assets/reset-password_mobile.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import NavFooter from "../Reuseable Components/NavFooter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isMobile } from "react-device-detect";

const initialValues = {
  newPassword: "",
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  // const param = useParams()
  const location = useLocation();

  const confirmationCode = location.state?.code;
  const email = location.state?.email;

  const [newPassword, setNewPassword] = useState("");
  const [newConfirmPassword, setNewConfirmPassword] = useState("");
  // const [newpasswordConfirm, setNewPasswordConfirm] = useState("");
  const [load, setLoad] = useState(false);
  const [passworderror, setPassworderror] = useState("");
  // const [confirmpassworderror, setConfirmPassworderror] = useState("");
  var obj = {};

  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .required("Password is required!")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,16}$/,
        {
          message: "Password must be 8-16 characters and contain at least one number, one uppercase letter, one lowercase letter, and one special character, and cannot contain spaces.",
          excludeEmptyString: true,
        }
      )
      .matches(
        /^\S*$/,
        "Password cannot contain spaces."
      ),
  });

  // function validate() {
  //   let passworderror = "";
  //   // let confirmpassworderror = "";

  //   var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,20}$/;
  //   // var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

  //   if (newPassword === "") {
  //     passworderror = "Password is required";
  //   }

  //   if (!newPassword.match(passw)) {
  //     passworderror = "Password must have a number, lower case, uppercase letter and special character";
  //   }

  //   if (newPassword.length < 8) {
  //     passworderror = "Please Enter atleast 8 characters ";
  //   }

  //   // if (newPassword !== newConfirmPassword) {
  //   //   confirmpassworderror = "Password does not match";
  //   // }

  //   // if (newPassword == newConfirmPassword) {
  //   //   setNewPasswordConfirm(newConfirmPassword);
  //   // }

  //   // if (passworderror !== "" || confirmpassworderror !== "") {
  //   //   setPassworderror(passworderror);
  //   //   setConfirmPassworderror(confirmpassworderror);
  //   //   return false;
  //   // }

  //   //////////////////////////////////////////////////////////////////

  //   if (passworderror) {
  //     console.log("Password error", passworderror);
  //     setPassworderror(passworderror);
  //     return false;
  //   }

  //   // if (confirmpassworderror) {
  //   //   console.log("Password error", confirmpassworderror);
  //   //   setPassworderror(confirmpassworderror);
  //   //   return false;
  //   // }

  //   return true;
  // }

  const handleSubmit = (event) => {
    // 👇️ navigate programmatically
    console.log("---confirmationCode--->", confirmationCode);
    var checkin = false;

    // event.preventDefault();

    // const isValid = validate();
    // console.log("isValid", validate());

    if (formik.isValid) {
      obj = {
        Url: "/change-password",
        EmailAddress: email,
        ConfirmationCode: confirmationCode,
        NewPassword: event.newPassword,
      };
      obj["EmailAddress"] = email;
      obj["ConfirmationCode"] = confirmationCode;
      obj["NewPassword"] = event.newPassword;

      var stat;
      var api = `${config.BASE_URL}`;
      setLoad(true);
      Axios.post(api, obj)
        .then((res) => {
          setLoad(false);
          console.log("Axios Response: ", res.data);
          stat = res.data;

          console.log("stat", stat["StatusCode"]);

          if (stat["StatusCode"] === 200) {
            checkin = true;
            navigate(`/reset_Successfully`);
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Password Changed Successfully",
              showConfirmButton: false,
              timer: 3500,
            });
            var useridval = stat.Data.UserId;
            console.log("userid", useridval);
            setLoad(false);
            navigate("/Confirmation", {
              state: { userid: useridval, email: email },
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "Invalid!",
              text: stat["Message"],
              showCancelButton: false,
              confirmButtonText: "Try Again!",
            });
            setLoad(false);
          }
        })
        .catch((e) => {
          //   Swal.fire({
          //     icon: 'warning',
          //     title: 'Error!',
          //     text: e.Message,
          //     showCancelButton: false,
          //     confirmButtonText: 'Try Again!',
          // })
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Wrapper>
      <body className="renaware">
        <header className="dark-header new_header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav className="navbar navbar-expand-lg p-0">
                  <Link to='/' onClick={() => window.scrollTo(0, 0)} className="navbar-brand">
                    <img src={logoBlack}
                      width="140px"
                      alt />
                  </Link>
                </nav>
              </div>
            </div>
          </div>
        </header>

        <section className="login_page inner-banner-section sign_up_outer d-flex align-items-center">
          <div className="container">
            <div
              className="row flex-lg-row flex-md-column-reverse flex-column-reverse">
              <div
                className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center pb-lg-0 pb-md-0 pb-4">
                <div className="card_title w-100 forgot_pwd">
                  <h2 className="mb-lg-4 mb-md-3 mb-3">Reset your
                    password.</h2>
                  <p className="text-start m-0">Enter a brand-spanking-new
                    password.<br />
                    Remember, a strong password is :</p>
                  <ul>
                    <li>8-16 characters long.</li>
                    <li>Contains a mix of upper and lower case
                      letters.</li>
                    <li>Contains numeric and special
                      characters.</li>
                    <li>Not your birthdate.</li>
                  </ul>
                  <div className="mt-4 pt-lg-2 form_feild">
                    <form action>
                      <div className="row">
                        <div
                          className="col-lg-7 col-md-12 col-sm-12 col-12">
                          <label for className="w-100">Enter password : </label>
                          <div className="position-relative">
                            <input type={showPassword ? "text" : "password"}
                              className={`form-control w-100 ${formik.touched.newPassword &&
                                formik.errors.newPassword
                                ? "is-invalid"
                                : ""
                                }`}
                              id="newPassword"
                              name="newPassword"
                              placeholder="Password"
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.newPassword}
                            />
                            <div className="invalid-feedback">
                              {formik.errors.newPassword}
                            </div>
                            <div className="eye">
                              {/* <img
                                src={Visibility} /> */}
                              {showPassword ? (
                                <VisibilityIcon
                                  onClick={() => setShowPassword(!showPassword)}
                                  className="password-icon"
                                />
                              ) : (
                                <VisibilityOffIcon
                                  onClick={() => setShowPassword(!showPassword)}
                                  className="password-icon"
                                />

                              )}

                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-7 col-md-12 col-sm-12 col-12 mt-lg-4 mt-3">
                          <button onClick={formik.handleSubmit} type="submit"
                            className="btn btn-primary mb-0">
                            {load ? (
                              <div className='w-100 align-items-center d-flex justify-content-center'>
                                <h1
                                  class="loader"
                                  disabled={load}
                                  style={{
                                    // color: "#101A36",
                                    margin: "-5px",
                                    width: '30px',
                                    height: '30px',
                                  }}
                                ></h1>
                              </div>
                            ) : (
                              "Submit"
                            )}</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-4 mt-0 mb-lg-0 mb-md-0 mb-4 pb-lg-0">
                <div
                  className="forgot_img d-flex align-items-center justify-content-center">
                  <img src={forgot_img}
                    className="d-lg-block d-md-none d-none" alt />
                  <img src={forgot_mobile}
                    className="d-lg-none d-md-block d-block w-auto" alt />
                </div>
              </div>
            </div>
          </div>
        </section>
        <NavFooter />
      </body>


      {/* old ---- */}
      {/* <div className="row">
        <div className="col col-md-4 col-sm-4 col-xs-12 left_Container">
          <div className="left-card">
            <section className="col-md-6 new">
              <img
                src={resetPasswordLeft}
                alt="Cart_icon"
                className="img-resetPasswordLeft"
              />
            </section>
          </div>
        </div>

        <div className="col right_Container">
          <div className="card">
            <div className="card-right-bar">
              <div className="title">
                <h4>Reset password</h4>
                <p className="label-align">
                  {" "}
                  We recommend using 8-16 characters for the password. A good
                  mix of special characters, numbers, lower and upper case
                  letters make for a strong password.
                </p>
              </div>

              <div className="row" style={{ marginTop: "22px" }}>
                <label for="Password" className="label-align">
                  New password :
                </label>

                <input
                  type="password"
                  name="Password"
                  placeholder="Password"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
                <div style={{ color: "red" }}>{passworderror}</div>
              </div>

              <div className="row" style={{ marginTop: "18px" }}>
                <label for="Password" className="label-align">
                  Confirm new Password :
                </label>
                <input
                  type="password"
                  name="ConfirmPassword"
                  placeholder="Confirm Password"
                  value={newConfirmPassword}
                  onChange={(e) => {
                    setNewConfirmPassword(e.target.value);
                  }}
                />
                <div style={{ color: "red" }}>{confirmpassworderror}</div>
              </div>
              <div className="alignment-order">
                <div className="col continue">
                  <button onClick={handleSubmit} type="submit" value="Submit">
                    Reset password
                  </button>
                  {load ? (
                    <h1
                      className={load ? "loading-spinner" : "Login"}
                      disabled={load}
                      style={{ color: "blue", margin: "10px 0 150px 120px" }}
                    ></h1>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .left_Container {
    // position: absolute;
    width: 628px;
    height: 781px;
    // left: 0px;
    // top: 0px;
    padding: 0;
    padding-top: 150px;
    background: #26649d;
  }
  .right_Container {
    padding: 0;
    padding-top: 150px;
  }

  .card {
    // position: relative;
    width: 612px;
    height: 590px;
    // left: 628px;
    // top: 151px;

    background: #ffffff;
    box-shadow: 0px 4px 32px 16px rgba(30, 30, 30, 0.25);
    border-radius: 8px;
  }

  .title {
    // position: absolute;
    // width: 307px;
    // height: 28px;
    // left: 140px;
    // top: 50px;

    font-family: "WorkSans";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-top: 40px;
    color: #4e4e4e;
  }

  .discription {
    // position: absolute;
    width: 307px;
    height: 76px;
    // left: 140px;
    // top: 100px;

    font-family: "WorkSans";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 19px;

    color: #4e4e4e;
  }

  .label-align {
    // position: absolute;
    // width: 178px;
    // height: 19px;
    // left: 140px;
    // top: 190px;

    font-family: "WorkSans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    color: #4e4e4e;
  }

  input[type="password"] {
    box-sizing: border-box;
    //   position: absolute;
    width: 328px;
    margin-top: 10px;
    margin-left: 10px;
    height: 48px;
    left: 730px;
    top: 260px;
    background: #ffffff;
    border: 1px solid #26649d;
    border-radius: 8px;
    padding-left: 20px;
    // margin-left:124px;
    // margin-top:240px;
  }

  .row1 {
    margin-left: 138px;
    height: 18px;
  }

  .left-card {
    // position: absolute;
    width: 360px;
    height: 590px;
    // left: 270px;
    // top: 151px;

    background: #26649d;
    box-shadow: 0px 4px 32px 16px rgba(30, 30, 30, 0.25);
    border-radius: 8px;
    float: right;
  }

  .img-resetPasswordLeft {
    // position: absolute;
    // width: 347.59px;
    // height: 499.96px;
    // left: 0px;
    // top: 42px;
  }

  .alignment-order {
    // position: absolute;
    width: 328px;
    height: 48px;
    // left: 80px;
    // top: 420px;

    filter: drop-shadow(0px 16px 24px rgba(38, 100, 157, 0.3));
  }

  .continue {
    margin-top: 10px;
  }

  .continue button {
    background-color: #22669c;
    /* box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3); */
    font-family: "WorkSans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border: none;
    padding: 10px 0px;
    color: white;
    width: 100%;
    margin-top: 20px;
  }
  .card-right-bar {
    margin: 0px 0px 0px 80px;
    width: 80%;
  }

  @media Screen and (min-width: 996px) and (max-width: 1215px) {
    .left_Container {
      width: 490px;
    }
    .left-card {
      width: 280px;
    }
    .card {
      width: 450px;
    }
    .card-right-bar {
      margin: 0px 0px 0px 50px;
    }
  }

  @media Screen and (min-width: 320px) and (max-width: 995px) {
    .left_Container {
      display: none;
    }

    .card {
      width: 355px;
      height: auto;
      background: #ffffff;
      box-shadow: unset;
      border-radius: 8px;
      border: none;
      margin: auto auto;
      margin-bottom: 60px;
    }

    .alignment-order {
      // position: absolute;
      // width: 306px;
      // height: 48px;
      // left: 60px;
      // top: 480px;

      filter: drop-shadow(0px 16px 24px rgba(38, 100, 157, 0.3));
    }

    .continue {
      margin-top: 10px;
    }

    .continue button {
      background-color: #22669c;
      /* box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3); */

      border: none;
      padding: 10px 0px;
      color: white;
      // width: 85%;
    }
    .card-right-bar {
      margin: 0;
      width: auto;
    }
    .title {
      margin-top: 0px;
    }
  }
`;

export default ResetPassword;
