import React from "react";
import "./styles.css";
import "./landing.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
const stripePromise = loadStripe("pk_live_51LOAgCLocYDVe0iYBLe4CvZXD8rrR55x76RP0dwZXLPlvSOb00C24M8jlwdHsMFrra4d4rPUtrkAECsX4mi2JAaK00UJ6FhaxM");
// const stripePromise = loadStripe("pk_test_51LOAgCLocYDVe0iYLVV1VLadghurJPIunOlYlLuQfHr2kYSiWwAqj8nIbBd4rmKoJQ5DRQyquix1Q1BrssP6xRGP00XqsUlfCk");


const App = (props) => {
  return (
    <div>
      <Elements stripe={stripePromise} >
        <CheckoutForm />
      </Elements>
    </div>

  );
};
export default App;