import React from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import outline from "../../assets/outline.svg";
import { useState } from "react";
import Swal from "sweetalert2";
import Axios from "axios";
import art1 from "../../assets/art.png";
import "../spinner.css";
import config from "../../config";
import resetPasswordLeft from "../../assets/resetPasswordLeft.svg";
import { isMobile } from "react-device-detect";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import logoBlack from "../../assets/logo-black.png";
import Visibility from "../../assets/Visibility.png";
import Modal from 'react-modal';
import Signin from "../Modal/Signin";
import NavFooter from "../Reuseable Components/NavFooter";
import * as Yup from "yup";
import { useFormik } from "formik";

const initialValues = {
  firstName: "",
  lastName: "",
  gender: "",
  age: "",
  Email: "",
  Password: "",
};

function Signup() {
  const [firstName, setFirstName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [load, setLoad] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  var obj = {};
  const navigate = useNavigate();
  function handleClickSignup() {
    navigate("/Signup");
  }

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Please enter valid first name")
      .required("First Name is required!"),
    lastName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Please enter valid Last Name")
      .required("Last Name is required!"),
    // age: Yup.string().required("Age is required!"),
    age: Yup.number().required("Age is required").min(1, "Age is greater than 0").max(99, "Age must be less than 100"),
    gender: Yup.string().required("Gender is required!"),
    // Your other validation rules...
    Email: Yup.string()
      .email("Invalid email address")
      .required("Email is required!"),
    Password: Yup.string()
      .required("Password is required!")
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,16}$/, {
        message:
          "Password must be 8-16 characters and contain at least one number, one uppercase letter, one lowercase letter, and one special character, and cannot contain spaces.",
        excludeEmptyString: true,
      })
      .matches(/^\S*$/, "Password cannot contain spaces."),
  });

  const Clickback = () => {
    //closemodel(false);
    navigate("/Signin");
  };

  const handleClick = (event) => {
    // 👇️ navigate programmatically
    var checkin = false;
    // event.preventDefault();
    // const isValid = validate();
    // console.log("isValid", validate());
    if (formik.isValid) {
      setLoad(true);
      obj = {
        EmailAddress: "myemail121@gmail.com",
        Password: "PAin77@!",
        confirmPassword: "PAin77@!",
        // "UserName":"Ali Hamza",
        FirstName: "Ali",
        LastName: "Hamza",
        PhoneNumber: "+923214186535",
        Url: "/signup",
        Gender: "male",
        IsStuffyNose: false,
        IsThereLossOfMemory: false,
        Medication: "",
        Age: 24,
      };
      obj["EmailAddress"] = event.Email;
      obj["Password"] = event.Password;
      obj["confirmPassword"] = event.Password;
      //  obj["UserName"]=firstName+','+ lastName   //Name
      obj["FirstName"] = event.firstName;
      obj["LastName"] = event.lastName;
      obj["Age"] = event.age;
      obj["Gender"] = event.gender;

      //  console.log(obj["UserName"]);

      var stat;
      var api = `${config.BASE_URL}`;
      Axios.post(api, obj).then((res) => {
        console.log("Axios Response: ", res.data);
        stat = res.data;

        console.log("stat", stat["StatusCode"]);

        if (stat["StatusCode"] === 200) {
          checkin = true;
          Swal.fire({
            icon: "success",
            title: "Sign Up Success!",
            text: `${firstName} has been Signed Up. Kindly Confirm your Email Address.`,
            showConfirmButton: false,
            timer: 3500,
          });
          var useridval = stat.Data.UserId;
          console.log("userid", useridval);
          localStorage.setItem("SignupData", JSON.stringify(obj));
          setLoad(false);
          navigate("/Confirmation", {
            state: { userid: useridval, email: event.Email },
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "Invalid!",
            text: stat["Message"],
            showCancelButton: false,
            confirmButtonText: "Try Again!",
          });
          setLoad(false);
        }
      });
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleClick,
  });

  const handleSignInSuccess = (newUserName) => {
    navigate("/");
  };
  return (
    <>
      <body className="renaware">
        <header class="dark-header new_header">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <nav class="navbar navbar-expand-lg p-0">
                  <Link to='/' onClick={() => window.scrollTo(0, 0)} class="navbar-brand">
                    <img src={logoBlack}
                      width="140px"
                      alt />
                  </Link>
                </nav>
              </div>
            </div>
          </div>
        </header>


        <section
          class="login_page inner-banner-section sign_up_outer d-flex align-items-center">
          <div class="container">
            <div class="row justify-content-center">
              <div
                class="col-lg-4 col-md-6 col-sm-12 col-12 px-lg-3 px-md-3 px-4">
                <div class="login_outer_m position-relative">
                  <div
                    class="login_outer form_feild sign_up_form py-0"><div
                      class="sign_up card_title ">
                      <h2
                        class="mb-lg-4 mb-md-4 mb-4 text-center pb-lg-2">Sign
                        up!</h2>
                    </div>

                    <form action>
                      <div class="row g-lg-2 g-md-3 g-3">

                        <div
                          class="col-lg-6 col-md-6 col-sm-6 col-6">
                          <label for class="w-100">First
                            name
                            :</label>
                          <input type="text"
                            id="firstName"
                            className={`form-control ${formik.touched.firstName && formik.errors.firstName
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="First name"
                            name="firstName"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            isValid={formik.isValid}
                            isTouched={formik.touched.firstName}
                            invalidFeedback={formik.errors.firstName}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.firstName}
                          </div>
                          {/* <div className="text-danger">{firstnameerror}</div> */}
                        </div>
                        <div
                          class="col-lg-6 col-md-6 col-sm-6 col-6">
                          <label for class="w-100">Last
                            name
                            :</label>
                          <input type="text"
                            id="lastName"
                            className={`form-control ${formik.touched.lastName && formik.errors.lastName
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="Last name"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            isValid={formik.isValid}
                            isTouched={formik.touched.lastName}
                            invalidFeedback={formik.errors.lastName}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.lastName}
                          </div>
                        </div>
                        <div class="col-12">
                          <label for class="w-100">Age
                            :</label>
                          <input
                            id="age"
                            type="number"
                            className={`form-control ${formik.touched.age && formik.errors.age
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="Age"
                            name="age"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.age}
                            isValid={formik.isValid}
                            isTouched={formik.touched.age}
                            invalidFeedback={formik.errors.age}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.age}
                          </div>
                        </div>

                        {/* <div class="col-12">
                          <div
                            class="select_box position-relative">
                            <label for class="w-100">Gender:</label>
                            <select name="gender"
                              id="gender"
                              className={`form-control ${formik.touched.gender && formik.errors.gender
                                ? "is-invalid"
                                : ""
                                }`}
                              placeholder="Select"
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.gender}
                              isValid={formik.isValid}
                              isTouched={formik.touched.gender}
                              invalidFeedback={formik.errors.gender}
                            >
                              <option value>What's
                                your
                                gender?</option>
                              <option
                                value="Male">Male</option>
                              <option
                                value="Female">Female</option>
                            </select>
                            <div class="select_arrow">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none">
                                <path
                                  d="M16.7998 9.59999L11.9998 14.4L7.19981 9.59999"
                                  stroke="black"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" />
                              </svg>
                            </div>
                            <div className="invalid-feedback">
                              {formik.errors.gender}
                            </div>
                          </div>
                        </div> */}

                        <div class="col-12">
                          <div
                            class="select_box position-relative">
                            <label for class="w-100">Gender:</label>
                            <div class="dropdown">
                              <button
                                className="btn btn-secondary d-flex align-items-center justify-content-center justify-content-between dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {formik.values.gender || "Select"}

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M16.8 9.60039L12 14.4004L7.19999 9.60039" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </button>
                              <ul class="dropdown-menu">
                                <li><a class="dropdown-item" onClick={() => formik.setFieldValue("gender", "Male")}>Male</a></li>
                                <li><a class="dropdown-item" onClick={() => formik.setFieldValue("gender", "Female")}>Female</a></li>
                              </ul>
                            </div>
                            {formik.touched.gender && formik.errors.gender ? (
                            <div className="text-danger">{formik.errors.gender}</div>
                          ) : null}
                          </div>
                        </div>

                        <div class="col-12">
                          <label for class="w-100">Email
                            ID
                            :</label>
                          <input type="mail"
                            id="Email"
                            className={`form-control ${formik.touched.Email && formik.errors.Email
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="Email id"
                            name="Email"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.Email}
                            isValid={formik.isValid}
                            isTouched={formik.touched.Email}
                            invalidFeedback={formik.errors.Email}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.Email}
                          </div>
                        </div>
                        <div
                          class="col-12 position-relative">
                          <label for
                            class="w-100">Password
                            :</label>
                          <div class="position-relative">
                            <input
                              id="Password"
                              className={`form-control ${formik.touched.Password && formik.errors.Password
                                ? "is-invalid"
                                : ""
                                }`}
                              placeholder="Password"
                              type={showPassword ? "text" : "password"}
                              name="Password"
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.Password}
                              isValid={formik.isValid}
                              isTouched={formik.touched.Password}
                              invalidFeedback={formik.errors.Password} />
                            <div class="eye">
                              {showPassword ? (
                                <VisibilityIcon
                                  onClick={() => setShowPassword(!showPassword)}
                                  className="password-icon"
                                />
                              ) : (
                                <VisibilityOffIcon
                                  onClick={() => setShowPassword(!showPassword)}
                                  className="password-icon"
                                />

                              )}

                            </div>
                            <div className="invalid-feedback">
                              {formik.errors.Password}
                            </div>
                          </div>
                        </div>
                        <div class="col-12 mt-lg-2 mt-2">
                          <div class="pwd_note">
                            <p class="mb-1 w-100">A
                              strong
                              password contains
                              :</p>
                            <div class="row">
                              <div
                                class="col-lg-7 col-md-7 col-12">
                                <ul>
                                  <li>8-16
                                    characters</li>
                                </ul>
                              </div>
                              <div
                                class="col-lg-5 col-md-5 col-12">
                                <ul>
                                  <li>Number</li>
                                </ul>
                              </div>
                              <div
                                class="col-lg-7 col-md-7 col-12">
                                <ul>
                                  <li>Upper &
                                    lower
                                    case
                                    letters</li>
                                </ul>
                              </div>
                              <div
                                class="col-lg-5 col-md-5 col-12">
                                <ul>
                                  <li>Special
                                    character</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 mt-4 pt-lg-2" >
                          <button type="submit"
                            class="btn btn-primary mb-0" onClick={formik.handleSubmit}
                          >
                             {load ? (
                              <div className='w-100 align-items-center d-flex justify-content-center'>
                                <h1
                                  class="loader"
                                  disabled={load}
                                  style={{
                                    // color: "#101A36",
                                    margin: "-5px",
                                    width: '30px',
                                    height: '30px',
                                  }}
                                ></h1>
                              </div>
                            ) : (
                              "Sign me up!"
                            )}</button>
                          {/* {isMobile ? (
                            <div>
                              {load ? (
                                <h1
                                  className={load ? "loading-spinner" : "LoginBtn"}
                                  disabled={load}
                                  style={{
                                    color: "blue",
                                    margin: "10px 0 150px 80px",
                                  }}
                                ></h1>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <div>
                              {load ? (
                                <h1
                                  className={load ? "loading-spinner" : "LoginBtn"}
                                  disabled={load}
                                  style={{
                                    color: "blue",
                                    margin: "10px 0 150px 140px",
                                  }}
                                ></h1>
                              ) : (
                                ""
                              )} 
                            </div>
                          )}*/}
                        </div>
                        <div class="col-12 mt-4 pt-lg-2">
                          <div class="card_title">
                            <p class="m-0">Already have an
                              account? <Link onClick={openModal}
                                style={{ color: '#03529c', textDecoration: 'none' }}>Go
                                to Login.</Link></p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NavFooter />
      </body>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className='login_modal'
      >
        <Signin closeModal={closeModal} onSignInSuccess={handleSignInSuccess}/>
      </Modal>
    </>
  );
}

// const Wrapper = styled.section`
//   .left_Container {
//     // position: absolute;
//     // width: 628px;
//     width: 40%;
//     // height: 781px;
//     height: 100%;
//     // left: 0px;
//     // top: 0px;

//     padding: 0;
//     padding-top: 150px;
//     background: #26649d;
//   }
//   .right_Container {
//     padding: 0;
//     padding-top: 150px;
//     margin-left: 0;
//   }

//   .confirm-password-icon {
//     // position: absolute;
//     // top: 352px;
//     // left: 385px;
//     // padding: 0px;
//     // margin-top: 22px;
//     margin-left: -40px;
//   }

//   input.confirm-password-input {
//     // top: 332px !important;
//     // width: 327px !important;
//   }

//   input.input-password {
//     // top: 332px !important;
//     // width: 333px !important;
//   }

//   .password-icon {
//     // position: absolute;
//     // top: 352px;
//     // left: 385px;
//     // margin-top: 22px;
//     margin-left: -40px;
//   }

//   .left-card {
//     // position: absolute;
//     // width: 360px;
//     width: 56%;
//     height: 635px;
//     // left: 270px;
//     // top: 151px;
//     float: right;
//     background: #26649d;
//     box-shadow: 0px 4px 32px 16px rgba(30, 30, 30, 0.25);
//     border-radius: 8px;
//   }

//   .right-card {
//     // position: absolute;
//     // width: 612px;
//     width: 66%;
//     height: 635px;
//     // left: 628px;
//     // top: 151px;

//     background: #ffffff;
//     box-shadow: 0px 4px 32px 16px rgba(30, 30, 30, 0.25);
//     border-radius: 8px;
//   }
//   .img-resetPasswordLeft {
//     // position: absolute;
//     // width: 347.59px;
//     // height: 499.96px;
//     // left: 0px;
//     // top: 42px;
//     padding-top: 35px;
//   }

//   .yourcart {
//     // position: absolute;
//     // width: 225px;
//     // height: 28px;
//     // left: 84px;
//     font-family: "WorkSans";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 24px;
//     line-height: 28px;
//     color: #4e4e4e;
//     padding-top: 35px;
//     margin-bottom: 25px;
//   }

//   input[type="text"] {
//     box-sizing: border-box;
//     //  position: absolute;
//     // width: 152px;
//     height: 40px;
//     // left: 100px;
//     // top: 150px;
//     background: #ffffff;
//     border: 1px solid #26649d;
//     border-radius: 8px;
//     margin-top: 7px;
//   }

//   .input-Lname {
//     box-sizing: border-box;
//     //  position: absolute;
//     // width: 152px;
//     // left: 185px;
//     // top: 0px;
//     background: #ffffff;
//     border-radius: 8px;
//   }

//   input[type="email"] {
//     box-sizing: border-box;
//     //  position: absolute;
//     // width: 328px;
//     height: 40px;
//     // left: 100px;
//     // top: 240px;
//     background: #ffffff;
//     border: 1px solid #26649d;
//     border-radius: 8px;
//     margin-top: 10px;
//     margin-bottom: 15px;
//     padding-left: 20px;
//     width: 100%;
//   }

//   input[type="password"] {
//     box-sizing: border-box;
//     // position: absolute;
//     // width: 328px;
//     margin-top: 10px;
//     height: 40px;
//     // left: 100px;
//     // top: 330px;
//     background: #ffffff;
//     border: 1px solid #26649d;
//     border-radius: 8px;
//     padding-left: 20px;
//     width: 100%;
//   }

//   .input-CPassword {
//     box-sizing: border-box;
//     // position: absolute;
//     // width: 328px;
//     // left: 12px;
//     // top: 96px;
//     background: #ffffff;
//     border-radius: 8px;
//     // padding-left:20px;
//   }

//   .row1 {
//     margin-left: 15px;
//     height: 18px;
//   }

//   .label-align-first {
//     // position: absolute;
//     // top:132px;
//     //   left:100px;
//     font-family: "WorkSans";
//     font-size: 16px;
//     font-weight: 500;
//     line-height: 19px;
//   }
//   .label-align-last {
//     // position: absolute;
//     // top:132px;
//     //   left:278px;
//     font-family: "WorkSans";
//     font-size: 16px;
//     font-weight: 500;
//     line-height: 19px;
//   }
//   .label-align-email {
//     // position: absolute;
//     // top:226px;
//     //   left:100px;
//     font-family: "WorkSans";
//     font-size: 16px;
//     font-weight: 500;
//     line-height: 19px;
//   }

//   .label-align-password {
//     // position: absolute;
//     // top:316px;
//     //   left:100px;
//     font-family: "WorkSans";
//     font-size: 16px;
//     font-weight: 500;
//     line-height: 19px;
//   }
//   .label-align-Cpassword {
//     // position: absolute;
//     // top:410px;
//     //   left:100px;
//     font-family: "WorkSans";
//     font-size: 16px;
//     font-weight: 500;
//     line-height: 19px;
//     margin-top: 15px;
//   }

//   .continue {
//     // position: absolute;
//     // top: 520px;
//     // left: 100px;
//   }
//   .continue button {
//     background-color: #22669c;
//     border: none;
//     padding: 10px 0px;
//     color: white;
//     // width:328px;
//     width: 100%;
//     margin-top: 35px;
//   }

//   .firstName-error {
//     color: red;
//     // position:absolute;
//     // top:202px;
//     // left:106px
//   }

//   .lastName-error {
//     color: red;
//     // position:absolute;
//     // top:202px;
//     // left:280px
//   }

//   .email-error {
//     color: red;
//     // position:absolute;
//     // left:94px;
//     // top:296px;
//   }

//   .password-error {
//     color: red;
//     // position:absolute;
//     // top:386px;
//     // left:94px
//   }
//   .Cpassword-error {
//     color: red;
//     // position:absolute;
//     // top:480px;
//     // left:94px;
//   }
//   .card-right-bar {
//     margin: 0px 0px 0px 80px;
//     width: 80%;
//   }

//   @media Screen and (min-width: 996px) and (max-width: 1215px) {
//     .left_Container {
//       width: 41%;
//     }
//     .left-card {
//       width: 50%;
//     }
//     .right-card {
//       width: 66%;
//     }
//     .card-right-bar {
//       margin: 0px 0px 0px 60px;
//     }
//   }

//   @media Screen and (min-width: 320px) and (max-width: 995px) {
//     .left_Container {
//       display: none !important;
//     }
//     .password-icon {
//       // left: 238px !important;
//       // top: 470px !important;
//     }
//     .confirm-password-icon {
//       // top: 468px;
//       // left: 238px;
//     }

//     .right-card {
//       // position: relative;
//       width: auto;
//       height: 780px;
//       // left: 40px;
//       // top: 120px;

//       background: #ffffff;
//       box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
//       border-radius: 0px;
//     }

//     input.confirm-password-input {
//       top: 453px !important;
//     }

//     input.input-password {
//       // top: 449px !important;
//       // width: 275px !important;
//     }

//     .yourcart {
//       // position: absolute;
//       // width: 200px;
//       // height: 28px;
//       // left: 0px;
//       font-family: "WorkSans";
//       font-style: normal;
//       font-weight: 500;
//       font-size: 33px;
//       line-height: 28px;
//       color: #4e4e4e;
//     }

//     input[type="text"] {
//       box-sizing: border-box;
//       // position: absolute;
//       // width: 280px;
//       height: 48px;
//       // left: 0px;
//       // top: 160px;
//       // padding:20px
//       background: #ffffff;
//       border: 1px solid #26649d;
//       border-radius: 8px;
//       // margin-top: 7px;
//     }

//     .input-Lname {
//       box-sizing: border-box;
//       // position: absolute;
//       // width: 280px;
//       // left: 12px;
//       // top: 100px;
//       background: #ffffff;
//       border-radius: 8px;
//     }

//     input[type="email"] {
//       box-sizing: border-box;
//       // position: absolute;
//       // width: 280px;
//       height: 48px;
//       // left: 0px;
//       // top: 350px;
//       background: #ffffff;
//       border: 1px solid #26649d;
//       border-radius: 8px;
//       margin-top: 10px;
//       margin-bottom: 15px;
//       padding-left: 15px;
//     }

//     input[type="password"] {
//       box-sizing: border-box;
//       // position: absolute;
//       // width: 280px;
//       margin-top: 10px;
//       height: 48px;
//       // left: 1px;
//       // top: 450px;
//       background: #ffffff;
//       border: 1px solid #26649d;
//       border-radius: 8px;
//       padding-left: 15px;
//     }

//     .input-CPassword {
//       box-sizing: border-box;
//       // position: absolute;
//       // width: 280px;
//       // left: 0px
//       // top: 50px;
//       background: #ffffff;
//       border-radius: 8px;
//       padding-left: 15px;
//     }

//     .label-align-first {
//       // position: absolute;
//       // top:140px;
//       // left:10px;
//       font-family: "WorkSans";
//       font-size: 20px;
//       font-weight: 500;
//       line-height: 19px;
//     }
//     .label-align-last {
//       // position: absolute;
//       // top:240px;
//       // left:10px;
//       font-family: "WorkSans";
//       font-size: 20px;
//       font-weight: 500;
//       line-height: 19px;
//     }
//     .label-align-email {
//       // position: absolute;
//       // top:335px;
//       //   left:10px;
//       font-family: "WorkSans";
//       font-size: 20px;
//       font-weight: 500;
//       line-height: 19px;
//     }

//     .label-align-password {
//       // position: absolute;
//       // top:430px;
//       // left:10px;
//       font-family: "WorkSans";
//       font-size: 20px;
//       font-weight: 500;
//       line-height: 19px;
//     }
//     .label-align-Cpassword {
//       // position: absolute;
//       // top:530px;
//       //   left:10px;
//       font-family: "WorkSans";
//       font-size: 20px;
//       font-weight: 500;
//       line-height: 19px;
//     }

//     .continue {
//       // position: absolute;
//       top: 650px;
//       left: 45px;
//     }
//     .continue button {
//       background-color: #22669c;
//       border: none;
//       padding: 10px 0px;
//       color: white;
//       // width: 200px;
//     }

//     .firstName-error {
//       color: red;
//       // position:absolute;
//       // top:212px;
//       // left:10px
//     }

//     .lastName-error {
//       color: red;
//       // position:absolute;
//       // top:312px;
//       // left:10px
//     }

//     .email-error {
//       color: red;
//       // position:absolute;
//       left: 0px;
//       top: 408px;
//     }

//     .password-error {
//       color: red;
//       // position:absolute;
//       // top:508px;
//       // left:0px
//     }
//     .Cpassword-error {
//       color: red;
//       // position:absolute;
//       // top:604px;
//       // left:0px;
//     }
//     .card-right-bar {
//       // margin: 0px 0px 0px 50px;
//       margin: auto auto;
//     }
//     .right_Container {
//       margin: auto auto;
//     }
//   }

//   // .Modalback{
//   //     // width:500vw;
//   //     // height:100vh;
//   //     // background-color:white;
//   //     // position:fixed;
//   //     // display: flex;
//   //     // justify-content: center;
//   //     // align-items: center;
//   // }

//   // .img-here {
//   //     height: 100%;
//   //     width: 100%;
//   //     margin-left: 0px;
//   //     margin-top: 0px;
//   //   }

//   // .new{
//   //     background-color: #26649d;
//   //     margin-left: -15px;
//   //     padding-bottom:1000px;
//   //     padding-right: 0px;
//   // }

//   // .pad-right{
//   //     padding-right: 10px;
//   //     padding-left: 100px;
//   //     margin-top: 120px;
//   //     background: white;

//   // }

//   // .word{

//   //     font-family: 'WorkSans';
//   //     font-style: normal;
//   //     font-weight: 500;
//   //     font-size: 16px;
//   //     line-height: 19px;
//   //     /* identical to box height */

//   //     color: #4E4E4E;

//   // }

//   // .yourcart {
//   //     padding-top: 68px;
//   //     font-size: var(--font-size-24px);
//   //     font-weight: var(--font-weight-500);
//   //     line-height: 28px;
//   //   }
//   //   input[type="text"] {
//   //     box-sizing: border-box;
//   //    // position: absolute;
//   //     width: 152px;
//   //     height: 48px;
//   //     left: 730px;
//   //     top: 385px;
//   //     background: #FFFFFF;
//   //     border: 1px solid #26649D;
//   //     border-radius: 8px;
//   //     margin-top: 7px;
//   //   }

//   //   input[type="email"] {
//   //     box-sizing: border-box;
//   // //    position: absolute;
//   //     width: 328px;
//   //     height: 48px;
//   //     left: 730px;
//   //     top: 385px;
//   //     background: #FFFFFF;
//   //     border: 1px solid #26649D;
//   //     border-radius: 8px;
//   //     margin-top: 10px;
//   //     margin-bottom:15px;
//   //     padding-left:20px;
//   //   }

//   //   input[type="password"] {
//   //     box-sizing: border-box;
//   //   //   position: absolute;
//   //     width: 328px;
//   //     margin-top: 10px;
//   //     height: 48px;
//   //     left: 730px;
//   //     top: 385px;
//   //     background: #FFFFFF;
//   //     border: 1px solid #26649D;
//   //     border-radius: 8px;
//   //     padding-left:20px;
//   //   }

//   //   .shipping p {
//   //     font-family: 'WorkSans';
//   //     font-size: 12px;
//   //     font-weight: 300;
//   //     line-height: 14px;
//   //   }

//   // .row1{
//   //         margin-left: 15px;
//   //         height: 18px;
//   //   }

//   //   .continue {
//   //     margin-top: 40px;
//   //     margin-left:40px;
//   //   }
//   //   .continue button {
//   //     background-color: #22669c;
//   //     /* box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3); */

//   //     border: none;
//   //     padding: 10px 0px;
//   //     color: white;
//   //     width:100%;
//   //   }

//   //   .label-align {
//   //     font-family: 'WorkSans';
//   //     font-size: 16px;
//   //     font-weight: 500;
//   //     line-height: 19px;
//   //     margin-left: 14px;
//   //     margin-top:-18px
//   //   }

//   //   .alignment-order {
//   //     padding-bottom: 10px;
//   //     margin-top: 40px;
//   //     margin-right: 170px;
//   //   }

//   // .container {
//   //     height: 100vh;
//   //     overflow: hidden;
//   // }
// `;

export default Signup;
