import React from "react";
import styled from "styled-components";
import new_hero_img from "../assets/MainImage.png";
import LandingPageMobile from "../assets/LandingPageMobile.png";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { isMobile } from "react-device-detect";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <section class="home-section" id="home">
        <div class="background-img">
          <div className="overlay"></div>
          <div class="row hero-section">
            <div class="container-fluid p-0">
              <div class="col-lg-8 col-md-12 col-sm-12 section-one">
                <p>
                  Dementias like <br />
                  Alzheimer’s are a <br /> <span>global health crisis.</span>
                </p>
              </div>
              <div class="col-lg-4 col-md-12 col-sm-12 sub-section">
                <p>
                  Enormous economic <br /> burden to caregivers and <br /> the
                  healthcare system.
                </p>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 link-section">
                <a href="/mission">
                  <span class="link">Learn more about our mission</span>{" "}
                  <ArrowForwardIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .container-fluid {
    width: 100%;
    flex-wrap: wrap;
  }
  span.link {
    font-weight: 700;
    font-size: 24px;
    color: #fff;
    text-decoration-line: underline;
  }
  .link-section {
    justify-content: center;
    padding-left: 440px;
    display: flex;
    align-items: end;
    padding-bottom: 65px;
  }
  .background-img {
    background-image: url(${isMobile ? LandingPageMobile : new_hero_img});
    background-size: cover;
    background-position: top;
    height: 100vh;
  }
  .overlay {
    width: 45%;
    height: 100vh;
    position: absolute;
    background-color: #041f39e3;
  }
  .link-section svg {
    margin-left: 15px;
    color: #fff;
    margin-bottom: 3px;
}
  .container-fluid {
    display: flex;
    height: 100%;
  }
  .hero-section {
    height: 100%;
    width: 100%;
    margin: 0;
  }
  .hero-section .col-lg-8,
  .hero-section .col-lg-4 {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .sub-section p,
  .section-one p {
    font-style: normal;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.5);
    font-family: "WorkSans";
    margin: 0px;
  }
  .sub-section p {
    font-size: 32px;
    width: 100%;
  }
  .section-one p {
    padding-left: 64px;
    font-size: 56px;
    padding-top: 180px;
}
  }
  .section-one span,
  .sub-section p {
    color: #fff;
  }
  .section-one {
    z-index: 999;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {

    .overlay{
      display : none 
    }
    .section-one p {
      font-size: 32px;
      padding : 0px
  }
  .sub-section p {
    font-size: 20px;
    width: auto;
  }
   .link-section {
  padding: 0px !important;
  align-items: center !important;
   }
   span.link {
    font-size: 16px;
}
.section-one {
  width: 100%;
}
  .sub-section {
  width: 100%;
  padding-right: 60px;
  }
  .sub-section {
  display: flex;
  align-items: center !important;
  }
  .link-section {
    width: 100%;
    align-items: flex-start !important;
    justify-content: flex-start;
}
.hero-section .col-lg-4{
  justify-content: flex-start !important;
}
.section-one {
  display: flex;
  justify-content: flex-start !important;
}
.container-fluid.p-0 {
  width: 80%;
}
}
`;

export default Hero;
