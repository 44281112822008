import styled from "styled-components";

export const PaymentFormContainer=styled.div`
    height:100%;
    display:table-row;
    flex-direction:row;
    align-items:center;
    justify-content:center;
`

export const FormContainer=styled.div`

    height:100px;
    min-width:500px;
`