/** @format */

import React from "react";
import styled from "styled-components";
import logo_desktop from "../assets/logo_desktop.svg";
import logo_mobile from "../assets/logo_mobile.svg";
import logo from "../assets/MainLogo.png";
import mobileLogo from "../assets/LogoMobile.png";
import carticon from "../assets/carticon.svg";
import hamburger from "../assets/hamburger.svg";
import user from "../assets/user.svg";
import { Outlet, Link } from "react-router-dom";
import { useGlobalContext } from "../context/context";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

const Nav = (props) => {
  var socilaLogIn = localStorage.getItem("socilaLogIn");
  const [userId, setUserId] = useState("");
  const [color, setColor] = useState("");
  const [mobileNavbarToggle, setMobileNavbarToggle] = useState(true);

  setTimeout(() => {
    const userID = localStorage.getItem("local_id_store");
    setUserId(userID);
  }, 0);


  const navigate = useNavigate();

  const location = useLocation();

  const handleClick = () => {
    window.location.reload();
    navigate("/");
  };

  const handleClickcart = () => {
    navigate("/MainCheckout");
  };

  const handleClickout = () => {
    window.localStorage.clear();
    // window.localStorage.remove("local_id_store");
    window.location.reload();
    navigate("/");
  };

  const handleclicksign = () => {
    var stri = localStorage.getItem("local_id_store");
    setMobileNavbarToggle(true);
    stri = parseInt(stri);
    if (stri > 0 || socilaLogIn === "true") {
      navigate("/userProfile");

    } else {
      navigate("/Signin");
    }
  };

  const handleclickmission = () => {
      navigate("/mission");
  };
  const handleclickcollab = () => {
    navigate("/collaboration");
};
  const { amount } = useGlobalContext();

  const handleRedirectToHome = (value) => {
    if (value !== "") {
      setMobileNavbarToggle(true);
      navigate("/");
      if (location.pathname === "/Payment") {
        window.location.reload();
      }
    }
    // setMobileNavbarToggle(!mobileNavbarToggle)
    else navigate("/");
  };
  const TotalAmount = localStorage.getItem("TotalAmount1");

  const listenScrollEvent = (e) => {
    if (window.scrollY > 50) {
      if (location.pathname === "/") {
        setColor("#153F67");
      }
    }
  };

  useEffect(() => {
    
    if (location.pathname !== "/") {
      setColor("#153F67");
    }
    else{
      setColor("");
    }
    window.addEventListener("scroll", listenScrollEvent);
  }, [location.pathname]);

  return (
    <NavContainer>
      <div className="container-fluid p-0">
        <header class="header-fixed">
          <nav
            class="navbar navbar-expand-lg"
            style={{ backgroundColor: color, zIndex: 9999 }}
          >
            <div class="nav-bar-color">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleRedirectToHome()}
                class="navbar-brand"
              >
                <img class="mobile" src={logo} alt="Desktop_logo" />
                <img class="desktop" src={mobileLogo} alt="Mobile_logo" />
              </div>{" "}
              <span
                class={
                  mobileNavbarToggle
                    ? "navbar-toggler collapsed"
                    : "navbar-toggler"
                }
                type="button"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setMobileNavbarToggle(!mobileNavbarToggle)}
              >
                <span>
                  <img src={hamburger} alt="Hamburger_icon" />
                </span>
              </span>
              <div
                class={
                  mobileNavbarToggle
                    ? "collapse navbar-collapse"
                    : "collapse navbar-collapse show"
                }
                id="navbarSupportedContent"
              >
                <ul class="navbar-nav">
                  <li class="nav-item">
                    {/* <a class="nav-link" onClick={handleclickmission}>
                      Mission
                    </a> */}
                    <a class="nav-link" >
                      Mission
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" >
                      Collaboration
                    </a>
                    {/* <a class="nav-link" onClick={handleclickcollab}>
                      Collaboration
                    </a> */}
                  </li>
                  {userId || socilaLogIn === "true" ? (
                    <>
                      <li class="nav-item-icon-cart cart-mobile">
                        <Link
                          to={
                            userId || socilaLogIn === "true"
                              ? "/MainCheckout"
                              : "/Signin"
                          }
                          class="nav-link"
                        >
                          <img src={carticon} alt="Cart_icon" />
                          {TotalAmount > 0 && (
                            <span class="badge badge-warning" id="lblCartCount">
                              {TotalAmount}{" "}
                            </span>
                          )}
                        </Link>
                      </li>
                      <li class="nav-item-icon-cart">
                        <a class="nav-link">
                          <img
                            src={user}
                            alt="User"
                            onClick={handleclicksign}
                          />
                        </a>
                      </li>
                    </>
                  ) : (
                    <li class="nav-item login">
                      <Link to={"/Signin"} class="nav-link">
                        Login
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </NavContainer>
  );
};

const NavContainer = styled.nav`
  .badge {
    padding-left: 9px;
    padding-right: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
  }
  .login a.nav-link {
    color: #fff !important;
  }
  img.mobile {
    width: 150px;
  }
  .font {
    font-size: 2px;
  }
  .label-warning[href],
  .badge-warning[href] {
    background-color: #c67605;
  }
  #lblCartCount {
    font-size: 12px;
    background: #ff0000;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-left: -10px;
  }

  .navbar {
    position: fixed;
    z-index: 1;
    width: 100%;
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.2));
    border-radius: 0px 0px 8px 8px;
    margin-left: 0px;
    left: 0;
    display: flex;
    justify-content: center;
  }
  .nav-bar-color {
    width: 66%;
    display: flex;
    padding: 20px 0px 20px 0px;
  }
  .navbar-nav {
    margin-left: auto;
  }
  .container-fluid {
    width: 63%;
  }
  .nav-item {
    padding: 0px 11px;
    cursor: pointer;
  }
  .nav-item-icon-cart {
    padding: 0px 9px;
    cursor: pointer;
  }
  .navbar-nav .nav-link {
    font-size: var(--font-size-16px);
    font-weight: var(--font-weight-500);
    font-style: var(--font-sytle-normal);
    color: #ffff;
    line-height: 19px;
    font-family: "WorkSans";
  }
  .navbar-brand .desktop {
    display: none;
  }
  .navbar-brand .mobile {
    display: block;
  }
  .nav-link-mobile {
    display: none;
  }
  @media screen and (min-width: 992px) and (max-width: 1150px) {
    .navbar-brand {
      padding-left: 15px;
    }
  }
  @media Screen and (min-width: 320px) and (max-width: 767px) {
    span.navbar-toggler.collapsed {
      border: 0px;
    }
    .nav-link-mobile {
      display: block;
    }
    .cart-mobile {
      display: none;
    }

    .navbar {
      width: 100%;
      border-radius: 0px 0px 0px 0px;
    }
    .nav-item-icon-cart {
      padding: 0px 0px 0px 20px;
    }
    .navbar-brand .mobile {
      display: none;
    }
    .navbar-brand .desktop {
      display: block;
    }
    .container-fluid {
      width: 100%;
    }
    .navbar-brand {
      padding-left: 30px;
    }
    .navbar-nav {
      margin-left: 10px;
    }
    .navbar-toggler {
      padding: 0.25rem 2rem;
    }
    .nav-bar-color {
      width: 94%;
      justify-content: space-between;
    }
    div#navbarSupportedContent {
      width: 100%;
      background-color: #fff;
      position: absolute;
      top: 90px;
      left: 0px;
    }
    .nav-bar-color {
      width: 94%;
      flex-wrap: wrap;
    }
    nav.navbar.navbar-expand-lg {
      background-color: rgb(21, 63, 103) !important;
    }
    .nav-bar-color {
      padding: 12px 0px 12px 0px;
    }
    span.navbar-toggler.collapsed img,
    span.navbar-toggler img {
      filter: brightness(0) invert(1);
    }
    a.nav-link {
      color: #000 !important;
    }
    .login a.nav-link {
      color: #000 !important;
    }
  }
`;

export default Nav;
