// import React, { useEffect } from "react";
// import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
// import CheckoutNav from "./components/CheckoutNav.jsx";
// import HomePage from "./pages/HomePage.jsx";
// import ContactPage from "./pages/ContactPage.jsx";
// import About from "./components/Sticks.jsx";
// import Tutorials from "./components/Tutorials.jsx";
// import Shop from "./components/Shop.jsx";
// import MainCheckOut from "./pages/MainCheckOut.jsx";
// import Cart from "./components/Cart.jsx";
// import Product_Cart from "./components/Product_Cart.jsx";
// import Shipping from "./components/Shipping.jsx";
// import Payment from "./components/Payment.jsx";
// import Order_Review from "./components/Order_Review.jsx";
// import { useGlobalContext } from "./context/context";
// import Thankyou from "./components/Thankyou.jsx";
// import Signup from "./components/Login Pages/Signup.jsx";
// // import Signin from "./components/Login Pages/Signin.jsx";
// import Confirmation from "./components/Login Pages/Confirmation.jsx";
// import Dialogue from "./components/Dialogue.jsx";
// import Dialogueup from "./components/Dialogueup.jsx";
// import DialogueConfirm from "./components/DialogueConfirm.jsx";
// import ForgetPassword from "./components/Login Pages/ForgetPassword.jsx";
// import PasswordResetLink from "./components/Login Pages/PasswordResetLink.jsx";
// import ResetPassword from "./components/Login Pages/ResetPassword.jsx";
// import ResetSuccessfully from "./components/Login Pages/ResetSuccessfully.jsx";
// import Navbar from "./components/Navbar.jsx";
// import UserProfile from "./components/UserProfile.jsx";
// import YourOrders from "./components/YourOrders.jsx";
// import YetToDispatch from "./components/YetToDispatch.jsx";
// import EditProfile from "./components/EditProfile.jsx";
// import MobileScreen from "./pages/MobileAppScreen.jsx";
// import ErrorPage from "./components/ErrorPage.jsx";
// import Research from "./components/Research/Research.jsx";
// import Collabration from "./components/Collaboration/Collabration.jsx";
// import Mission from "./components/Mission/Mission.jsx";
// import { gapi } from "gapi-script";
// import "./components/App/App.css";
// import Footer from "./components/Footer.jsx";
// import Auth from "./components/Layout/Auth.js";
// import Main from "./components/Layout/Main.js";
// import IntelliVista from "./components/IntelliVista";
// import PrivacyPolicy from "./components/PrivacyPolicy.jsx";
// import Privacy from "./components/Privacy-Policy.jsx";
// import RenAware from "./components/RenAware.jsx";
// import Home from "./pages/Home.js";
// import Sentaware from "./pages/Sentaware.jsx";
// import Product from "./components/Product.jsx";
// import Header from "./components/Reuseable Components/Header.jsx";
// import NewFooter from "./components/Reuseable Components/NewFooter.jsx";
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// function App() {
//   var LoginEmail = localStorage.getItem("Current_Login_user");
//   const location = useLocation();

//   useEffect(() => {
//     function start() {
//       gapi.client.init({
//         clientId:
//           "251997598489-2en61bset3ti5uakmnc5370fvma3v0sl.apps.googleusercontent.com",
//         scope: "",
//       });
//     }
//     gapi.load("client:auth2", start);
//   });

//   return (
//     <>
//     <ToastContainer/>
//       {!location.pathname.includes("renAware") &&
//        !location.pathname.includes("sentaware") &&
//         !location.pathname.includes("product") &&
//         !location.pathname.includes("MainCheckOut") &&
//          !location.pathname.includes("/") && <Header />}
//       <Routes>
//         {/* <Route index exact path="/" element={<HomePage />} />
//         <Route index exact path="/apps" element={<HomePage />} /> */}
//         <Route index exact path="/" element={<Home />} />
//         <Route index exact path="/collaboration" element={<Collabration />} />
//         <Route index exact path="/mission" element={<Mission />} />
//         <Route index exact path="/privacypolicy" element={<PrivacyPolicy />} />
//         <Route index exact path="/ren-aware" element={<RenAware />} />
//         <Route index exact path="/privacy-policy" element={<Privacy />} />
//         <Route index exact path="/sentaware" element={<Sentaware />} />
//         <Route index exact path="/product" element={<Product />} />

//         <Route path="mobile-screen" element={<MobileScreen />} />
//         <Route path="contact-us" element={<ContactPage />} />
//         <Route path="Signup" element={<Dialogueup />}></Route>
//         {/* <Route path="Signin" element={<Dialogue />}></Route> */}
//         <Route path="Confirmation" element={<DialogueConfirm />}></Route>
//         <Route path="MainCheckOut" element={<MainCheckOut />}></Route>
//         <Route path="Product_Cart" element={<Product_Cart />}></Route>
//         <Route path="Shipping" element={<Shipping />}></Route>
//         <Route path="Payment" element={<Payment />}></Route>
//         <Route path="Thankyou" element={<Thankyou />}></Route>
//         <Route path="Order_Review" element={<Order_Review />}></Route>
//         <Route path="forget_Password" element={<ForgetPassword />} />
//         <Route path="Forgot_PasswordLink" element={<PasswordResetLink />} />
//         <Route path="reset_Password" element={<ResetPassword />} />
//         <Route path="reset_Successfully" element={<ResetSuccessfully />} />
//         <Route path="userProfile" element={<UserProfile />} />
//         <Route path="yourOrders" element={<YourOrders />} />
//         <Route path="yet-Dispatch" element={<YetToDispatch />} />
//         <Route path="edit-Profile" element={<EditProfile />} />
//         <Route path="research" element={<Research />} />
//         <Route path="/*" element={<ErrorPage />} />
//         <Route path="IntelliVista" element={<IntelliVista />} />
//       </Routes>

//       {!location.pathname.includes("Signin") &&
//         !location.pathname.includes("Signup") &&
//         !location.pathname.includes("renAware") &&
//         !location.pathname.includes("sentaware") &&
//         !location.pathname.includes("/") &&
//         !location.pathname.includes("product") &&
//         <NewFooter />}
//     </>
//   );
// }

// export default App;
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import CheckoutNav from "./components/CheckoutNav.jsx";
import HomePage from "./pages/HomePage.jsx";
import About from "./components/Sticks.jsx";
import Tutorials from "./components/Tutorials.jsx";
import Shop from "./components/Shop.jsx";
import MainCheckOut from "./pages/MainCheckOut.jsx";
import Cart from "./components/Cart.jsx";
import Product_Cart from "./components/Product_Cart.jsx";
import Shipping from "./components/Shipping.jsx";
import Payment from "./components/Payment.jsx";
import Order_Review from "./components/Order_Review.jsx";
import { useGlobalContext } from "./context/context";
import Thankyou from "./components/Thankyou.jsx";
import Signup from "./components/Login Pages/Signup.jsx";
// import Signin from "./components/Login Pages/Signin.jsx";
import Confirmation from "./components/Login Pages/Confirmation.jsx";
import Dialogue from "./components/Dialogue.jsx";
import Dialogueup from "./components/Dialogueup.jsx";
import DialogueConfirm from "./components/DialogueConfirm.jsx";
import ForgetPassword from "./components/Login Pages/ForgetPassword.jsx";
import PasswordResetLink from "./components/Login Pages/PasswordResetLink.jsx";
import ResetPassword from "./components/Login Pages/ResetPassword.jsx";
import ResetSuccessfully from "./components/Login Pages/ResetSuccessfully.jsx";
import Navbar from "./components/Navbar.jsx";
import UserProfile from "./components/UserProfile.jsx";
import YourOrders from "./components/YourOrders.jsx";
import YetToDispatch from "./components/YetToDispatch.jsx";
import EditProfile from "./components/EditProfile.jsx";
import MobileScreen from "./pages/MobileAppScreen.jsx";
import ErrorPage from "./components/ErrorPage.jsx";
import Research from "./components/Research/Research.jsx";
import Collabration from "./components/Collaboration/Collabration.jsx";
import Mission from "./components/Mission/Mission.jsx";
import { gapi } from "gapi-script";
import "./components/App/App.css";
import Footer from "./components/Footer.jsx";
import Auth from "./components/Layout/Auth.js";
import Main from "./components/Layout/Main.js";
import IntelliVista from "./components/IntelliVista";
import PrivacyPolicy from "./components/PrivacyPolicy.jsx";
import Privacy from "./components/Privacy-Policy.jsx";
import RenAware from "./components/RenAware.jsx";
import Home from "./pages/Home.js";
import Sentaware from "./pages/Sentaware.jsx";
import Product from "./components/Product.jsx";
import Header from "./components/Reuseable Components/Header.jsx";
import NewFooter from "./components/Reuseable Components/NewFooter.jsx";
import Billing from "./components/Checkout Intellivista/Biling.jsx";
import PaymentIntelliVista from "./components/Checkout Intellivista/PaymentIntelliVista.jsx";
import OrderConfirmationIntelliVista from "./components/Checkout Intellivista/OrderConfirmationIntelliVista.jsx";
import CustomDigital from "./components/CustomDigital.jsx";
import ProtectedRoute from "./ProtectedRoute.js";
import OctDirect from "./components/OCTDirect.jsx";
import GetTheApp from "./components/get-the-app.jsx"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  var LoginEmail = localStorage.getItem("Current_Login_user");
  const location = useLocation();

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          "251997598489-2en61bset3ti5uakmnc5370fvma3v0sl.apps.googleusercontent.com",
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });

  return (
    <>
    <ToastContainer/>
      {!location.pathname.includes("renAware") &&
       !location.pathname.includes("scentaware") &&
        !location.pathname.includes("product") &&
        !location.pathname.includes("MainCheckOut") &&
         !location.pathname.includes("/") && <Header />}
      <Routes>
        {/* <Route index exact path="/" element={<HomePage />} />
        <Route index exact path="/apps" element={<HomePage />} /> */}
        <Route index exact path="/" element={<Home />} />
        <Route index exact path="/collaboration" element={<Collabration />} />
        <Route index exact path="/mission" element={<Mission />} />
        <Route index exact path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route index exact path="/ren-aware" element={<RenAware />} />
        <Route index exact path="/privacy-policy" element={<Privacy />} />
        <Route index exact path="/scentaware" element={<Sentaware />} />
        <Route index exact path="/product" element={<Product />} />

        <Route path="mobile-screen" element={<MobileScreen />} />
        <Route path="Signup" element={<Dialogueup />}></Route>
        <Route path="Confirmation" element={<DialogueConfirm />}></Route>
        <Route path="MainCheckOut"  element={<ProtectedRoute Component={MainCheckOut} allowWithoutAuth={false}/>} />
        <Route path="Product_Cart" element={<Product_Cart />}></Route>
        <Route path="Shipping"  element={<ProtectedRoute Component={Shipping} allowWithoutAuth={false}/>} />
        <Route path="Payment"  element={<ProtectedRoute Component={Payment} allowWithoutAuth={false}/>} />
        <Route path="Thankyou"  element={<ProtectedRoute Component={Thankyou} allowWithoutAuth={false}/>} />
        <Route path="Order_Review" element={<Order_Review />}></Route>
        <Route path="forget_Password" element={<ForgetPassword />} />
        <Route path="Forgot_PasswordLink" element={<PasswordResetLink />} />
        <Route path="reset_Password" element={<ResetPassword />} />
        <Route path="reset_Successfully" element={<ResetSuccessfully />} />
        <Route path="userProfile"  element={<ProtectedRoute Component={UserProfile} allowWithoutAuth={false}/>} />
        <Route path="yourOrders" element={<YourOrders />} />
        <Route path="yet-Dispatch" element={<YetToDispatch />} />
        <Route path="edit-Profile" element={<EditProfile />} />
        <Route path="research" element={<Research />} />
        <Route path="/*" element={<ErrorPage />} />
        <Route path="IntelliVista" element={<IntelliVista />} />
        <Route path="billing"  element={<ProtectedRoute Component={Billing} allowWithoutAuth={false}/>} />
        <Route path="payment-intelliVista"  element={<ProtectedRoute Component={PaymentIntelliVista} allowWithoutAuth={false}/>} />
        <Route path="order-confirmation-intelliVista"  element={<ProtectedRoute Component={OrderConfirmationIntelliVista} allowWithoutAuth={false}/>} />
        <Route path="custom-digital" element={<CustomDigital />} />
        <Route path="apps" element={<GetTheApp />} />
        <Route path="oct-direct" element={<OctDirect />} />
      </Routes>

      {!location.pathname.includes("Signin") &&
        !location.pathname.includes("Signup") &&
        !location.pathname.includes("renAware") &&
        !location.pathname.includes("sentaware") &&
        !location.pathname.includes("/") &&
        !location.pathname.includes("product") &&
        <NewFooter />}
    </>
  );
}

export default App;
