import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import logoBlack from "../../assets/logo-black.png";
import right_arrow from "../../assets/right_arrow.png";
import NewFooter from "../Reuseable Components/NewFooter";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";

const initialValues = {
    ShippingToName: "",
    AddressLine1: "",
    AddressLine2: "",
    ZipCode: "",
    City: "",
    State: "",
    PhoneNumber: "",
    ShippingToEmail: "",
    // saveInfo: false,
};

const Billing = () => {
    const navigate = useNavigate();
    const [load, setLoad] = useState(false);
    const [userName, setUserName] = useState([]);
    const [saveInfo, setSaveInfo] = useState(false);

    const validationSchema = Yup.object().shape({
        ShippingToName: Yup.string().required("Name is required"),
        AddressLine1: Yup.string().required("Address Line 1 is required"),
        AddressLine2: Yup.string(),
        ZipCode: Yup.string()
            .required("Zip Code is required")
            .matches(/^\d{5}$/, "Invalid Zip Code"),
        City: Yup.string().required("City is required"),
        State: Yup.string().required("State is required"),
        PhoneNumber: Yup.string()
            .required("Phone Number is required")
            .matches(/^\d{10}$/, "Invalid Phone Number"),
        ShippingToEmail: Yup.string()
            .email("Invalid email")
            .required("Email is required"),
        // ShippingToEmailC: Yup.string()
        //   .email("Invalid email")
        //   .oneOf([Yup.ref("ShippingToEmail")], "Emails must match")
        //   .required("Confirm Email is required"),
    });

    useEffect(() => {
        const userDataByAccounts = JSON.parse(localStorage.getItem('socialObj'));
        const userDataByEmail = JSON.parse(localStorage.getItem('UserData'));
        if (userDataByAccounts) {
            const Name = userDataByAccounts.FirstName + ' ' + userDataByAccounts.LastName;
            setUserName(Name);
        } else if (userDataByEmail) {
            setUserName(userDataByEmail.Data.UserName);
        } else {
            setUserName(null);
        }

        const savedData = localStorage.getItem("BillingIntelliVista");
        if (savedData) {
            formik.setValues(JSON.parse(savedData));
        }
    }, []);

    function SaveUser(event) {
        if (formik.isValid) {
            //   Swal.fire({
            //     icon: 'success',
            //     title: 'Added!',
            //     text: `${ShippingToName} 's data has been added.`,
            //     showConfirmButton: false,
            //     timer: 3500,
            // });
            if (saveInfo === true) {
                localStorage.setItem("BillingIntelliVista", JSON.stringify(event));
            }

            navigate("/payment-intelliVista", {
                state: {
                    id: 1,
                    name: event.ShippingToName,
                    address: event.AddressLine1,
                    address2: event.AddressLine2,
                    zipcode: event.ZipCode,
                    city: event.City,
                    state: event.State,
                    phone: event.PhoneNumber,
                    email: event.ShippingToEmail,
                },
            });
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Invalid Data',
                text: "Invalid Details Added, Please Enter Again!",
                showCancelButton: false,
                confirmButtonText: 'Try Again!',
            })
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: SaveUser,
    });

    const handleCheckboxChange = (e) => {
        setSaveInfo(e.target.checked);
    };
    return (
        <body className="renaware">
            <header className="dark-header new_header checout">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* <nav className="navbar navbar-expand-lg p-0 d-flex align-items-cneter justify-content-between">
                                <Link to='/' onClick={() => window.scrollTo(0, 0)} className="navbar-brand" >
                                    <img src={logoBlack}
                                        width="140px"
                                        alt />
                                </Link>
                                <div className="card_title">

                                    <p className="m-0 blue" style={{ fontWeight: 600 }}>Ashlynn Torff</p>
                                </div>
                            </nav> */}

                            <nav
                                class="navbar navbar-expand-lg justify-content-between p-0">
                                <Link to='/' class="navbar-brand" >
                                    <img src={logoBlack}
                                        width="140px"
                                        alt />
                                </Link>

                                <ul
                                    class="navbar-nav ms-auto d-lg-block d-md-block d-none mb-0 mb-lg-0 gap-3">
                                    <li class="nav-item">
                                        <Link to='/userProfile' class="nav-link"
                                            aria-current="page"
                                        >{userName}</Link>
                                    </li>
                                </ul>
                                <div class="d-lg-none d-md-none d-block">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32" viewBox="0 0 32 32" fill="none">
                                        <path fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M16.0003 29.3337C23.3643 29.3337 29.3337 23.3643 29.3337 16.0003C29.3337 8.63633 23.3643 2.66699 16.0003 2.66699C8.63633 2.66699 2.66699 8.63633 2.66699 16.0003C2.66699 23.3643 8.63633 29.3337 16.0003 29.3337ZM20.0003 13.3337C20.0003 14.3945 19.5789 15.4119 18.8288 16.1621C18.0786 16.9122 17.0612 17.3337 16.0003 17.3337C14.9395 17.3337 13.922 16.9122 13.1719 16.1621C12.4218 15.4119 12.0003 14.3945 12.0003 13.3337C12.0003 12.2728 12.4218 11.2554 13.1719 10.5052C13.922 9.75509 14.9395 9.33366 16.0003 9.33366C17.0612 9.33366 18.0786 9.75509 18.8288 10.5052C19.5789 11.2554 20.0003 12.2728 20.0003 13.3337ZM8.00033 22.667C8.931 21.4242 10.1387 20.4155 11.5274 19.7212C12.9161 19.0268 14.4477 18.6659 16.0003 18.667C17.553 18.6659 19.0845 19.0268 20.4732 19.7212C21.862 20.4155 23.0696 21.4242 24.0003 22.667C23.0696 23.9098 21.862 24.9184 20.4732 25.6128C19.0845 26.3072 17.553 26.6681 16.0003 26.667C14.4477 26.6681 12.9161 26.3072 11.5274 25.6128C10.1387 24.9184 8.931 23.9098 8.00033 22.667Z"
                                            fill="#031A40" />
                                    </svg>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>

            <section className="login_page inner-banner-section sign_up_outer d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div
                            class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                            <ul class="card_breadcrumb">
                                <li class="active" onClick={() => navigate('/billing')}>
                                    <a>
                                        Billing
                                    </a>
                                </li>
                                <li 
                                // onClick={() => navigate('/payment-intelliVista')}
                                >
                                    <a>
                                        Checkout
                                    </a>
                                </li>
                                <button
                                    class="btn btn-outline-primary w-auto ms-4 d-lg-inline-block d-md-inline-block d-none" onClick={() => navigate('/IntelliVista')}>Cancel
                                    order</button>
                                <button
                                    class="btn btn-primary w-auto ms-4 d-lg-inline-block d-md-inline-block d-none" onClick={(e) => {
                                        e.preventDefault();
                                        formik.handleSubmit();
                                    }}>Proceed to
                                    Checkout</button>
                            </ul>
                        </div>
                    </div>
                    <div class="row my-4 py-lg-2">
                        <div class="col-12">
                            <div class="card_title">
                                <h6 class="m-0"><b
                                    class="d-flex align-items-center gap-4">
                                    <img src={right_arrow} class="d-lg-block d-md-block d-none" onClick={() => navigate('/IntelliVista')} alt='' />
                                    Billing details
                                </b></h6>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12 pe-lg-5">
                            <div class="form_feild card_title">
                                <form action>
                                    <div class="row gx-lg-5 gx-3">
                                        <div
                                            class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                                            <label for class="w-100">Zip code
                                                :</label>
                                            <input type="text"
                                                className={`form-control ${formik.touched.ZipCode && formik.errors.ZipCode
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                name="ZipCode"
                                                placeholder="Zip Code"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.ZipCode}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.ZipCode}
                                                invalidFeedback={formik.errors.ZipCode} />
                                            <div className="invalid-feedback">
                                                {formik.errors.ZipCode}
                                            </div>

                                        </div>
                                        <div
                                            class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                                            <label for class="w-100">State :</label>
                                            <input type="text"
                                                className={`form-control ${formik.touched.State && formik.errors.State
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                name="State"
                                                placeholder="State"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.State}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.State}
                                                invalidFeedback={formik.errors.State} />
                                            <div className="invalid-feedback">
                                                {formik.errors.State}
                                            </div>

                                        </div>
                                        <div
                                            class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                                            <label for class="w-100">Address
                                                :</label>
                                            <input type="text"
                                                className={`form-control ${formik.touched.AddressLine1 && formik.errors.AddressLine1
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                name="AddressLine1"
                                                placeholder="Address"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.AddressLine1}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.AddressLine1}
                                                invalidFeedback={formik.errors.AddressLine1} />
                                            <div className="invalid-feedback">
                                                {formik.errors.AddressLine1}
                                            </div>

                                            <input type="text"
                                                className={`form-control mt-2 ${formik.touched.AddressLine2 && formik.errors.AddressLine2
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                name="AddressLine2"
                                                placeholder="Address"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.AddressLine2}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.AddressLine2}
                                                invalidFeedback={formik.errors.AddressLine2} />
                                            <div className="invalid-feedback">
                                                {formik.errors.AddressLine2}
                                            </div>
                                        </div>
                                        <div
                                            class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                                            <label for class="w-100">City :</label>
                                            <input type="text"
                                                className={`form-control ${formik.touched.City && formik.errors.City
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                name="City"
                                                placeholder="City"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.City}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.City}
                                                invalidFeedback={formik.errors.City} />
                                            <div className="invalid-feedback">
                                                {formik.errors.City}
                                            </div>
                                        </div>
                                        <div class="col-12 pt-2 mb-4">
                                            <h6 class="m-0"><b>Contact
                                                information</b></h6>
                                        </div>
                                        <div
                                            class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                                            <label for class="w-100">Ship to
                                                :</label>
                                            <input type="text"
                                                className={`form-control ${formik.touched.ShippingToName && formik.errors.ShippingToName
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                name="ShippingToName"
                                                placeholder="Ship To"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.ShippingToName}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.ShippingToName}
                                                invalidFeedback={formik.errors.ShippingToName} />
                                            <div className="invalid-feedback">
                                                {formik.errors.ShippingToName}
                                            </div>
                                        </div>
                                        <div
                                            class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                                            <label for class="w-100">Email :</label>
                                            <input type="mail"
                                                className={`form-control ${formik.touched.ShippingToEmail && formik.errors.ShippingToEmail
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                name="ShippingToEmail"
                                                placeholder="Email"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.ShippingToEmail}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.ShippingToEmail}
                                                invalidFeedback={formik.errors.ShippingToEmail} />
                                            <div className="invalid-feedback">
                                                {formik.errors.ShippingToEmail}
                                            </div>
                                            <sup>We will send a confirmation on this
                                                e-mail.</sup>
                                        </div>
                                        <div
                                            class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                                            <label for class="w-100">Organization
                                                :</label>
                                            <input type="text"
                                                class="form-control"
                                                placeholder="Organization" />
                                        </div>
                                        <div
                                            class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                                            <label for class="w-100">Mobile / Phone
                                                :</label>
                                            <input type="number"
                                                className={`form-control ${formik.touched.PhoneNumber && formik.errors.PhoneNumber
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                name="PhoneNumber"
                                                placeholder="Mobile/Phone Number"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.PhoneNumber}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.PhoneNumber}
                                                invalidFeedback={formik.errors.PhoneNumber} />
                                            <div className="invalid-feedback">
                                                {formik.errors.PhoneNumber}
                                            </div>
                                            <sup>Phone numbers shall be used only
                                                for delivery purposes.</sup>
                                        </div>
                                        <div class="col-12 pt-lg-2 pt-lg-2 pt-0 mt-lg-0 mt-md-0 mt-4">
                                            <div
                                                class="form d-flex align-items-center">
                                                <input type="checkbox"
                                                    name="saveInfo"
                                                    class="form-check-input m-0"
                                                    checked={() => setSaveInfo(true)}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label for class="ms-3 mb-0 text-start"><p
                                                    class="m-0 text-start">Save shipping
                                                    information for the next
                                                    time.</p></label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12 mt-lg-0 mt-4 ms-lg-5 d-lg-block d-md-block d-none">
                            <div class="summary_card">
                                <div class="card_title">
                                    <h6 class="mb-lg-4 mb-3"><b>Order
                                        summary</b></h6>
                                    <p class="mb-3 text-start">IntelliVista - Basic
                                        plan</p>
                                    <ul class="mb-3">
                                        <li>1 administrator account login.</li>
                                        <li>10,000 participants.</li>
                                        <li>Unlimited trials.</li>
                                    </ul>
                                    <div class="row">
                                        <div class="col-lg-10 col-12">
                                            <p
                                                class="d-flex align-items-center justify-content-between mb-0">Price
                                                : <span>$4,995.00/year</span></p>
                                        </div>
                                    </div>
                                    <hr class="my-lg-3" />
                                    <div class="row">
                                        <div class="col-lg-7 col-12">
                                            <p class="d-flex align-items-center justify-content-between mb-1 w-100 gap-3">Total: <span><b>$4995.00/year</b></span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-lg-3 pt-md-3 pt-2 d-lg-none d-md-none d-block">
                        <div class="col-12 mt-4">
                            <button
                                class="btn btn-primary w-100 ms-0">Proceed to
                                Checkout</button>
                            <button
                                class="btn btn-outline-primary w-100 ms-0 mt-3">Cancel
                                order</button>
                        </div>
                    </div>
                </div>
            </section>
            <NewFooter />
        </body>
    )
}
export default Billing