import React, { useEffect, useState } from 'react'
import Great from '../../assets/great.png'
const SuccessSubscribe = () => {
    const [userName, setUserName] = useState([]);

    useEffect(() => {
        const userDataByAccounts = JSON.parse(localStorage.getItem('socialObj'));
        const userDataByEmail = JSON.parse(localStorage.getItem('UserData'));
        if (userDataByAccounts) {
            const Name = userDataByAccounts.FirstName + ' ' + userDataByAccounts.LastName;
            setUserName(Name);
        } else if (userDataByEmail) {
            setUserName(userDataByEmail.Data.UserName);
        } else {
            setUserName(null);
        }
    }, []);
    return (

        <section class="login_page d-flex align-items-center justify-content-center position-relative">
            <div className='confirmation_modal p-lg-5 p-md-4 p-3'>
                <div className='card_title text-center'>
                    <h2 className='text-center m-0'>Thank You for Subscribe!</h2>
                    <img src={Great} width='150px' alt="" className='my-4' />
                    <h6 className='m-0 text-center'><b>{userName}</b> Thank You for Subscribe!</h6>
                </div>
            </div>
        </section>
    )
}

export default SuccessSubscribe