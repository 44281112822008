import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, Link } from "react-router-dom";
import Logo from '../../assets/logo1.png'
import LogoMobile from '../../assets/LogoMobile.png'
import Modal from 'react-modal';
import Signin from '../Modal/Signin';


const Header = () => {
    const location = useLocation();
    var socilaLogIn = localStorage.getItem("socilaLogIn");
    const navigate = useNavigate();
    const [userName, setUserName] = useState([]);
    const [mobileNavbarToggle, setMobileNavbarToggle] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        const userDataByAccounts = JSON.parse(localStorage.getItem('socialObj'));
        const userDataByEmail = JSON.parse(localStorage.getItem('UserData'));
        if (userDataByAccounts) {
            const Name = userDataByAccounts.FirstName + ' ' + userDataByAccounts.LastName;
            setUserName(Name);
        } else if (userDataByEmail) {
            setUserName(userDataByEmail.Data.UserName);
        } else {
            setUserName(null);
        }
    }, []);

    const handleclicksign = () => {
        var stri = localStorage.getItem("local_id_store");
        setMobileNavbarToggle(true);
        stri = parseInt(stri);
        if (stri > 0 || socilaLogIn === "true") {
            navigate("/userProfile");

        } else {
            navigate("/Signin");
        }
    };
    const handleRedirectToHome = () => {
        navigate("/");
    };

    const handleSignInSuccess = (newUserName) => {
        setUserName(newUserName);
      };
    return (
        <div>
            <header className={location.pathname === '/' ? 'dark-header new_header landing_header w-100' : 'dark-header new_header'}>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <nav class="navbar navbar-expand-lg p-0">
                                <div class="container-fluid px-0">
                                    <a class="navbar-brand p-0 me-0" style={{ cursor: "pointer" }} onClick={() => handleRedirectToHome()}>
                                        <img src={Logo} width="140px"
                                            alt="" className='d-lg-block d-md-none d-none' />
                                        <img src={LogoMobile} width="30px"
                                            alt="" className='d-lg-none d-md-block d-block' />
                                    </a>
                                    {/* 
                                    <div className='d-lg-none d-md-block d-block'>
                                    </div> */}
                                    <button class="navbar-toggler" type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent"
                                        aria-controls="navbarSupportedContent"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0003 29.3332C23.3643 29.3332 29.3337 23.3638 29.3337 15.9998C29.3337 8.63584 23.3643 2.6665 16.0003 2.6665C8.63633 2.6665 2.66699 8.63584 2.66699 15.9998C2.66699 23.3638 8.63633 29.3332 16.0003 29.3332ZM20.0003 13.3332C20.0003 14.394 19.5789 15.4115 18.8288 16.1616C18.0786 16.9117 17.0612 17.3332 16.0003 17.3332C14.9395 17.3332 13.922 16.9117 13.1719 16.1616C12.4218 15.4115 12.0003 14.394 12.0003 13.3332C12.0003 12.2723 12.4218 11.2549 13.1719 10.5047C13.922 9.7546 14.9395 9.33317 16.0003 9.33317C17.0612 9.33317 18.0786 9.7546 18.8288 10.5047C19.5789 11.2549 20.0003 12.2723 20.0003 13.3332ZM8.00033 22.6665C8.931 21.4237 10.1387 20.415 11.5274 19.7207C12.9161 19.0263 14.4477 18.6654 16.0003 18.6665C17.553 18.6654 19.0845 19.0263 20.4732 19.7207C21.862 20.415 23.0696 21.4237 24.0003 22.6665C23.0696 23.9093 21.862 24.918 20.4732 25.6123C19.0845 26.3067 17.553 26.6676 16.0003 26.6665C14.4477 26.6676 12.9161 26.3067 11.5274 25.6123C10.1387 24.918 8.931 23.9093 8.00033 22.6665Z" fill="#5BC0EB" />
                                        </svg>
                                    </button>
                                    <div
                                        class="collapse navbar-collapse ps-lg-5 ps-md-4 ps-0 justify-content-start"
                                        id="navbarSupportedContent">
                                        <ul
                                            class="navbar-nav mb-0 mb-lg-0 gap-3  pt-lg-0 pt-md-0 pt-4">
                                            <li class="nav-item dropdown">
                                                <a class="nav-link dropdown-toggle d-flex align-items-center text-lg-start text-center justify-content-center gap-lg-4 gap-3" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Product
                                                    <svg xmlns="http://www.w3.org/2000/svg" className='ms-2' width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M16.7998 9.5999L11.9998 14.3999L7.19981 9.5999" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </a>
                                                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <li><Link to='/scentaware' onClick={() => window.scrollTo(0, 0)} class="dropdown-item text-lg-start text-md-start text-center" >ScentAware</Link></li>
                                                    <li><Link to='/ren-aware' onClick={() => window.scrollTo(0, 0)} class="dropdown-item text-lg-start text-md-start text-center" >RenAware</Link></li>
                                                    <li><Link to='/IntelliVista' onClick={() => window.scrollTo(0, 0)} class="dropdown-item text-lg-start text-md-start text-center" >IntelliVista</Link></li>
                                                    <li><Link to='/oct-direct' onClick={() => window.scrollTo(0, 0)} class="dropdown-item text-lg-start text-md-start text-center" >OCT Direct</Link></li>
                                                </ul>
                                            </li>
                                            {/* <li class="nav-item">
                                                <Link to='/IntelliVista' class="nav-link text-lg-start text-center"
                                                    aria-current="page" onClick={() => window.scrollTo(0, 0)}
                                                >IntelliVista</Link>
                                            </li> */}
                                            {/* <li class="nav-item">
                                                <Link to='/product' class="nav-link text-lg-start text-center"
                                                    aria-current="page" onClick={() => window.scrollTo(0, 0)}
                                                >Products</Link>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link text-lg-start text-center">About us</a>
                                            </li> */}
                                            <li class="nav-item">
                                            <Link to='/custom-digital' class="nav-link  text-lg-start text-center"
                                                aria-current="page" onClick={() => window.scrollTo(0, 0)}
                                            >Digital Biomarkers</Link>
                                            </li>
                                            <li class="nav-item">
                                                {userName ? (
                                                    <Link to='/MainCheckOut' class="nav-link text-lg-start text-center"
                                                        aria-current="page" onClick={() => window.scrollTo(0, 0)}
                                                    >Cart</Link>
                                                ) : (
                                                    ''
                                                )}
                                            </li>
                                            {/* <Link to='/MainCheckOut' onClick={() => window.scrollTo(0, 0)}>
                                        <button
                                            class="btn btn-primary w-auto me-lg-3">Buy
                                            now</button>
                                        </Link> */}
                                        </ul>
                                        <div
                                            class="d-flex align-items-center gap-3 ms-lg-4 ms-lg-0 ms-0">
                                            {/* <button
                                                class="btn btn-primary py-3">Cart</button> */}

                                            {userName ? (
                                                <button
                                                    className="btn btn-primary text-nowrap"
                                                    onClick={handleclicksign}
                                                >
                                                    {userName}
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn  btn-primary"
                                                    onClick={openModal}
                                                >
                                                    Login
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>

            </header>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                className='login_modal'
            >
                <Signin closeModal={closeModal} onSignInSuccess={handleSignInSuccess}/>
            </Modal>
        </div>
    )
}

export default Header