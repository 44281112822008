import React, { useState } from "react";
import styled from "styled-components";
import resetPasswordLink from "../../assets/resetPasswordLink.svg";
import pattern1 from "../../assets/Pattern1.svg";
import pattern2 from "../../assets/pattern2.svg";
import { useNavigate, useLocation, Link } from "react-router-dom";
import logoBlack from "../../assets/logo-black.png";
import forgot_img from "../../assets/verification_code.png";
import forgot_mobile from "../../assets/verification_code_mobile.png";
import NavFooter from "../Reuseable Components/NavFooter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isMobile } from "react-device-detect";

const initialValues = {
  code: "",
};

const PasswordResetLink = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [load, setLoad] = useState(false);

  const validationSchema = Yup.object({
    code: Yup.string().required("Verification Code is required!")
      .min(6, "Please Enter valid verification code!")
  });

  console.log("email is", location.state?.email);
  const email = location.state?.email;

  const handleSubmit = (value) => {
    if (formik.isValid) {
      navigate("/reset_Password", {
        state: { code: value.code.toString(), email: email },
      });
    } else {
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });
  return (
    <Wrapper>

      <body className="renaware">
        <header className="dark-header new_header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav className="navbar navbar-expand-lg p-0">
                  <Link to='/' onClick={() => window.scrollTo(0, 0)} className="navbar-brand" >
                    <img src={logoBlack}
                      width="140px"
                      alt />
                  </Link>
                </nav>
              </div>
            </div>
          </div>
        </header>

        <section className="login_page inner-banner-section sign_up_outer d-flex align-items-center">
          <div className="container">
            <div
              className="row flex-lg-row flex-md-column-reverse flex-column-reverse">
              <div
                className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center pb-lg-0 pb-md-0 pb-4">
                <div className="card_title w-100">
                  <h2 className="mb-lg-4 mb-md-3 mb-3">Verification
                    code sent!</h2>
                  <p className="text-start m-0">We've fired a verification
                    code to your email faster than you can say<br />
                    'supercalifragilisticexpialidocious.' Check your
                    inbox (and maybe your spam folder,<br /> just in
                    case). Once you've got the code, pop it in
                    here and let's get you a brand new<br /> password.</p>
                  <div className="mt-4 pt-lg-2 form_feild">
                    <form action>
                      <div className="row">
                        <div
                          className="col-lg-7 col-md-12 col-sm-12 col-12">
                          <label for className="w-100">Enter
                            verification code : </label>
                          <input type="number"
                            id="code"
                            maxLength={6}
                            className={`form-control ${formik.touched.code && formik.errors.code
                              ? "is-invalid"
                              : ""
                              }`}
                            name="code"
                            placeholder="Verification Code"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.code}
                            isValid={formik.isValid}
                            isTouched={formik.touched.code}
                            invalidFeedback={formik.errors.code}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, "").slice(0, 6);
                            }} />
                          <div className="invalid-feedback">
                            {formik.errors.code}
                          </div>
                        </div>
                        <div
                          className="col-lg-7 col-md-12 col-sm-12 col-12 mt-lg-4 mt-3">
                          <button
                            onClick={formik.handleSubmit}
                            className="btn btn-primary mb-0">
                            {load ? (
                              <div className='w-100 align-items-center d-flex justify-content-center'>
                                <h1
                                  class="loader"
                                  disabled={load}
                                  style={{
                                    // color: "#101A36",
                                    margin: "-5px",
                                    width: '30px',
                                    height: '30px',
                                  }}
                                ></h1>
                              </div>
                            ) : (
                              "Submit"
                            )}</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-0 mb-lg-0 pb-lg-0">
                <div
                  className="forgot_img d-flex align-items-center justify-content-center">
                  <img src={forgot_img}
                    className="d-lg-block d-md-none d-none" alt />
                  <img src={forgot_mobile}
                    className="d-lg-none d-md-block d-block w-auto" alt />
                </div>
              </div>
            </div>
          </div>
        </section>
        <NavFooter />
      </body>

      {/* old----------- */}
      {/* <div className="row">
        <div className="card">
          <div className="row">
            <div className="col left-container">
              <section className="col-md-6 new">
                <img
                  src={resetPasswordLink}
                  alt="Cart_icon"
                  className="img-here"
                />
              </section>

              <section className="col-md-6 new">
                <img src={pattern2} alt="Cart_icon" className="img-Pattern2" />
              </section>
            </div>
            <div className="col">
              <div className="title">
                <h4>Password reset code sent!</h4>
              </div>

              <div className="discription">
                We’ve sent you a code to reset your password on your email.
                Enter the code below to reset your password and begin your
                Sensify experience today!
              </div>

              <div className="row code-section">
                <div>
                  <label for="Code" className="label-align">
                    Code:
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    name="Code"
                    placeholder=""
                    value={code}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="alignment-order">
                <div className="col continue">
                  <button
                    onClick={(e) => {
                      navigate("/reset_Password", {
                        state: { code: code, email: email },
                      });
                    }}
                    type="button"
                    value="Submit"
                  >
                    <span
                      style={{
                        textDecoration: "none",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Next
                    </span>
                  </button>
                </div>
              </div>

              <section className="col-md-6 new">
                <img src={pattern1} alt="Cart_icon" className="img-Pattern1" />
              </section>
            </div>
          </div>
        </div>
      </div> */}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .card {
    // position: absolute;
    width: 968px;
    height: 580px;
    // left: 284px;
    // top: 151px;

    background: #ffffff;
    box-shadow: 0px 4px 32px 16px rgba(30, 30, 30, 0.25);
    border-radius: 8px;
    margin: auto auto;
    margin-top: 150px;
    margin-bottom: 150px;
  }

  .title {
    // position: absolute;
    // width: 307px;
    height: 28px;
    // left: 530px;
    // top: 50px;

    font-family: "WorkSans";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    color: #4e4e4e;

    margin-bottom: 40px;
    margin-top: 40px;
  }

  .discription {
    // position: absolute;
    width: 307px;
    height: 76px;
    // left: 530px;
    // top: 100px;

    font-family: "WorkSans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #4e4e4e;
  }

  .alignment-order {
    // position: absolute;
    width: 328px;
    // height: 48px;
    // left: 530px;
    // top: 350px;
    filter: drop-shadow(0px 16px 24px rgba(38, 100, 157, 0.3));
  }

  .continue {
    // margin-top: 10px;
    // margin-left:20px;
  }
  .continue button {
    background-color: #22669c;
    /* box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3); */

    border: none;
    padding: 10px 0px;
    color: white;
    width: 100%;
  }

  .img-here {
    position: absolute;
    width: 360px;
    height: 300px;
    left: 100px;
    top: 200px;
  }

  .img-Pattern2 {
    position: absolute;
    width: 165px;
    height: 150px;
    left: 2px;
    top: 450px;

    mix-blend-mode: normal;
    border-radius: 8px 0px 0px 8px;
    transform: rotate(0deg);
  }

  .img-Pattern1 {
    position: absolute;
    width: 480px;
    height: 148px;
    left: 470px;
    top: 424px;

    mix-blend-mode: normal;
    opacity: 0.5;
    border-radius: 8px 0px 0px 8px;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  input[type="text"] {
    box-sizing: border-box;
    //    position: absolute;
    width: 328px;
    height: 48px;
    // left: 730px;
    // top: 385px;
    background: #ffffff;
    border: 1px solid #26649d;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-left: 20px;
    // margin-left:34px
  }

  .label-align {
    font-family: "WorkSans";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    // margin-left: 34px;
    // margin-top:230px
  }
  .right-container {
    margin-top: 20px;
  }
  .code-section {
    margin-top: 50px;
  }

  @media Screen and (min-width: 320px) and (max-width: 995px) {
    .left-container {
      display: none;
    }

    .card {
      width: auto;
      height: auto;
      box-shadow: unset;
      border: none;
    }
    .new {
      display: none;
    }

    .title {
      // position: absolute;
      //   width: 193px;
      //   height: 56px;
      //   left: 30px;
      //   top: 50px;

      font-family: "WorkSans";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;

      color: #4e4e4e;
    }

    .discription {
      // position: absolute;
      width: 296px;
      height: 64px;
      left: 30px;
      top: 120px;

      font-family: "WorkSans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      color: #4e4e4e;
    }

    input[type="number"] {
      box-sizing: border-box;
      //    position: absolute;
      width: 300px;
      height: 48px;
      left: 30px;
      top: 385px;
      background: #ffffff;
      border: 1px solid #26649d;
      border-radius: 8px;
      margin-top: 10px;
      margin-bottom: 15px;
      padding-left: 20px;
      //   margin-left: 34px;
    }

    .label-align {
      font-family: WorkSans;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      //   margin-left: 34px;
      //   margin-top: 230px;
    }

    .alignment-order {
      // position: absolute;
      width: 306px;
      height: 48px;
      left: 90px;
      top: 380px;

      filter: drop-shadow(0px 16px 24px rgba(38, 100, 157, 0.3));
    }
    .continue {
      margin-top: 10px;
      // margin-left:50px;
    }

    .continue button {
      background-color: #22669c;
      /* box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3); */

      border: none;
      padding: 10px 0px;
      color: white;
      width: 300px;
    }
  }
`;

export default PasswordResetLink;
