/** @format */

import React from "react";
import styled from "styled-components";

const Privacy = () => {
  return (
    <Wrapper>
      <section className="privay-policy"></section>
      <div className="container-fluid privacy_policy p-0">
        <div className="content">
          <p className="main-title text-center">Privacy Policy For SensifyAware</p>
          <p className="sub-title text-center">
            <strong>Last updated March 13, 2024</strong>
          </p>

          <div id="point-12">
            <div className="heading_1">
              Data Controller And Owner
            </div>
            {/* <p className="mt-3 text-color fs-14">
              If you have questions or comments about this notice, you may email
              us at <a href="mailto:info@sensifylife.com" target="_blank">info@sensifylife.com</a> or by post to:
            </p> */}
            <p className="mt-3 text-color fs-14">Sensify Inc</p>
            <div className="mt-3 text-color fs-14">251 EAST 32ND STREET 7C</div>
            <div className=" text-color fs-14">New York, 10016</div>
            <p className=" text-color fs-14">United States</p>
            <p className="mt-3 text-color fs-14"><a href="mailto:info@sensifylife.com" target="_blank">info@sensifylife.com</a></p>
          </div>

          <hr />
          <div className="text-color fs-14">
            This privacy policy for Sensify Inc (" <strong>Sensify Inc </strong>", "<strong>we</strong>
            ", "<strong>us</strong>" or "<strong>our</strong>
            "), describes how and why we might collect, store, use, and/or share
            ("<strong>process</strong>") your information when you use our
            services ("<strong>Services</strong>"), such as when you:
            <ul>
              <li>
                Download and use our mobile application (SensifyAware), or any
                other application of ours that links to this privacy policy
              </li>
              <li>
                Engage with us in other related ways, including any sales,
                marketing, or events
              </li>
            </ul>
            <p>
              <strong>
                <span className="text-color">Questions or concerns? </span>
              </strong>
              Reading this privacy policy will help you understand your privacy
              rights and choices. If you do not agree with our policies and
              practices, please do not use our Services. If you still have any
              questions or concerns, please contact us at <a href="mailto:info@sensifylife.com" target="_blank">info@sensifylife.com</a>
            </p>
          </div>
        </div>
        <div className="summary">
          <div className="heading_1">SUMMARY OF KEY POINTS</div>
          <span className="text-color fs-14">
            <strong>
              This summary provides key points from our privacy policy by using our table of
              contents below to find the section you are looking for.
            </strong>
          </span>

          <ul>
            <li>
              <strong>What personal information do we process?</strong> When you
              visit, use, or navigate our Services, we may process personal
              information depending on how you interact with SensifyLife and the
              Services, the choices you make, and the products and features you
              use.
            </li>
            <li>
              <strong>Do we process any sensitive personal information?</strong>{" "}
              We do not process sensitive personal information.
            </li>
            <li>
              <strong>Do we receive any information from third parties?</strong>{" "}
              We do not receive any information from third parties.
            </li>
            <li>
              We process your
              information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your information for other
              purposes with your consent. We process your information only when we
              have a valid legal reason to do so.</li>
            <li>
              We process your
              information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your information for other
              purposes with your consent. We process your information only when we
              have a valid legal reason to do so.</li>
            <li>
              <strong>
                In what situations and with which parties do we share personal
                information?
              </strong>{" "}
              We may share information in specific situations and with specific
              third parties.
            </li>
            <li>
              We have
              organizational and technical processes and procedures in place to
              protect your personal information. However, no electronic
              transmission over the internet or information storage technology can
              be guaranteed to be 100% secure, so we cannot promise or guarantee
              that hackers, cybercriminals, or other unauthorized third parties
              will not be able to defeat our security and improperly collect,
              access, steal, or modify your information.
            </li>
            <li>
              <strong>What are your rights?</strong> Depending on where you are
              located geographically, the applicable privacy law may mean you have
              certain rights regarding your personal information.
            </li>
            <li>

              <strong>How do you exercise your rights?</strong> The easiest way to
              exercise your rights is by filling out our data subject request by contacting us. We will consider and act upon any request in
              accordance with applicable data protection laws.
            </li>
          </ul>
          {/* <p className="mt-4 text-color fs-14">
          Want to learn more about what SensifyLife does with any information
          we collect?
        </p> */}
        </div>
        <div id="table-contents">
          <div id="point-1">
            <div className="heading_1">1. WHAT INFORMATION DO WE COLLECT?</div>
            {/* <div className="heading_2">
            Personal information you disclose to us
          </div>
          <p className="mt-3 text-color fs-14">
            <strong>In Short:</strong>
            We collect personal information that you provide to us.
          </p> */}
            <p className="mt-3 text-color fs-14">
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </p>
            <p className="mt-3 text-color fs-14">
              <strong>Personal Information Provided by You. </strong>
              The personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make,
              and the products and features you use. The personal information we
              collect may include the following:
              <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Age</li>
                <li>Gender</li>
              </ul>
            </p>
            <p className="mt-3 text-color fs-14">
              <strong>Sensitive Information.</strong>
              We do not process sensitive information.
            </p>
            {/* <p className="mt-3 text-color fs-14">
            <strong>Social Media Login Data.</strong>
            We may provide you with the option to register with us using your
            existing social media account details, like your Facebook,
            Twitter, or other social media account.
          </p>
          <p className="mt-3 text-color fs-14">
            <strong>Application Data.</strong>
            If you use our application(s), we also may collect the following
            information if you choose to provide us with access or permission:
            <ul>
              <li>
                Mobile Device Data. We automatically collect device
                information (such as your mobile device ID, model, and
                manufacturer), operating system, version information and
                system configuration information, device and application
                identification numbers, browser type and version, hardware
                model Internet service provider and/or mobile carrier, and
                Internet Protocol (IP) address (or proxy server). If you are
                using our application(s), we may also collect information
                about the phone network associated with your mobile device,
                your mobile device’s operating system or platform, the type of
                mobile device you use, your mobile device’s unique device ID,
                and information about the features of our application(s) you
                accessed.
              </li>
              <li>
                Push Notifications. We may request to send you push
                notifications regarding your account or certain features of
                the application(s). If you wish to opt out from receiving
                these types of communications, you may turn them off in your
                device's settings.
              </li>
            </ul>
          </p> */}
            <p className="mt-3 text-color fs-14">
              This information is primarily needed to maintain the security and
              operation of our application(s), for troubleshooting, and for our
              internal analytics and reporting purposes.
            </p>
            <p className="mt-3 text-color fs-14">
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </p>
            {/* <div className="heading_2">Information automatically collected</div>
          <p className="mt-3 text-color fs-14">
            <strong>In Short:</strong> Some information — such as your
            Internet Protocol (IP) address and/or browser and device
            characteristics — is collected automatically when you visit our
            Services.
          </p>
          <p className="mt-3 text-color fs-14">
            We automatically collect certain information when you visit, use,
            or navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </p>
          <p className="mt-3 text-color fs-14">
            The information we collect includes:
            <ul>
              <li>
                Log and Usage Data. Log and usage data is service-related,
                diagnostic, usage, and performance information our servers
                automatically collect when you access or use our Services and
                which we record in log files. Depending on how you interact
                with us, this log data may include your IP address, device
                information, browser type, and settings and information about
                your activity in the Services (such as the date/time stamps
                associated with your usage, pages and files viewed, searches,
                and other actions you take such as which features you use),
                device event information (such as system activity, error
                reports (sometimes called "crash dumps"), and hardware
                settings).
              </li>
              <li>
                Device Data. We collect device data such as information about
                your computer, phone, tablet, or other device you use to
                access the Services. Depending on the device used, this device
                data may include information such as your IP address (or proxy
                server), device and application identification numbers,
                location, browser type, hardware model, Internet service
                provider and/or mobile carrier, operating system, and system
                configuration information.
              </li>
            </ul>
          </p> */}
          </div>
          <div id="point-2">
            <div className="heading_1">
              2. HOW DO WE PROCESS YOUR INFORMATION?
            </div>
            {/* <p className="mt-3 text-color fs-14">
            <strong>In Short:</strong> We process your information to provide,
            improve, and administer our Services, communicate with you, for
            security and fraud prevention, and to comply with law. We may also
            process your information for other purposes with your consen
          </p> */}
            <p className="mt-3 text-color fs-14">
              <strong>
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
              </strong>
              <ul>
                <li>
                  <strong>
                    To facilitate account creation and authentication and
                    otherwise manage user accounts.
                  </strong> We may process your information so you can create and log in
                  to your account, as well as keep your account in working
                  order.
                </li>
                {/* <li>
                <strong>
                  To evaluate and improve our Services, products, marketing,
                  and your experience.
                </strong>
                We may process your information when we believe it is
                necessary to identify usage trends, determine the
                effectiveness of our promotional campaigns, and to evaluate
                and improve our Services, products, marketing, and your
                experience.
              </li> */}
              </ul>
            </p>
          </div>
          <div id="point-3">
            <div className="heading_1">
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </div>
            {/* <p className="mt-3 text-color fs-14">
            <strong>In Short:</strong> We only process your personal
            information when we believe it is necessary and we have a valid
            legal reason (i.e., legal basis) to do so under applicable law,
            like with your consent, to comply with laws, to provide you with
            services to enter into or fulfill our contractual obligations, to
            protect your rights, or to fulfill our legitimate business
            interests.
          </p> */}
            <p
              className="mt-3 text-color fs-14"
              style={{ textDecoration: "underline" }}
            >
              <strong>
                If you are located in Canada, this section applies to you.
              </strong>
            </p>
            <p className="mt-3 text-color fs-14">
              We may process your information if you have given us specific
              permission (i.e., express consent) to use your personal
              information for a specific purpose, or in situations where your
              permission can be inferred (i.e., implied consent). You can
              withdraw your consent at any time.
            </p>
            <p className="mt-3 text-color fs-14">
              In some exceptional cases, we may be legally permitted under
              applicable law to process your information without your consent,
              including, for example:
              <ul>
                <li>
                  If collection is clearly in the interests of an individual and
                  consent cannot be obtained in a timely way
                </li>
                <li>For investigations and fraud detection and prevention</li>
                <li>
                  For business transactions provided certain conditions are met
                </li>
                <li>
                  If it is contained in a witness statement and the collection
                  is necessary to assess, process, or settle an insurance claim
                </li>
                <li>
                  For identifying injured, ill, or deceased persons and
                  communicating with next of kin
                </li>
                <li>
                  If we have reasonable grounds to believe an individual has
                  been, is, or may be victim of financial abuse
                </li>
                <li>
                  If it is reasonable to expect collection and use with consent
                  would compromise the availability or the accuracy of the
                  information and the collection is reasonable for purposes
                  related to investigating a breach of an agreement or a
                  contravention of the laws of Canada or a province
                </li>
                <li>
                  If disclosure is required to comply with a subpoena, warrant,
                  court order, or rules of the court relating to the production
                  of records
                </li>
                <li>
                  If it was produced by an individual in the course of their
                  employment, business, or profession and the collection is
                  consistent with the purposes for which the information was
                  produced
                </li>
                <li>
                  If the collection is solely for journalistic, artistic, or
                  literary purposes
                </li>
                <li>
                  If the information is publicly available and is specified by
                  the regulations
                </li>
              </ul>
            </p>
          </div>
          {/* <div id="point-4">
          <div className="heading_1">
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </div>
          <p className="mt-3 text-color fs-14">
            <strong>In Short:</strong> We may share information in specific
            situations described in this section and/or with the following
            third parties.
          </p>
          <p className="mt-3 text-color fs-14">
            We may need to share your personal information in the following
            situations:
            <ul>
              <li>
                <strong>Business Transfers.</strong> We may share or transfer
                your information in connection with, or during negotiations
                of, any merger, sale of company assets, financing, or
                acquisition of all or a portion of our business to another
                company.
              </li>
              <li>
                <strong>Affiliates.</strong> We may share your information
                with our affiliates, in which case we will require those
                affiliates to honor this privacy policy. Affiliates include
                our parent company and any subsidiaries, joint venture
                partners, or other companies that we control or that are under
                common control with us.
              </li>
              <li>
                <strong>Business Partners.</strong> We may share your
                information with our business partners to offer you certain
                products, services, or promotions.
              </li>
            </ul>
          </p>
        </div> */}
          {/* <div id="point-5">
          <div className="heading_1">
            4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
          </div>
          <p className="mt-3 text-color fs-14">
            <strong>In Short:</strong> If you choose to register or log in to
            our services using a social media account, we may have access to
            certain information about you.
          </p>
          <p className="mt-3 text-color fs-14">
            Our Services offer you the ability to register and log in using
            your third-party social media account details (like your Facebook
            or Twitter logins). Where you choose to do this, we will receive
            certain profile information about you from your social media
            provider. The profile information we receive may vary depending on
            the social media provider concerned, but will often include your
            name, email address, friends list, and profile picture, as well as
            other information you choose to make public on such a social media
            platform.
          </p>
          <p className="mt-3 text-color fs-14">
            We will use the information we receive only for the purposes that
            are described in this privacy policy or that are otherwise made
            clear to you on the relevant Services. Please note that we do not
            control, and are not responsible for, other uses of your personal
            information by your third-party social media provider. We
            recommend that you review their privacy policy to understand how
            they collect, use, and share your personal information, and how
            you can set your privacy preferences on their sites and apps.
          </p>
        </div> */}
          <div id="point-6">
            <div className="heading_1">
              4. HOW LONG DO WE KEEP YOUR INFORMATION?
            </div>
            {/* <p className="mt-3 text-color fs-14">
            <strong>In Short:</strong> We keep your information for as long as
            necessary to fulfill the purposes outlined in this privacy policy
            unless otherwise required by law.
          </p> */}
            <p className="mt-3 text-color fs-14">
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy policy, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </p>
            {/* <p className="mt-3 text-color fs-14">
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because
            your personal information has been stored in backup archives),
            then we will securely store your personal information and isolate
            it from any further processing until deletion is possible.
          </p> */}
          </div>
          <div id="point-7">
            <div className="heading_1">
              5. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </div>
            {/* <p className="mt-3 text-color fs-14">
            <strong>In Short:</strong> We aim to protect your personal
            information through a system of organizational and technical
            security measures.
          </p> */}
            <p className="mt-3 text-color fs-14">
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </p>
          </div>
          <div id="point-8">
            <div className="heading_1">6. WHAT ARE YOUR PRIVACY RIGHTS?</div>
            {/* <p className="mt-3 text-color fs-14">
            <strong>In Short:</strong> In some regions, such as Canada, you
            have rights that allow you greater access to and control over your
            personal information. You may review, change, or terminate your
            account at any time.
          </p> */}
            <p className="mt-3 text-color fs-14">
              In some regions (like Canada), you have certain rights under
              applicable data protection laws. These may include the right (i)
              to request access and obtain a copy of your personal information,
              (ii) to request rectification or erasure; (iii) to restrict the
              processing of your personal information; and (iv) if applicable,
              to data portability. In certain circumstances, you may also have
              the right to object to the processing of your personal
              information.
            </p>
            <p className="mt-3 text-color fs-14">
              We will consider and act upon any request in accordance with
              applicable data protection laws.
            </p>
            <p className="mt-3 text-color fs-14">
              If you are located in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your local data protection supervisory
              authority.
            </p>
            <p className="mt-3 text-color fs-14">
              If you are located in Switzerland, the contact details for the
              data protection authorities are available here:{" "}
              <a
                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                target="blank"
              >
                https://www.edoeb.admin.ch/edoeb/en/home.html.
              </a>
            </p>
            {/* <p className="mt-3 text-color fs-14">
            <b>
              Withdrawing your consent: 
            </b>
            
            If we are relying on your consent to process your personal
            information, which may be express and/or implied consent depending
            on the applicable law, you have the right to withdraw your consent
            at any time.
          </p>
          <p className="mt-3 text-color fs-14">
            However, please note that this will not affect the lawfulness of
            the processing before its withdrawal nor, when applicable law
            allows, will it affect the processing of your personal information
            conducted in reliance on lawful processing grounds other than
            consent.
          </p> */}
            <div className="heading_2">Account Information</div>
            <p className="mt-3 text-color fs-14">
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
              <ul>
                <li>
                  Log in to your account settings and update your user account.
                </li>
                <li>Contact us using the contact information provided.</li>
              </ul>
            </p>
            <p className="mt-3 text-color fs-14">
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements.
            </p>
            <p className="mt-3 text-color fs-14">
              If you have questions or comments about your privacy rights, you
              may email us at <a href="mailto:info@sensifylife.com" target="_blank">info@sensifylife.com</a>.
            </p>
          </div>
          {/* <div id="point-9">
          <div className="heading_1">
            8. CONTROLS FOR DO-NOT-TRACK FEATURES
          </div>
          <p className="mt-3 text-color fs-14">
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism
            that automatically communicates your choice not to be tracked
            online. If a standard for online tracking is adopted that we must
            follow in the future, we will inform you about that practice in a
            revised version of this privacy policy.
          </p>
        </div> */}
          <div id="point-10">
            <div className="heading_1">
              7. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </div>
            {/* <p className="mt-3 text-color fs-14">
            <strong>In Short:</strong> Yes, if you are a resident of
            California, you are granted specific rights regarding access to
            your personal information.
          </p> */}
            <p className="mt-3 text-color fs-14">
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p className="mt-3 text-color fs-14">
              If you are under 18 years of age, reside in California, and have a
              registered account with Services, you have the right to request
              removal of unwanted data that you publicly post on the Services.
              To request removal of such data, please contact us using the
              contact information provided below and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Services, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.,
              backups, etc.).
            </p>
            <div className="heading_2">CCPA Privacy Policy</div>
            <p className="mt-3 text-color fs-14">
              The California Code of Regulations defines a "resident" as:
            </p>
            <p className="mt-3 text-color fs-14">
              <ul>
                (1) every individual who is in the State of California for other
                than a temporary or transitory purpose and
                <br /> (2) every individual who is domiciled in the State of
                California who is outside the State of California for a
                temporary or transitory purpose
              </ul>
            </p>
            <p className="mt-3 text-color fs-14">
              All other individuals are defined as "non-residents."
            </p>
            <p className="mt-3 text-color fs-14">
              If this definition of "resident" applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.
            </p>
            {/* <p className="mt-3 text-color fs-14">
            <strong className="heading_2">
              What categories of personal information do we collect?
            </strong>
          </p>
          <p className="mt-3 text-color fs-14">
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </p>
          <table>
            <tbody>
              <tr>
                <th>Category</th>
                <th>Examples</th>
                <th>Collected</th>
              </tr>
              <tr>
                <td> A. Identifiers</td>
                <td>
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account na
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>
                  B. Personal information categories listed in the California
                  Customer Records statute
                </td>
                <td>
                  Name, contact information, education, employment, employment
                  history, and financial information
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>
                  C. Protected classification characteristics under California
                  or federal law
                </td>
                <td>Gender and date of birth</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>D. Commercial information</td>
                <td>
                  Transaction information, purchase history, financial
                  details, and payment information
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>E. Biometric information</td>
                <td>Fingerprints and voiceprints</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>F. Internet or other similar network activity</td>
                <td>
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>G. Geolocation data</td>
                <td>Device location</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  H. Audio, electronic, visual, thermal, olfactory, or similar
                  informatio
                </td>
                <td>
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>I. Professional or employment-related information</td>
                <td>
                  Business contact details in order to provide you our
                  Services at a business level or job title, work history, and
                  professional qualifications if you apply for a job with us
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>J. Education Informatio</td>
                <td>Student records and directory information</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>K. Inferences drawn from other personal information</td>
                <td>
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary
                  about, for example, an individual’s preferences and
                  characteristics
                </td>
                <td>NO</td>
              </tr>
            </tbody>
          </table>
          <p className="mt-3 text-color fs-14">
            We may also collect other personal information outside of these
            categories through instances where you interact with us in person,
            online, or by phone or mail in the context of:
            <ul>
              <li>Receiving help through our customer support channels;</li>
              <li>Participation in customer surveys or contests; and</li>
              <li>
                Facilitation in the delivery of our Services and to respond to
                your inquiries.
              </li>
            </ul>
          </p>
          <div className="heading_2">
            How do we use and share your personal information?
          </div>
          <p className="mt-3 text-color fs-14">
            More information about our data collection and sharing practices
            can be found in this privacy policy.
          </p>
          <p className="mt-3 text-color fs-14">
            You may contact us by visiting{" "}
            <a href="https://www.sensifyaware.com" target="blank">
              https://www.sensifyaware.com
            </a>
            , or by referring to the contact details at the bottom of this
            document.
          </p>
          <p className="mt-3 text-color fs-14">
            If you are using an authorized agent to exercise your right to opt
            out we may deny a request if the authorized agent does not submit
            proof that they have been validly authorized to act on your
            behalf.
          </p>
          <div className="heading_2">
            Will your information be shared with anyone else?
          </div>
          <p className="mt-3 text-color fs-14">
            We may disclose your personal information with our service
            providers pursuant to a written contract between us and each
            service provider. Each service provider is a for-profit entity
            that processes the information on our behalf.
          </p>
          <p className="mt-3 text-color fs-14">
            We may use your personal information for our own business
            purposes, such as for undertaking internal research for
            technological development and demonstration. This is not
            considered to be "selling" of your personal information.
          </p>
          <p className="mt-3 text-color fs-14">
            Sensify Inc has not disclosed or sold any personal information
            to third parties for a business or commercial purpose in the
            preceding twelve (12) months. Sensify Inc will not sell
            personal information in the future belonging to website visitors,
            users, and other consumers.
          </p>
          <div className="heading_2">
            Your rights with respect to your personal data
          </div>
          <p
            className="mt-3 text-color fs-14"
            style={{ textDecoration: "underline" }}
          >
            Right to request deletion of the data — Request to delete
          </p>
          <p className="mt-3 text-color fs-14">
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her right to free speech,
            our compliance requirements resulting from a legal obligation, or
            any processing that may be required to protect against illegal
            activities.
          </p>
          <p
            className="mt-3 text-color fs-14"
            style={{ textDecoration: "underline" }}
          >
            Right to be informed — Request to know
          </p>
          <p className="mt-3 text-color fs-14">
            Depending on the circumstances, you have a right to know:
            <ul>
              <li>whether we collect and use your personal information;</li>
              <li>the categories of personal information that we collect;</li>
              <li>
                the purposes for which the collected personal information is
                used;
              </li>
              <li>
                whether we sell your personal information to third parties;
              </li>
              <li>
                the categories of personal information that we sold or
                disclosed for a business purpose;
              </li>
              <li>
                the categories of third parties to whom the personal
                information was sold or disclosed for a business purpose; and
              </li>
              <li>
                the business or commercial purpose for collecting or selling
                personal information.
              </li>
            </ul>
          </p>
          <p className="mt-3 text-color fs-14">
            In accordance with applicable law, we are not obligated to provide
            or delete consumer information that is de-identified in response
            to a consumer request or to re-identify individual data to verify
            a consumer request.
          </p>
          <p
            className="mt-3 text-color fs-14"
            style={{ textDecoration: "underline" }}
          >
            Right to Non-Discrimination for the Exercise of a Consumer’s
            Privacy Rights
          </p>
          <p className="mt-3 text-color fs-14">
            We will not discriminate against you if you exercise your privacy
            rights.
          </p>
          <p
            className="mt-3 text-color fs-14"
            style={{ textDecoration: "underline" }}
          >
            Verification process
          </p>
          <p className="mt-3 text-color fs-14">
            Upon receiving your request, we will need to verify your identity
            to determine you are the same person about whom we have the
            information in our system. These verification efforts require us
            to ask you to provide information so that we can match it with
            information you have previously provided us. For instance,
            depending on the type of request you submit, we may ask you to
            provide certain information so that we can match the information
            you provide with the information we already have on file, or we
            may contact you through a communication method (e.g., phone or
            email) that you have previously provided to us. We may also use
            other verification methods as the circumstances dictate..
          </p>
          <p className="mt-3 text-color fs-14">
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the
            extent possible, we will avoid requesting additional information
            from you for the purposes of verification. However, if we cannot
            verify your identity from the information already maintained by
            us, we may request that you provide additional information for the
            purposes of verifying your identity and for security or
            fraud-prevention purposes. We will delete such additionally
            provided information as soon as we finish verifying you.
          </p>
          <p
            className="mt-3 text-color fs-14"
            style={{ textDecoration: "underline" }}
          >
            Other privacy rights
          </p>
          <p className="mt-3 text-color fs-14">
            <ul>
              <li>
                You may object to the processing of your personal information.
              </li>
              <li>
                You may request correction of your personal data if it is
                incorrect or no longer relevant, or ask to restrict the
                processing of the information.
              </li>
              <li>
                You can designate an authorized agent to make a request under
                the CCPA on your behalf. We may deny a request from an
                authorized agent that does not submit proof that they have
                been validly authorized to act on your behalf in accordance
                with the CCPA.
              </li>
              <li>
                You may request to opt out from future selling of your
                personal information to third parties. Upon receiving an
                opt-out request, we will act upon the request as soon as
                feasibly possible, but no later than fifteen (15) days from
                the date of the request submission.
              </li>
            </ul>
          </p>
          <p className="mt-3 text-color fs-14">
            To exercise these rights, you can contact us by visiting{" "}
            <a href="https://www.sensifyaware.com" target="blank">
              https://www.sensifyaware.com
            </a>
            , or by referring to the contact details at the bottom of this
            document. If you have a complaint about how we handle your data,
            we would like to hear from you.
          </p> */}
          </div>
          <div id="point-11">
            <div className="heading_1">
              8. DO WE MAKE UPDATES TO THIS NOTICE?
            </div>
            {/* <p className="mt-3 text-color fs-14">
            <strong>In Short:</strong>Yes, we will update this notice as
            necessary to stay compliant with relevant laws.
          </p> */}
            <p className="mt-3 text-color fs-14">
              We may update this privacy policy from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy policy, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy policy frequently to be informed of how we are
              protecting your information.
            </p>
          </div>

          <div id="point-13">
            <div className="heading_1">
              9. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </div>
            <p className="mt-3 text-color fs-14">
              Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. Also you can delete your account from application directly and then we will remove all your information from our database.
            </p>

          </div>.
        </div>
      </div>
    </Wrapper >
  );
};

export default Privacy;

const Wrapper = styled.section`
  html {
    scroll-padding-top: 100px;
  }
  .container-fluid {
    width: 63%;
    font-family: Arial;
  }
  .privay-policy {
    background-color: #153f67;
    padding-top: 100px;
  }
  .content {
    margin: 20px 0;
  }
  p {
    margin: 0;
  }
  .main-title {
    font-size: 40px;
    color: #000000;
    font-weight: bold;
  }
  .sub-title {
    color: #595959;
    font-size: 14px;
    margin: 10px 0 30px;
  }
  ul {
    list-style-type: disc;
  }
  a
  {
    color:#2B87D0;
    text-decoration:none;
  }
  li {
    margin: 10px 0;
  }
  .text-color {
    color: #000;
  }
  .summary {
    margin: 20px 0;
  }
  .heading_1 {
    font-size: 24px;
    font-weight: bold;
    margin: 20px 0;
  }
  .heading_2 {
    font-size: 20px;
    font-weight: bold;
    margin: 15px 0;
  }
  .fs-14 {
    font-size: 16px;
  }
  #table-contents {
    margin: 40px 0;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  table,
  td,
  th {
    border: 1px solid;
    color: #595959;
    font-size: 16px;
  }
  td {
    padding: 10px 5px;
  }
  td:last-child,
  th:last-child {
    text-align: center;
    width: 10%;
  }
`;
