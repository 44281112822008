import React, { useState } from "react";
import styled from "styled-components";
import Checkout from "./Checkout";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../context/context";
import { isMobile } from "react-device-detect";

function Product_Cart({ id, price, amount, main_header, img, pecriptionHeading, shelf_life, desc }, props) {
  const { total } = useGlobalContext();
  const PersistedTotal = localStorage.getItem("total");
  const PersistedSubTotal = localStorage.getItem("subtotal");

  const [nav, SetNav] = useState([]);
  const navigate = useNavigate();
  const handleClicktest = (link, obj) => {
    props.onClick(link, obj);
  };
  const handleClick = () => {
    if (window.location.href === "http://localhost:3000/MainCheckOut") {
      navigate("/Shipping");
    } else if (window.location.href === "http://localhost:3000/Payment") {
      navigate("/Order_Review");
    } else if (window.location.href === "http://localhost:3000/Order_Review") {
      navigate("/Thankyou");
    }
  };

  return (
    <Wrapper>
      <div class="container-fluid1 padding-0">
        <div class="">
          {/* <div class="col-lg-9"></div> */}

          {!isMobile && (
            <div class="mobile-line">
              {/* <img src={line_1} alt="" /> */}
              <hr style={{ marginLeft: "60px", width: "105%" }} />
            </div>
          )}
          {/* <div class="col-lg-3  col-md-6 col-sm-12 order"></div> */}
          <div class={!isMobile && "order"}>
            <div className="s" style={{ display: "flex" }}>
              <span style={{ fontWeight: "500", textAlign: "center" }}>
                Order Summary
              </span>
              {/* <img src={line_2} class="mb" /> */}
              <hr
                style={{
                  marginLeft: "20px",
                  width: "50%",
                }}
                class="mb"
              />
            </div>
            {/* <div
                class="col"
                style={{ fontWeight: "500", paddingBottom: "20px" }}
              >
                i-Sticks, Scent Evaluation kit
              </div> */}
            <div class="alignment-order">
              <table class="table table-borderless">
                <tbody>
                  <tr class="t">
                    <td>Total</td>
                    <td>${Number(PersistedTotal)?.toFixed(2)}</td>
                  </tr>
                  <tr class="text">
                    <td>Sub-total</td>
                    {/* <td>${PersistedSubTotal}</td> */}
                    <td>${Number(PersistedSubTotal)?.toFixed(2)}</td>
                  </tr>
                  <tr class="text">
                    <td>Tax</td>
                    <td>0%</td>
                  </tr>
                  <tr class="text">
                    <td>
                      Shipping
                      
                      <span
                        style={
                          isMobile ? { fontSize: 12, color: "#4E4E4E" } : null
                        }
                      >
                        (4-7 business days)
                      </span>
                    </td>
                    <td>$0.00</td>
                  </tr>
                  <tr class="text">
                    <td>Standard delivery</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              {/* <img src={line_3} alt="" /> */}
              <hr className="hr" style={{ paddingTop: "2px" }} />
              <table class="table table-borderless">
                <tbody>
                  <tr class="tr">
                    <td>Total</td>
                    <td style={{ paddingLeft: "170px" }}>
                      ${Number(PersistedTotal)?.toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="alignment-order">
              <div
                class="col continue"
                style={{ visibility: props.visibility }}
              >
                {!isMobile && (
                  <button
                    onClick={handleClicktest}
                    type="submit"
                    value="Submit"
                  >
                    <a
                      style={{
                        textDecoration: "none",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Continue
                    </a>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  .tr {
    display: none;
  }
  .hr {
    display: none;
  }
  .container-fluid {
    ${isMobile ? `width: 100%` : `width: 100%`}
  }
  .padding-0 {
    padding-right: 0;
    padding-left: 0px;
  }
  .mb {
    display: none;
  }
  .s {
    padding-bottom: 20px;
  }
  .tr {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  /* product-cart */

  .mobile-line {
    display: none;
  }
  .mobile-line1 {
    display: none;
  }
  .order {
    box-shadow: 16px 16px 48px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 30px 20px 20px 20px;
    height: auto;
  }
  .text {
    ${isMobile
      ? `font-size: 16px;
      font-family: 'WorkSans';
         text-align: left;
         color:#4E4E4E;
         font-weight: var(--font-weight-300);`
      : `font-size: 16px;
        font-weight: var(--font-weight-300);
        font-style: var(--font-sytle-normal);
        line-height: 14px;
        font-family: 'WorkSans';
        color: rgba(0, 0, 0, 0.55);`}
  }
  .custom-select select {
    width: 100%;
    padding: 12px 0px;
    border: 0px solid #26649d;
    border-radius: 8px;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    font-family: "WorkSans";
    ${isMobile && `font-size: 16px;`}
    -webkit-appearance: none;
  }
  .continue {
    padding-top: 0px;
  }
  .continue button {
    background-color: #22669c;
    /* box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3); */
    border-radius: 8px;
    border: none;
    padding: 10px 0px;
    color: white;
    width: 100%;
  }

  @media screen and (min-width: 320px) and (max-width: 995px) {
    .t {
      display: none;
    }
    .hr {
      display: block;
    }
    .tr {
      display: block;
    }
    .mb {
      display: block;
    }
    .mobile-line {
      // display: block !important;
      // padding-top: 20px;
      // padding-bottom: 10px;
    }
    .mobile-line1 {
      display: block !important;
      padding-top: 20px;
      width: 100%;
    }

    .order {
      padding: 0px !important;
      padding-left: 0px !important;
      //   display: none;
      //   padding: 0px 20px;
      box-shadow: none;
    }
    .alignment-order {
      padding-bottom: 10px;
    }
    .custom-select select {
      // width: 80%;
      padding: 0px 0px;
      border: 0px solid #26649d;
      border-radius: 8px;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      font-family: "WorkSans";
    }
    .custom-select {
      justify-content: center;
    }
    .continue button {
      // width: 80%;
    }
  }
  /* product-cart end */
`;

export default Product_Cart;
