import Description from "./Description";
import Hero from "./Hero";
const Mission = () => {
  return (
    <>
      <Hero />
      <Description/>
    </>
  );
};

export default Mission;
