
import React from "react";
import { useNavigate, Link } from "react-router-dom";
import logoBlack from "../assets/logo-black.png";
import getTheAppImg from "../assets/get_the_app.png";
import NewFooter from '../components/Reuseable Components/NewFooter';
import apple from '../assets/apple.png'
import andriod from '../assets/andriod.png'

function getTheApp() {
    return (
        <>
            <body className="renaware">
                <header class="dark-header new_header">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <nav class="navbar navbar-expand-lg p-0">
                                    <Link to='/' onClick={() => window.scrollTo(0, 0)} class="navbar-brand">
                                        <img src={logoBlack}
                                            width="140px"
                                            alt />
                                    </Link>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>

                <section class="get_the_app">
                    <div class="container">
                        <div class="app_card px-lg-5 px-md-4 px-3">
                            <div class="row g-4 flex-lg-row flex-md-row flex-column-reverse">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                    <div class="card_title">

                                        <a class="btn btn-primary w-100 d-lg-none d-md-none d-flex align-items-center justify-content-center" onClick={() => {
                                            window.open(
                                                "https://play.google.com/store/apps/details?id=com.sensifyaware"
                                            );
                                        }}>Get
                                            the
                                            app</a>
                                        <div className="d-lg-none d-md-none d-flex align-items-center gap-3 mb-4 mt-4 pwd_note">
                                            <img src={apple} alt=""
                                                onClick={() => {
                                                    window.open(
                                                        "https://apps.apple.com/in/app/sensifyaware/id1638890466"
                                                    );
                                                }} />
                                            <img src={andriod} alt="" onClick={() => {
                                                window.open(
                                                    "https://play.google.com/store/apps/details?id=com.sensifyaware"
                                                );
                                            }} />
                                            <p className="m-0 blue">Available on iOS and Android.</p>
                                        </div>
                                        <h2 className="mb-0">A digital biomarker platform for your senses
                                            and
                                            cognition.</h2>
                                        <p class="text-start mt-lg-4 mt-md-4 mt-4 mb-lg-4 mb-md-4 mb-0 py-lg-2">The SensifyAware app is free and when used with the Scentaware kits, it helps evaluate your sense of smell. Additionally the Smell Training module reveals scent identities, allowing you to familiarize yourself with them. Evaluate your cognitive abilities with other tests like TraceAware, featured in the app.</p>
                                        <a class="btn btn-primary w-auto d-lg-inline-flex d-md-inline-flex d-none align-items-center justify-content-center" onClick={() => {
                                            window.open(
                                                "https://play.google.com/store/apps/details?id=com.sensifyaware"
                                            );
                                        }}>Get
                                            the
                                            app</a>

                                        <div className="d-lg-flex d-md-flex d-none align-items-center gap-3 mt-4 pwd_note">
                                            <img src={apple} alt="" onClick={() => {
                                                window.open(
                                                    "https://apps.apple.com/in/app/sensifyaware/id1638890466"
                                                );
                                            }} />
                                            <img src={andriod} alt="" onClick={() => {
                                                window.open(
                                                    "https://play.google.com/store/apps/details?id=com.sensifyaware"
                                                );
                                            }} />
                                            <p className="m-0 blue">Available on iOS and Android.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 m-auto col-md-12 col-sm-12 col-12">
                                    <img src={getTheAppImg} width="100%" alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <NewFooter />
            </body>
        </>
    )
}

export default getTheApp;