import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useGlobalContext } from "../context/context";
import { useLocation } from "react-router-dom";
import App from "./App/App";
import { isMobile } from "react-device-detect";
import { useNavigate, Link } from 'react-router-dom';
import logoBlack from "../assets/logo-black.png";
import right_arrow from "../assets/right_arrow.png";
import NewFooter from "./Reuseable Components/NewFooter";
import * as Yup from "yup";

const Payment = (props) => {
  const [PublishableKey, setPublishableKey] = useState();
  const [load, setLoad] = useState(false);
  let res = {};
  var thenum = 0;
  const location = useLocation();
  const { cart, total, increase2, amount3 } = useGlobalContext();
  const navigate = useNavigate();
  const [userName, setUserName] = useState([]);

  useEffect(() => {
    const userDataByAccounts = JSON.parse(localStorage.getItem('socialObj'));
    const userDataByEmail = JSON.parse(localStorage.getItem('UserData'));
    if (userDataByAccounts) {
      const Name = userDataByAccounts.FirstName + ' ' + userDataByAccounts.LastName;
      setUserName(Name);
    } else if (userDataByEmail) {
      setUserName(userDataByEmail.Data.UserName);
    } else {
      setUserName(null);
    }
  }, []);

  const handleClick = () => {
    navigate("/Shipping");
  };

  return (
    <>
      <body className="renaware">
        <header className="dark-header new_header checout">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav
                  class="navbar navbar-expand-lg justify-content-between p-0">
                  <Link to='/' class="navbar-brand" >
                    <img src={logoBlack}
                      width="140px"
                      alt />
                  </Link>

                  <ul
                    class="navbar-nav ms-auto d-lg-block d-md-block d-none mb-0 mb-lg-0 gap-3">
                    <li class="nav-item">
                      <Link to='/userProfile' class="nav-link"
                        aria-current="page"
                      >{userName}</Link>
                    </li>
                  </ul>
                  <button class="navbar-toggler" type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32" viewBox="0 0 32 32" fill="none">
                      <path fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.0003 29.3337C23.3643 29.3337 29.3337 23.3643 29.3337 16.0003C29.3337 8.63633 23.3643 2.66699 16.0003 2.66699C8.63633 2.66699 2.66699 8.63633 2.66699 16.0003C2.66699 23.3643 8.63633 29.3337 16.0003 29.3337ZM20.0003 13.3337C20.0003 14.3945 19.5789 15.4119 18.8288 16.1621C18.0786 16.9122 17.0612 17.3337 16.0003 17.3337C14.9395 17.3337 13.922 16.9122 13.1719 16.1621C12.4218 15.4119 12.0003 14.3945 12.0003 13.3337C12.0003 12.2728 12.4218 11.2554 13.1719 10.5052C13.922 9.75509 14.9395 9.33366 16.0003 9.33366C17.0612 9.33366 18.0786 9.75509 18.8288 10.5052C19.5789 11.2554 20.0003 12.2728 20.0003 13.3337ZM8.00033 22.667C8.931 21.4242 10.1387 20.4155 11.5274 19.7212C12.9161 19.0268 14.4477 18.6659 16.0003 18.667C17.553 18.6659 19.0845 19.0268 20.4732 19.7212C21.862 20.4155 23.0696 21.4242 24.0003 22.667C23.0696 23.9098 21.862 24.9184 20.4732 25.6128C19.0845 26.3072 17.553 26.6681 16.0003 26.667C14.4477 26.6681 12.9161 26.3072 11.5274 25.6128C10.1387 24.9184 8.931 23.9098 8.00033 22.667Z"
                        fill="#031A40" />
                    </svg>
                  </button>
                  <div
                    class="collapse navbar-collapse d-lg-none d-md-none ps-lg-5 ps-md-4 ps-0 justify-content-start"
                    id="navbarSupportedContent">
                    <ul
                      class="navbar-nav mb-0 mb-lg-0 gap-3  pt-lg-0 pt-md-0 pt-4">
                      <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle d-flex align-items-center text-lg-start text-center justify-content-center gap-lg-4 gap-3" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Product
                          <svg xmlns="http://www.w3.org/2000/svg" className='ms-2' width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M16.7998 9.5999L11.9998 14.3999L7.19981 9.5999" stroke="#031A40" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                          <li><Link to='/scentaware' onClick={() => window.scrollTo(0, 0)} class="dropdown-item text-center" >ScentAware</Link></li>
                          <li><Link to='/ren-aware' onClick={() => window.scrollTo(0, 0)} class="dropdown-item text-center" >RenAware</Link></li>
                          <li><Link to='/IntelliVista' onClick={() => window.scrollTo(0, 0)} class="dropdown-item text-center" >IntelliVista</Link></li>
                          <li><Link to='/oct-direct' onClick={() => window.scrollTo(0, 0)} class="dropdown-item text-center" >OCT Direct</Link></li>
                        </ul>
                      </li>
                      <li class="nav-item">
                        <Link to='/custom-digital' class="nav-link  text-lg-start text-center"
                          aria-current="page" onClick={() => window.scrollTo(0, 0)}
                        >Digital Biomarkers</Link>
                      </li>
                      <li class="nav-item">
                        {userName ? (
                          <Link to='/MainCheckOut' class="nav-link text-lg-start text-center"
                            aria-current="page" onClick={() => window.scrollTo(0, 0)}
                          >Cart</Link>
                        ) : (
                          ''
                        )}
                      </li>
                    </ul>
                    <div
                      class="d-flex align-items-center gap-3 ms-lg-4 ms-lg-0 ms-0">
                      {userName && (
                        <button
                          className="btn btn-primary text-nowrap blue"
                          onClick={() => navigate('/userProfile')}
                        >
                          {userName}
                        </button>
                        // ) : (
                        //   <button
                        //     className="btn btn-primary blue"
                        //   >
                        //     Login
                        //   </button>
                      )}
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
        <div>
          <App
          // data={data}
          />
        </div>
        <NewFooter />
      </body>
    </>
  );
};



export default Payment;

