import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useLocation, Link } from "react-router-dom";
import Header from './Reuseable Components/Header'
import NewFooter from './Reuseable Components/NewFooter'
import product_banner from '../assets/products_banner.png'
import product_img from '../assets/product_img.png'
import product_img_red from '../assets/product_img_red.png'
import precision_product from '../assets/precision_product.png'
import precision_product_2 from '../assets/precision_product_2.png'
import remote_logo from '../assets/remote_logo.png'
import sense_img from '../assets/sense_img.png'
import product_video from '../assets/product_video.png'
import add_to_cart from '../assets/add_to_cart.png'
import { useGlobalContext } from "../context/context";
import Modal from 'react-modal';
import Signin from "./Modal/Signin";
const Product = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userName, setUserName] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);

    const { cart, increasei, decreased, addToCart } = useGlobalContext();

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    useEffect(() => {
        const userDataByAccounts = JSON.parse(localStorage.getItem('socialObj'));
        const userDataByEmail = JSON.parse(localStorage.getItem('UserData'));
        if (userDataByAccounts) {
            const Name = userDataByAccounts.FirstName + ' ' + userDataByAccounts.LastName;
            setUserName(Name);
        } else if (userDataByEmail) {
            setUserName(userDataByEmail.Data);
        } else {
            setUserName('');
        }
    }, []);

    useEffect(() => {
        if (location.hash) {
          const id = location.hash.replace("#", "");
          const element = document.getElementById(id)
    ;
          if (element) {
            element.scrollIntoView();
          }
        }
      }, [location]); 

      const handleSignInSuccess = (newUserName) => {
        setUserName(newUserName);
        navigate("/")
      };

    return (
        <>
            <body className="renaware" style={{ backgroundColor: "#031A40" }}>
                <Header />
                <section class="banner-section product_banner">
                    <div class="container">
                        <div class="row g-4 flex-lg-row flex-md-column-reverse flex-column-reverse">
                            <div
                                class="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div>
                                    <img src={product_banner} width="100%"
                                        alt />
                                </div>
                            </div>
                            <div
                                class="col-lg-5 offset-lg-1 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                <div class="section_content">
                                    <p class="mb-0">1,000+ individuals have used<br />
                                        Scentaware, with
                                        millions poised to join <br />as we expand to major
                                        research studies.</p>
                                    <h6 class="mt-4 mb-0">The Scentaware Smell Test Kits
                                        are available as 16-Scent Kit for detailed
                                        assessment and the 8-Scent Kit for quick checks.
                                        Easy to use and effective, these kits are
                                        essential tools for anyone concerned about
                                        maintaining and understanding their olfactory
                                        health.</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="cost_effect product_individuals">
                    <div class="container">
                        <div class="row">
                            <div
                                class="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="card_title">
                                    <h2 class="mb-3 pb-lg-1">Scentaware for
                                        individuals</h2>
                                    <p class="text-start m-0 pe-lg-5">A platform that
                                        informs about imminent olfactory health concerns
                                        and motivates to take measures proactively.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section
                    class="comprehensive product_section comprehensive_product_sec">
                    <div class="container pe-0">
                        <div
                            class="row g-lg-4 g-md-4 g-3 flex-lg-row flex-md-column-reverse flex-column-reverse">
                            <div
                                class="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                <div class="product_card" id="section1">
                                    <div class="row">
                                        <div
                                            class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <h3 class="m-0">{cart[0].main_header}</h3>
                                        </div>
                                    </div>
                                    <div
                                        class="product_price d-flex align-items-center gap-3 my-lg-4 my-md-4 my-3">
                                        {/* <h3 class="m-0">$30.00</h3> */}
                                        <h3 class="m-0">${cart[0].price}</h3>
                                        <div
                                            class="product_spinner d-flex align-items-center gap-3">
                                            <div className={`previous ${cart[0].amount2 == 1 ? '' : 'active'} `} onClick={() => decreased(cart[0].id1, cart[0].amount2)}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24" height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    className="icon">
                                                    <path
                                                        d="M9.60002 7.20001L14.4 12L9.60002 16.8"
                                                        stroke="#031A40"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <span>{
                                                cart[0].amount2
                                            }</span>
                                            <div class="next" onClick={() => increasei(cart[0].id1, cart[0].amount2)}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24" height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    className="icon">
                                                    <path
                                                        d="M9.60002 7.20001L14.4 12L9.60002 16.8"
                                                        stroke="#031A40"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p class="m-0">unit</p>
                                    </div>
                                    <div class="product_dis">
                                        <h6 class="m-0">Shelf life : 6
                                            months</h6>
                                        <p class="my-2">{cart[0].pecriptionHeading}</p>
                                        <p class="m-0">Smoke, Banana, Cloves,
                                            Eucalyptus, Lemon, Peppermint (Weak),
                                            Peppermint (Strong), Garlic, Cinnamon, Wood,
                                            Pineapple, Orange, Lavender, Coffee,
                                            Leather, Rose</p>
                                    </div>
                                    <div class="mt-lg-4 mt-md-4 mt-3 d-flex align-items-center gap-3 flex-wrap">

                                        {/* <Link to='/MainCheckOut' onClick={() => window.scrollTo(0, 0)}>
                                            <button
                                                class="btn btn-primary w-auto">Buy
                                                now</button>
                                        </Link> */}
                                        {
                                            userName ? (
                                                <button
                                                    className="btn btn-primary w-auto"
                                                    onClick={() => {
                                                        addToCart(cart[0].id1, cart[0].amount2);
                                                        navigate('/MainCheckOut');
                                                        window.scrollTo(0, 0);
                                                    }}
                                                >
                                                    Buy now
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn btn-primary w-auto"
                                                    onClick={() => {
                                                        openModal();
                                                        window.scrollTo(0, 0);
                                                    }}
                                                >
                                                    Buy now
                                                </button>
                                            )
                                        }

                                        {
                                            userName ? (<button
                                                class="btn btn-outline-primary img_button w-auto ps-3 pe-4" onClick={() =>
                                                    addToCart(cart[0].id1, cart[0].amount2)} onChange="myFunction()">
                                                <img
                                                    src={add_to_cart}
                                                    alt /> Add to
                                                cart</button>) :
                                                <button
                                                    class="btn btn-outline-primary img_button w-auto ps-3 pe-4" onClick={() => {
                                                        openModal();
                                                        window.scrollTo(0, 0);
                                                    }}
                                                    onChange="myFunction()">
                                                    <img
                                                        src={add_to_cart}
                                                        alt /> Add to
                                                    cart</button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-lg-6 col-md-12 col-sm-12 col-12 ps-0">
                                <div class="comprehensive_img">
                                    <img src={product_img}
                                        width="100%"
                                        alt />
                                </div>
                            </div>
                        </div>
                        <div
                            class="row flex-lg-row pt-lg-5 pt-md-5 pt-5  flex-md-column-reverse flex-column-reverse g-lg-4 g-md-4 g-3 mt-lg-0 mt-md-0 mt-3">
                            <div
                                class="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                <div class="product_card"  id="section2">
                                    <div class="row">
                                        <div
                                            class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <h3 class="m-0">{cart[1].main_header}</h3>
                                        </div>
                                    </div>
                                    <div
                                        class="product_price d-flex align-items-center gap-3 my-lg-4 mt-md-4 my-3">
                                        <h3 class="m-0">${cart[1].price}</h3>
                                        <div
                                            class="product_spinner d-flex align-items-center gap-3">
                                            <div className={`previous ${cart[1].amount2 == 1 ? '' : 'active'} `} onClick={() => decreased(cart[1].id1, cart[1].amount2)}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24" height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    className="icon">
                                                    <path
                                                        d="M9.60002 7.20001L14.4 12L9.60002 16.8"
                                                        stroke="#031A40"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <span>{
                                                cart[1].amount2
                                            }</span>
                                            <div class="next" onClick={() => increasei(cart[1].id1, cart[1].amount2)}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24" height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    className="icon">
                                                    <path
                                                        d="M9.60002 7.20001L14.4 12L9.60002 16.8"
                                                        stroke="#031A40"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p class="m-0">unit</p>
                                    </div>
                                    <div class="product_dis">
                                        <h6 class="m-0">Shelf life : 6
                                            months</h6>
                                        <p class="my-2">Ideal for
                                            individual
                                            use</p>
                                        <p class="m-0">Banana, Lemon, Peppermint,
                                            Garlic, Cinnamon, Orange, Coffee, Rose.</p>
                                    </div>
                                    <div class="mt-lg-4 mt-md-4 mt-3 d-flex align-items-center gap-lg-4 gap-md-3 gap-3 flex-wrap">
                                        {/* <Link to='/MainCheckOut' onClick={() => window.scrollTo(0, 0)}>
                                            <button
                                                class="btn btn-primary w-auto">Buy
                                                now</button>
                                        </Link> */}
                                        {
                                            userName ? (
                                                <button
                                                    className="btn btn-primary w-auto"
                                                    onClick={() => {
                                                        addToCart(cart[1].id1, cart[1].amount2);
                                                        navigate('/MainCheckOut');
                                                        window.scrollTo(0, 0);
                                                    }}
                                                >
                                                    Buy now
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn btn-primary w-auto"
                                                    onClick={() => {
                                                        openModal();
                                                        window.scrollTo(0, 0);
                                                    }}
                                                >
                                                    Buy now
                                                </button>
                                            )
                                        }
                                       {
                                            userName ? (<button
                                                class="btn btn-outline-primary img_button w-auto ps-3 pe-4" onClick={() =>
                                                    addToCart(cart[1].id1, cart[1].amount2)} onChange="myFunction()">
                                                <img
                                                    src={add_to_cart}
                                                    alt /> Add to
                                                cart</button>) :
                                                <button
                                                    class="btn btn-outline-primary img_button w-auto ps-3 pe-4" onClick={() => {
                                                        openModal();
                                                        window.scrollTo(0, 0);
                                                    }}
                                                    onChange="myFunction()">
                                                    <img
                                                        src={add_to_cart}
                                                        alt /> Add to
                                                    cart</button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-lg-6 col-md-12 col-sm-12 col-12 ps-0 mt-0">
                                <div class="comprehensive_img">
                                    <img src={product_img_red}
                                        width="100%"
                                        alt />
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="cost_effect product_research">
                        <div class="container">
                            <div class="row">
                                <div
                                    class="col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div class="card_title">
                                        <h2 class="mb-lg-3 mb-md-3 mb-2 pb-lg-1">Scentaware for
                                            research</h2>
                                        <p class="text-start m-0 pe-lg-5">The ScentAware
                                            Pro
                                            kits offer extended shelf life and can be
                                            customized to suit the specific needs of a
                                            clinical study.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="container pe-0">
                        <div class="row g-lg-4 g-md-4 g-3">
                            <div className='col-12 d-lg-none d-md-none d-flex'>
                                <div class="card_title">
                                    <h2 class="mb-0" style={{ fontWeight: 600 }}>Precision olfactory
                                        testing redefined</h2>
                                </div>
                            </div>
                            <div
                                class="col-lg-6 col-md-12 col-sm-12 col-12 ps-0">
                                <div class="comprehensive_img">
                                    <img src={precision_product}
                                        width="100%"
                                        alt />
                                </div>
                            </div>
                            <div
                                class="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                <div class="product_card" id="section3">
                                    <div class="row">
                                        <div
                                            class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="card_title">
                                                <h2 class="mb-4 d-block d-md-block d-none" style={{ fontWeight: 600 }}>Precision olfactory
                                                    testing redefined</h2>
                                            </div>
                                            <h3 class="m-0">Scentaware Pro 16-item Smell
                                                Test and Training kit</h3>
                                        </div>
                                    </div>
                                    <div
                                        class="product_price d-flex align-items-center gap-3 my-lg-4 my-md-4 my-3">
                                        <h3 class="m-0">${cart[2].price}</h3>
                                        <div
                                            class="product_spinner d-flex align-items-center gap-3">
                                            <div className={`previous ${cart[2].amount2 == 1 ? '' : 'active'} `} onClick={() => decreased(cart[2].id1, cart[2].amount2)} >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24" height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    className="icon">
                                                    <path
                                                        d="M9.60002 7.20001L14.4 12L9.60002 16.8"
                                                        stroke="#031A40"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <span>{
                                                cart[2].amount2
                                            }</span>
                                            <div class="next" onClick={() => increasei(cart[2].id1, cart[2].amount2)}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24" height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    class="{ cart[2].amount2 > 1 ? 'active' : '' }"
                                                    className="icon">
                                                    <path
                                                        d="M9.60002 7.20001L14.4 12L9.60002 16.8"
                                                        stroke="#031A40"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p class="m-0">unit</p>
                                    </div>
                                    <div class="product_dis">
                                        <h6 class="m-0">Shelf life : 2 years</h6>
                                        <p class="my-2">Ideal for organizations and
                                            research studies.</p>
                                        <p class="m-0">An olfactory assessment kit that
                                            can be used on multiple individuals and used
                                            in conjunction with the Intellivista data
                                            management system for seamless data
                                            management and reporting. </p>
                                    </div>
                                    <div class="mt-lg-4 mt-md-4 mt-3  d-flex align-items-center gap-3 flex-wrap">
                                        {/* <Link to='/MainCheckOut' onClick={() => window.scrollTo(0, 0)}>
                                            <button
                                                class="btn btn-primary w-auto">Buy
                                                now</button>
                                        </Link> */}
                                        {
                                            userName ? (
                                                <button
                                                    className="btn btn-primary w-auto"
                                                    onClick={() => {
                                                        addToCart(cart[2].id1, cart[2].amount2);
                                                        navigate('/MainCheckOut');
                                                        window.scrollTo(0, 0);
                                                    }}
                                                >
                                                    Buy now
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn btn-primary w-auto"
                                                    onClick={() => {
                                                        openModal();
                                                        window.scrollTo(0, 0);
                                                    }}
                                                >
                                                    Buy now
                                                </button>
                                            )
                                        }
                                        {
                                            userName ? (<button
                                                class="btn btn-outline-primary img_button w-auto ps-3 pe-4" onClick={() =>
                                                    addToCart(cart[2].id1, cart[2].amount2)} onChange="myFunction()">
                                                <img
                                                    src={add_to_cart}
                                                    alt /> Add to
                                                cart</button>) :
                                                <button
                                                    class="btn btn-outline-primary img_button w-auto ps-3 pe-4" onClick={() => {
                                                        openModal();
                                                        window.scrollTo(0, 0);
                                                    }}
                                                    onChange="myFunction()">
                                                    <img
                                                        src={add_to_cart}
                                                        alt /> Add to
                                                    cart</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row g-lg-4 g-md-4 g-3 pt-5 mt-lg-0 mt-md-0 mt-3">
                            <div className='col-12 d-lg-none d-md-none d-flex mt-0'>
                                <div class="card_title">
                                    <h2 class="mb-0" style={{ fontWeight: 600 }}>Create your own
                                        olfactory test</h2>
                                </div>
                            </div>
                            <div
                                class="col-lg-6 col-md-12 col-sm-12 col-12 ps-0">
                                <div class="comprehensive_img">
                                    <img src={precision_product_2}
                                        width="100%"
                                        alt />
                                </div>
                            </div>
                            <div
                                class="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                <div class="product_card" id="section4">
                                    <div class="row">
                                        <div
                                            class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="card_title  d-block d-md-block d-none">
                                                <h2 class="mb-4" style={{ fontWeight: 600 }}>Create your own
                                                    olfactory test</h2>
                                            </div>
                                            <h3 class="m-0">Scentaware DIY kit</h3>
                                        </div>
                                    </div>
                                    <div
                                        class="product_price d-flex align-items-center gap-3 my-lg-4 my-md-4 my-3">
                                        <h3 class="m-0">${cart[3].price}</h3>
                                        <div
                                            class="product_spinner d-flex align-items-center gap-3">
                                            <div className={`previous ${cart[3].amount2 == 1 ? '' : 'active'} `} onClick={() => decreased(cart[3].id1, cart[3].amount2)} >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24" height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    className="icon">
                                                    <path
                                                        d="M9.60002 7.20001L14.4 12L9.60002 16.8"
                                                        stroke="#031A40"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <span>{
                                                cart[3].amount2
                                            }</span>
                                            <div class="next" onClick={() => increasei(cart[3].id1, cart[3].amount2)}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24" height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    className="icon">
                                                    <path
                                                        d="M9.60002 7.20001L14.4 12L9.60002 16.8"
                                                        stroke="#031A40"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p class="m-0">unit</p>
                                    </div>
                                    <div class="product_dis">
                                        <h6 class="m-0">Shelf life : 2 years</h6>
                                        <p class="my-2">Ideal for research studies.</p>
                                        <p class="m-0">Should your research necessitate
                                            olfactory stimulants or formats not
                                            available in existing tests, the ScentAware
                                            DIY kit could be the solution. This kit
                                            includes plastic parts and labels for
                                            assembling a custom 16-item olfactory test
                                            kit. Simply prepare your own scent
                                            stimulants, fill the provided tubes, and
                                            securely cap them.</p>
                                    </div>
                                    <div class="mt-lg-4 mt-md-4 mt-3  d-flex align-items-center gap-3 flex-wrap">
                                        {/* <Link to='/MainCheckOut' onClick={() => window.scrollTo(0, 0)}>
                                            <button
                                                class="btn btn-primary w-auto">Buy
                                                now</button>
                                        </Link> */}
                                         {
                                            userName ? (
                                                <button
                                                    className="btn btn-primary w-auto"
                                                    onClick={() => {
                                                        addToCart(cart[3].id1, cart[3].amount2);
                                                        navigate('/MainCheckOut');
                                                        window.scrollTo(0, 0);
                                                    }}
                                                >
                                                    Buy now
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn btn-primary w-auto"
                                                    onClick={() => {
                                                        openModal();
                                                        window.scrollTo(0, 0);
                                                    }}
                                                >
                                                    Buy now
                                                </button>
                                            )
                                        }
                                        {
                                            userName ? (<button
                                                class="btn btn-outline-primary img_button w-auto ps-3 pe-4" onClick={() =>
                                                    addToCart(cart[3].id1, cart[3].amount2)} onChange="myFunction()">
                                                <img
                                                    src={add_to_cart}
                                                    alt /> Add to
                                                cart</button>) :
                                                <button
                                                    class="btn btn-outline-primary img_button w-auto ps-3 pe-4" onClick={() => {
                                                        openModal();
                                                        window.scrollTo(0, 0);
                                                    }}
                                                    onChange="myFunction()">
                                                    <img
                                                        src={add_to_cart}
                                                        alt /> Add to
                                                    cart</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="cost_effect remote my-2">
                    <div class="container">
                        <div class="row justify-content-lg-between">
                            <div class="col-lg-5 pe-lg-0 col-md-6 col-sm-12 col-12">
                                <div class="card_title">
                                    <h2 class="mb-lg-4 mb-md-4 mb-3 text-white m-0">How does remote
                                        olfactory assessment work using ScentAware?</h2>
                                </div>
                            </div>
                            <div
                                class="col-lg-5 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-end">
                                <div
                                    class="card_title d-flex flex-column align-items-lg-end align-items-md-end align-items-start">
                                    <div
                                        class="p-3 d-flex align-items-center gap-4 mb-4">
                                        <h6 class="text-white m-0">Learn more about<br />
                                            IntelliVista</h6>
                                        <Link to='/IntelliVista' className='product_button' onClick={() => window.scrollTo(0, 0)}>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                width="32" height="32" viewBox="0 0 32 32"
                                                fill="none"
                                                className="icon">
                                                <rect x="31.5" y="31.5" width="30.8261"
                                                    height="31" rx="7.5"
                                                    transform="rotate(180 31.5 31.5)"
                                                    stroke="white" />
                                                <path fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M21.4437 11.4314L21.4437 20.6439C21.4437 20.9175 21.6661 21.1399 21.9397 21.1399C22.2132 21.1399 22.4356 20.9175 22.4356 20.6439L22.4356 10.2349C22.4356 9.96139 22.2132 9.739 21.9397 9.739L11.5307 9.739C11.2571 9.739 11.0347 9.96139 11.0347 10.2349C11.0347 10.5085 11.2571 10.7309 11.5307 10.7309L20.7417 10.7309L10.0584 21.4142C9.86466 21.6079 9.86466 21.9217 10.0584 22.1155C10.2521 22.3092 10.5659 22.3092 10.7596 22.1155L21.4437 11.4314Z"
                                                    fill="#F1F2F6" />
                                            </svg>
                                        </Link>
                                    </div>
                                    <img src={remote_logo} alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="sese_sec sense_sec">
                    <div class="container pe-lg-0">
                        <div class="row g-lg-4 g-md-4">
                            <div
                                class="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                <div class="card_title">
                                    <h2>Test your sense of smell in 10
                                        minutes!</h2>
                                    <div class="dis mt-lg-4 mt-md-4 mt-3 pt-lg-2">
                                        <h6 class="m-0">Step 1</h6>
                                        <h4 class="my-lg-2 my-md-2 my-1">Scan any tube from the
                                            box.</h4>
                                        <p class="text-start m-0">Pick a tube
                                            from the
                                            foam tray. In the
                                            SensifyAware app, choose ScentAware
                                            test and
                                            scan the QR code on the tube, when
                                            directed.</p>
                                    </div>
                                    <div class="dis mt-3 pt-lg-1">
                                        <h6 class="m-0">Step 2</h6>
                                        <h4 class="my-lg-2 my-md-2 my-1">Open the cap, smell the
                                            tube.</h4>
                                        <p class="text-start m-0">Open the cap
                                            and
                                            gently sniff by holding the inhaler
                                            tube
                                            near your nostrils. Try to identify
                                            the
                                            scent.</p>
                                    </div>
                                    <div class="dis mt-3 pt-lg-1">
                                        <h6 class="m-0">Step 3</h6>
                                        <h4 class="my-lg-2 my-md-2 my-1">Identify the odor and
                                            respond.</h4>
                                        <p class="text-start m-0">Identify the
                                            correct
                                            answer among the options displayed
                                            on your
                                            mobile screen. Repeat for all the
                                            tubes in
                                            the box</p>
                                    </div>
                                    <div class="dis mt-3 pt-lg-1">
                                        <h6 class="m-0">Step 4</h6>
                                        <h4 class="my-lg-2 my-md-2 my-1">Check your result</h4>
                                        <p class="text-start m-0">After you’ve
                                            tested
                                            all the tubes, a result will be
                                            displayed on
                                            your device. </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-lg-6 col-md-12 col-sm-12 col-12 pe-0 d-lg-flex d-md-flex d-none">
                                <div>
                                    <img src={sense_img} width="100%"
                                        alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="product_video">
                    <div class="container">
                        <div class="row">
                            <div
                                class="col-12 d-flex align-items-center">
                                <Link onClick={() =>
                                    window.open(
                                        "https://youtu.be/Tnkg3PgGLtA?si=OOMY9ddCxjAxJkjM"
                                    )
                                }>
                                    <div class="video w-100">
                                        <img src={product_video} alt
                                            width="100%" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <NewFooter />
            </body>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                className='login_modal'
            >
                <Signin closeModal={closeModal} onSignInSuccess={handleSignInSuccess}/>
            </Modal>
        </>
    )
}

export default Product