import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = (props) => {
    const { Component, allowWithoutAuth, userGuard, role } = props;
    const navigate = useNavigate();

    useEffect(() => {
        const userDataByAccounts = JSON.parse(localStorage.getItem('socialObj'));
        const userDataByEmail = JSON.parse(localStorage.getItem('UserData'));

        if (!allowWithoutAuth && (!userDataByAccounts && !userDataByEmail)) {
            navigate('/'); 
        }
    }, [allowWithoutAuth, navigate]);

    return (
    <Component />
    )
};

export default ProtectedRoute;

