import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import logoBlack from "../../assets/logo-black.png";
import right_arrow from "../../assets/right_arrow.png";
import NewFooter from "../Reuseable Components/NewFooter";
import * as Yup from "yup";
import { useFormik } from "formik";

const initialValues = {
    Name: "",
    cardNumber: "",
    Date: "",
    Cvv: "",
};

const PaymentIntelliVista = () => {
    const navigate = useNavigate();
    const [load, setLoad] = useState(false);
    const [userName, setUserName] = useState([]);
    const location = useLocation();

    const validationSchema = Yup.object().shape({
        Name: Yup.string().required("Name is required"),
        cardNumber: Yup.string().required("Card Number is required"),
        Date: Yup.string().required("Expire Date is required"),
        Cvv: Yup.string().required("CVV is required"),

    })

    useEffect(() => {
        const userDataByAccounts = JSON.parse(localStorage.getItem('socialObj'));
        const userDataByEmail = JSON.parse(localStorage.getItem('UserData'));
        if (userDataByAccounts) {
            const Name = userDataByAccounts.FirstName + ' ' + userDataByAccounts.LastName;
            setUserName(Name);
        } else if (userDataByEmail) {
            setUserName(userDataByEmail.Data.UserName);
        } else {
            setUserName(null);
        }
    }, []);



    const handleClickbtn = (value) => {
        var name1 = location?.state?.name;
        var address1 = location?.state?.address;
        var address21 = location?.state?.address2;
        var zipcode1 = location?.state?.zipcode;
        var city1 = location?.state?.city;
        var state1 = location?.state?.state;
        var phone1 = location?.state?.phone;
        var email1 = location?.state?.email;
        var Name1 = value.Name;
        var cardNumber1 = value.cardNumber;
        var Date1 = value.Date;
        var Cvv1 = value.Cvv;

        // res=location?.state?.responses;
        // console.log("Res",res);
        // console.log("TOTAL ",total);
        // //console.log(res["Message"]);
        // var thenum = res["Message"].replace( /^\D+/g, '');
        // console.log("thenum",thenum);
        navigate("/order-confirmation-intelliVista", {
            state: {
                id: 1,
                name: name1,
                address: address1,
                address2: address21,
                zipcode: zipcode1,
                city: city1,
                state: state1,
                phone: phone1,
                email: email1,
                Name: Name1,
                cardNumber: cardNumber1,
                Date: Date1,
                Cvv: Cvv1,
            },
        });
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleClickbtn,
    });
    return (
        <body className="renaware">
            <header className="dark-header new_header checout">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* <nav className="navbar navbar-expand-lg p-0 d-flex align-items-cneter justify-content-between">
                                <Link to='/' onClick={() => window.scrollTo(0, 0)} className="navbar-brand" >
                                    <img src={logoBlack}
                                        width="140px"
                                        alt />
                                </Link>
                                <div className="card_title">

                                    <p className="m-0 blue" style={{ fontWeight: 600 }}>Ashlynn Torff</p>
                                </div>
                            </nav> */}

                            <nav
                                class="navbar navbar-expand-lg justify-content-between p-0">
                                <Link to='/' class="navbar-brand" >
                                    <img src={logoBlack}
                                        width="140px"
                                        alt />
                                </Link>

                                <ul
                                    class="navbar-nav ms-auto d-lg-block d-md-block d-none mb-0 mb-lg-0 gap-3">
                                    <li class="nav-item">
                                        <Link to='/userProfile' class="nav-link"
                                            aria-current="page"
                                        >{userName}</Link>
                                    </li>
                                </ul>
                                <div class="d-lg-none d-md-none d-block">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32" viewBox="0 0 32 32" fill="none">
                                        <path fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M16.0003 29.3337C23.3643 29.3337 29.3337 23.3643 29.3337 16.0003C29.3337 8.63633 23.3643 2.66699 16.0003 2.66699C8.63633 2.66699 2.66699 8.63633 2.66699 16.0003C2.66699 23.3643 8.63633 29.3337 16.0003 29.3337ZM20.0003 13.3337C20.0003 14.3945 19.5789 15.4119 18.8288 16.1621C18.0786 16.9122 17.0612 17.3337 16.0003 17.3337C14.9395 17.3337 13.922 16.9122 13.1719 16.1621C12.4218 15.4119 12.0003 14.3945 12.0003 13.3337C12.0003 12.2728 12.4218 11.2554 13.1719 10.5052C13.922 9.75509 14.9395 9.33366 16.0003 9.33366C17.0612 9.33366 18.0786 9.75509 18.8288 10.5052C19.5789 11.2554 20.0003 12.2728 20.0003 13.3337ZM8.00033 22.667C8.931 21.4242 10.1387 20.4155 11.5274 19.7212C12.9161 19.0268 14.4477 18.6659 16.0003 18.667C17.553 18.6659 19.0845 19.0268 20.4732 19.7212C21.862 20.4155 23.0696 21.4242 24.0003 22.667C23.0696 23.9098 21.862 24.9184 20.4732 25.6128C19.0845 26.3072 17.553 26.6681 16.0003 26.667C14.4477 26.6681 12.9161 26.3072 11.5274 25.6128C10.1387 24.9184 8.931 23.9098 8.00033 22.667Z"
                                            fill="#031A40" />
                                    </svg>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>

            <section className="login_page inner-banner-section sign_up_outer d-flex align-items-start h-auto " style={{ minHeight: 'auto' }}>
                <div class="container pb-lg-3">
                    <div class="row">
                        <div
                            class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                            <ul class="card_breadcrumb">
                                <li onClick={() => navigate('/billing')}>
                                    <a>
                                        Shipping
                                    </a>
                                </li>
                                <li class="active" onClick={() => navigate('/payment-intelliVista')}>
                                    <a>
                                        Checkout
                                    </a>
                                </li>
                                <button
                                    class="btn btn-outline-primary w-auto ms-4 d-lg-inline-block d-md-inline-block d-none" onClick={() => navigate('/IntelliVista')}>Cancel
                                    order</button>
                                <button
                                    class="btn btn-primary w-auto ms-4 d-lg-inline-block d-md-inline-block d-none" onClick={(e) => {
                                        e.preventDefault();
                                        formik.handleSubmit();
                                    }}>Proceed
                                    to
                                    pay</button>
                            </ul>
                        </div>
                    </div>
                    <div class="row my-4 py-lg-2">
                        <div class="col-12">
                            <div class="card_title">
                                <h6 class="m-0"><b
                                    class="d-flex align-items-center gap-4">
                                    <img src={right_arrow}
                                        class="d-lg-block d-md-block d-none"
                                        onClick={() => navigate('/billing')}
                                        alt='' />
                                    Payment details
                                </b></h6>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-12 col-sm-12 col-12 pe-lg-5">
                            <div class="form_feild card_title">
                                {/* <form action>
                                    <div class="row">
                                        <h6 class="mb-lg-4 mb-3">Credit/Debit
                                            card</h6>
                                        <div
                                            class="col-12 mb-lg-4 mb-3">
                                            <label for class="w-100">Name on card
                                                :</label>
                                            <input type="mail"
                                                class="form-control"
                                                placeholder="Label" />
                                        </div>
                                        <div
                                            class="col-12 mb-lg-4 mb-3">
                                            <label for class="w-100">Card number
                                                :</label>
                                            <input type="mail"
                                                class="form-control"
                                                placeholder="Label" />
                                        </div>
                                        <div
                                            class="col-lg-6 col-md-6 col-sm-6 col-6 mb-lg-4 mb-3 pe-lg-3">
                                            <label for class="w-100">Expires on
                                                :</label>
                                            <input type="mail"
                                                class="form-control"
                                                placeholder="Label" />
                                        </div>
                                        <div
                                            class="col-lg-6 col-md-6 col-sm-6 col-6 mb-lg-4 mb-3 ps-lg-3">
                                            <label for class="w-100">CVV / CVC
                                                :</label>
                                            <input type="mail"
                                                class="form-control"
                                                placeholder="----" />
                                        </div>
                                    </div>
                                </form> */}
                                <form action>
                                    <div class="row">
                                        <h6 class="mb-lg-4 mb-3">Credit/Debit
                                            card</h6>
                                        <div
                                            class="col-12 mb-lg-4 mb-3">
                                            <label for class="w-100">Name on card
                                                :</label>
                                            <input type="text"
                                                className={`form-control ${formik.touched.Name && formik.errors.Name
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                name="Name"
                                                placeholder="Name on Card"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.Name}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.Name}
                                                invalidFeedback={formik.errors.Name} />
                                            <div className="invalid-feedback">
                                                {formik.errors.Name}
                                            </div>
                                        </div>
                                        <div
                                            class="col-12 mb-lg-4 mb-3">
                                            <label for class="w-100">Card number
                                                :</label>
                                            <input type="number"
                                                className={`form-control ${formik.touched.cardNumber && formik.errors.cardNumber
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                name="cardNumber"
                                                placeholder="Card Number"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.cardNumber}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.cardNumber}
                                                invalidFeedback={formik.errors.cardNumber} />
                                            <div className="invalid-feedback">
                                                {formik.errors.cardNumber}
                                            </div>
                                        </div>
                                        <div
                                            class="col-lg-6 col-md-6 col-sm-6 col-6 mb-lg-4 mb-3 pe-lg-3">
                                            <label for class="w-100">Expires on
                                                :</label>
                                            <input type="date"
                                                className={`form-control ${formik.touched.Date && formik.errors.Date
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                name="Date"
                                                placeholder="MM/YY"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.Date}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.Date}
                                                invalidFeedback={formik.errors.Date} />
                                            <div className="invalid-feedback">
                                                {formik.errors.Date}
                                            </div>
                                        </div>
                                        <div
                                            class="col-lg-6 col-md-6 col-sm-6 col-6 mb-lg-4 mb-3 ps-lg-3">
                                            <label for class="w-100">CVV / CVC
                                                :</label>
                                            <input type="number"
                                                placeholder="----"
                                                className={`form-control ${formik.touched.Cvv && formik.errors.Cvv
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                name="Cvv"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.Cvv}
                                                isValid={formik.isValid}
                                                isTouched={formik.touched.Cvv}
                                                invalidFeedback={formik.errors.Cvv} />
                                            <div className="invalid-feedback">
                                                {formik.errors.Cvv}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div
                            class="col-lg-3 col-md-6 col-sm-12 col-12 mt-lg-0 mt-3 ms-lg-5">
                            <div class="summary_card d-lg-block d-md-block d-none">
                                <div class="card_title">
                                    <h6 class="mb-lg-4 mb-3"><b>Order
                                        summary</b></h6>
                                    <p class="mb-3 text-start">IntelliVista - Basic
                                        plan</p>
                                    <ul class="mb-3">
                                        <li>1 administrator account login.</li>
                                        <li>10,000 participants.</li>
                                        <li>Unlimited trials.</li>
                                    </ul>
                                    <div class="row">
                                        <div class="col-lg-10 col-12">
                                            <p
                                                class="d-flex align-items-center justify-content-between mb-0">Price
                                                : <span>$4,995.00/year</span></p>
                                        </div>
                                    </div>
                                    <hr class="my-lg-3" />
                                    <div class="row">
                                        <div class="col-lg-10 col-12">
                                            <p
                                                class="d-flex align-items-center justify-content-between mb-1 w-100 gap-3">Total
                                                :
                                                <span><b>$4,995.00/year</b></span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="summary_card d-lg-none d-md-none d-block">
                                <div class="card_title">
                                    <h6 class="mb-lg-4 mb-3"><b>Order
                                        summary</b></h6>
                                    <p class="mb-3 text-start">IntelliVista - Basic
                                        plan</p>
                                    <ul class="mb-3">
                                        <li>1 administrator account login.</li>
                                        <li>10,000 participants.</li>
                                        <li>Unlimited trials.</li>
                                    </ul>
                                    <div class="row">
                                        <div class="col-lg-10 col-12">
                                            <p
                                                class="d-flex align-items-center justify-content-start mb-0 gap-3"><span
                                                    class="order_title">Price
                                                    :</span>
                                                <span>$4,995.00/year</span></p>
                                        </div>
                                    </div>
                                    <hr class="my-lg-3" style={{ borderColor: '#031A40', opacity: 1 }} />
                                    <div class="row">
                                        <div class="col-lg-10 col-12">
                                            <p
                                                class="d-flex align-items-center justify-content-start mb-1 w-100 gap-3"><span
                                                    class="order_title"><b>Total
                                                        :</b></span>
                                                <span><b>$4,995.00/year</b></span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="row pt-lg-3 pt-md-3 pt-2 d-lg-none d-md-none d-block">
                        <div class="col-12 mt-4">
                            <button
                                class="btn btn-primary w-100 ms-0">Proceed to pay $4,995.00</button>
                            <button
                                class="btn btn-outline-primary w-100 ms-0 mt-3">Cancel
                                order</button>
                        </div>
                    </div>
                </div>
            </section>
            <NewFooter />
        </body>
    )
}
export default PaymentIntelliVista