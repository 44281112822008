/** @format */

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Icon1 from "../../src/assets/Icon1.png";
import instagram from "../../src/assets/instagram.png";
import unlock from "../../src/assets/unlock.png";
// import  tw  from "../../src/assets/tw.png";
import { TwitterOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";

const Footer = (props) => {
  return (
    <Wrapper>
      <footer>
        <div className="p-footer">
          <Row className="footer-container" gutter={12}>
            <Col lg={12} md={12} sm={24} xs={24}>
              <div className="contact">
                <h4>Contact us</h4>
                <p>
                  251 EAST 32ND STREET 7C <br></br> New York, 10016
                </p>
                <p className="altoida"> info@sensifylife.com</p>
                <h4>Follow us</h4>
                <div className="icons">
                  <img
                    src={Icon1}
                    alt=""
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/people/Sensify-Inc/100089377607943/"
                      )
                    }
                  />
                  <img src={instagram} alt="" />
                  <img src={unlock} alt="" />
                  <TwitterOutlined />
                </div>
              </div>
            </Col>

            <Col lg={12} md={12} sm={24} xs={24}>
              <div className="policy">
                <h4>Policy</h4>
                <p>
                  By visiting our Website and/or using the<br></br> Services in
                  any manner, you acknowledge<br></br> that you accept the
                  practices and policies<br></br>
                  outlined in{" "}
                  <Link
                    to="/privacypolicy"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    Our Privacy Policy.
                  </Link>
                </p>
                <h4>Our Disclaimer</h4>
                <p className="gmail">
                  @SensifyAware {new Date().getFullYear()}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </footer>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .container-fluid {
    width: 63%;
  }
  .order p {
    text-align: center;
  }
  .row.order h1 {
    font-weight: 700;
    font-size: 56px;
    color: rgba(255, 255, 255, 0.5);
    font-family: "WorkSans";
  }
  .phone li {
    font-weight: 500;
    font-size: 16px;
    font-family: "WorkSans";
    margin-bottom: 0px !important;
    color: #8e8e8e;
  }
  .equal {
    padding-top: 28px !important;
    font-weight: 500;
    font-size: 16px;
    font-family: "WorkSans";
  }
  .list li a {
    text-decoration: none;
    color: white;
    opacity: 0.65;
    display: block;
  }
  .copy {
    font-size: 15px;
    font-weight: 400;
  }
  .list li {
    padding: 27px 0px 0px 0px;
    pointer-events: none;
  }

  .equal {
    padding-top: 40px;
  }

  hr {
    color: white;
  }
  .phone {
    margin-top: 55px;
  }

  .footer {
    padding: 100px 0px;
    background-color: #1e1e1e;
  }
  .btn-success {
    background-color: #22669c;
    border-color: #22669c;
    pointer-events: none;
  }
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
  .form-control {
    padding: 15px 20px;
    color: #0a142f;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
  }
  .social2 {
    padding: 0px 5px;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    .footer .container-fluid {
      width: 85%;
    }
    .form-control {
      background-color: #1e1e1e;
      padding: 0.975rem 0.75rem;
      border-color: #22669c;
    }
    .btn-success {
      padding: 0px 25px;
      background: #22669c;
    }
    .social {
      padding-bottom: 60px;
      display: flex;
    }
    .social1 {
      width: 50%;
      text-align: center;
    }

    .social3 {
      width: 50%;
      text-align: center;
    }
    .phone {
      margin-top: 15px;
    }
    .list {
      flex-direction: row;
    }

    .copy {
      padding-bottom: 50px;
    }

    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
    .mobile .list {
      display: flex;
      width: 100%;
      list-style: none;
    }
    .mobile .list li {
      padding: 0px 50px 0 0;
    }
    .align {
      padding-top: 40px;
    }

    .footer {
      padding: 30px 0px;
    }

    .row.order h1 {
      font-weight: 700;
      font-size: 37px;
      color: rgba(255, 255, 255, 0.5);
      font-family: "WorkSans";
    }

    .phone li {
      display: flex;
      justify-content: flex-start;
    }
    .mobile .list li {
      margin-top: -18px;
    }
    .copy {
      padding-bottom: 50px;
      color: #fff;
    }
  }
`;

export default Footer;
