import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import logoBlack from "../../assets/logo-black.png";
import NewFooter from "../Reuseable Components/NewFooter";
const OrderConfirmationIntelliVista = () => {
    const navigate = useNavigate();
    const [load, setLoad] = useState(false);
    const [userName, setUserName] = useState([]);

    useEffect(() => {
        const userDataByAccounts = JSON.parse(localStorage.getItem('socialObj'));
        const userDataByEmail = JSON.parse(localStorage.getItem('UserData'));
        if (userDataByAccounts) {
            const Name = userDataByAccounts.FirstName + ' ' + userDataByAccounts.LastName;
            setUserName(Name);
        } else if (userDataByEmail) {
            setUserName(userDataByEmail.Data.UserName);
        } else {
            setUserName(null);
        }
    }, []);

    const handleClickToHome = () => {
        localStorage.removeItem('BillingIntelliVista');
        navigate('/')
    }
    return (
        <body className="renaware">
            <header className="dark-header new_header checout">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* <nav className="navbar navbar-expand-lg p-0 d-flex align-items-cneter justify-content-between">
                                <Link to='/' onClick={() => window.scrollTo(0, 0)} className="navbar-brand" >
                                    <img src={logoBlack}
                                        width="140px"
                                        alt />
                                </Link>
                                <div className="card_title">

                                    <p className="m-0 blue" style={{ fontWeight: 600 }}>Ashlynn Torff</p>
                                </div>
                            </nav> */}

                            <nav
                                class="navbar navbar-expand-lg justify-content-between p-0">
                                <Link to='/' class="navbar-brand" >
                                    <img src={logoBlack}
                                        width="140px"
                                        alt />
                                </Link>

                                <ul
                                    class="navbar-nav ms-auto d-lg-block d-md-block d-none mb-0 mb-lg-0 gap-3">
                                    <li class="nav-item">
                                        <Link to='/userProfile' class="nav-link"
                                            aria-current="page"
                                        >{userName}</Link>
                                    </li>
                                </ul>
                                <div class="d-lg-none d-md-none d-block">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32" viewBox="0 0 32 32" fill="none">
                                        <path fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M16.0003 29.3337C23.3643 29.3337 29.3337 23.3643 29.3337 16.0003C29.3337 8.63633 23.3643 2.66699 16.0003 2.66699C8.63633 2.66699 2.66699 8.63633 2.66699 16.0003C2.66699 23.3643 8.63633 29.3337 16.0003 29.3337ZM20.0003 13.3337C20.0003 14.3945 19.5789 15.4119 18.8288 16.1621C18.0786 16.9122 17.0612 17.3337 16.0003 17.3337C14.9395 17.3337 13.922 16.9122 13.1719 16.1621C12.4218 15.4119 12.0003 14.3945 12.0003 13.3337C12.0003 12.2728 12.4218 11.2554 13.1719 10.5052C13.922 9.75509 14.9395 9.33366 16.0003 9.33366C17.0612 9.33366 18.0786 9.75509 18.8288 10.5052C19.5789 11.2554 20.0003 12.2728 20.0003 13.3337ZM8.00033 22.667C8.931 21.4242 10.1387 20.4155 11.5274 19.7212C12.9161 19.0268 14.4477 18.6659 16.0003 18.667C17.553 18.6659 19.0845 19.0268 20.4732 19.7212C21.862 20.4155 23.0696 21.4242 24.0003 22.667C23.0696 23.9098 21.862 24.9184 20.4732 25.6128C19.0845 26.3072 17.553 26.6681 16.0003 26.667C14.4477 26.6681 12.9161 26.3072 11.5274 25.6128C10.1387 24.9184 8.931 23.9098 8.00033 22.667Z"
                                            fill="#031A40" />
                                    </svg>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>

            <section className="login_page inner-banner-section sign_up_outer d-flex align-items-start h-auto " style={{ minHeight: 'auto' }}>
                <div class="container pb-lg-4">
                    <div class="row">
                        <div
                            class="col-lg-7 pe-lg-5 col-md-12 col-sm-12 col-12">
                            <div class="card_title order_confirmation">
                                <h2 style={{ fontWeight: '300', color: "#4E4E4E" }}
                                    class="mb-4 pb-lg-2">Subscription
                                    confirmed!</h2>
                                <h6 class="mb-lg-2 mb-md-2 mb-4">Thank you for choosing
                                    IntelliVista! Your annual
                                    subscription has been successfully
                                    activated.</h6>
                                <p class="text-start mb-0">Welcome to IntelliVista!
                                    A confirmation email has been sent to your
                                    registered email address,
                                    <b>ashlynn.torff@hotmail.com</b>. This email
                                    includes your default password, which you can
                                    use for your first login.</p>
                                <div class="mt-4 pt-lg-2 d-lg-block d-md-block d-none">
                                    <button class="btn btn-primary w-auto">Access
                                        the Dashboard</button>
                                    <button
                                        class="btn btn-outline-primary w-auto ms-4" onClick={() => navigate('/')}>Go
                                        to homepage</button>
                                </div>
                                <h6 class="mb-lg-3 mb-md-3 mb-4 mt-4 pt-lg-2"
                                    style={{ color: '#000' }}>Subscription details
                                    -</h6>
                                <div class="row">
                                    <div
                                        class="col-lg-4 col-md-12 col-sm-12 col-12 border-end confirmation pe-lg-3">
                                        <p
                                            class="mb-2 text-start"><b>IntelliVista
                                                -
                                                Basic plan</b></p>
                                        <ul>
                                            <li class="text-nowrap">1 administrator
                                                account
                                                login.</li>
                                            <li>10,000 participants.</li>
                                            <li>Unlimited trials.</li>
                                        </ul>
                                    </div>
                                    <div
                                        class="col-lg-7 col-md-12 col-sm-12 col-12 ps-lg-3 subscription_detail mt-lg-0 mt-md-0 mt-4">
                                        <p
                                            class="d-flex flex-lg-row flex-md-row flex-column align-items-lg-center text-start gap-lg-4 gap-md-4 gap-0 mb-lg-2 mb-md-2 mb-3"><span>Subscription
                                                start date
                                                :</span>January 15 2024</p>
                                        <p
                                            class="d-flex flex-lg-row flex-md-row flex-column align-items-lg-center text-start gap-lg-4 gap-md-4 gap-0 mb-lg-2 mb-md-2 mb-3"><span>Subscription
                                                end date :</span>January 15 2025</p>
                                        <p
                                            class="d-flex flex-lg-row flex-md-row flex-column align-items-lg-center text-start gap-lg-4 gap-md-4 gap-0 mb-lg-2 mb-md-2 mb-3"><span>Transaction
                                                ID :</span>5633AGLPO124I</p>
                                        <p
                                            class="d-flex flex-lg-row flex-md-row flex-column align-items-lg-center text-start gap-lg-4 gap-md-4 gap-0 mb-lg-2 mb-md-2 mb-3"><span>Payment
                                                method :</span>Credit card ending
                                            with 0379</p>
                                        <p
                                            class="d-flex flex-lg-row flex-md-row flex-column align-items-lg-center text-start gap-lg-4 gap-md-4 gap-0 mb-lg-2 mb-md-2 mb-0"><span>Amount
                                                paid : </span>$4,995.00</p>
                                        <p
                                            class="d-flex align-items-center text-start bill_time m-0">(Billed
                                            annually)</p>
                                    </div>
                                    <div class="col-12 d-lg-block d-md-block d-none">
                                        <p class="text-start m-0 mt-4 pt-lg-2">Your
                                            annual subscription will
                                            automatically renew on January 15 2025,
                                            ensuring uninterrupted access to all
                                            features and services. You'll receive a
                                            reminder email before the renewal date.
                                            For any questions or concerns regarding
                                            your payment, please reach out to our
                                            customer support team at
                                            support@sensifylife.com, and we'll be
                                            happy to assist you.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="row pt-lg-3 pt-md-3 pt-0 d-lg-none d-md-none d-block">
                        <div class="col-12 mt-4">
                            <button
                                class="btn btn-primary w-100 ms-0">Access the Dashboard</button>
                            <button
                                class="btn btn-outline-primary w-100 ms-0 mt-3" onClick={handleClickToHome}>Go to homepage</button>
                        </div>
                    </div>
                </div>
            </section>
            <NewFooter />
        </body>
    )
}
export default OrderConfirmationIntelliVista