import React, { useEffect, useState } from 'react'
import modal_confirm from '../../assets/modal_confirm.png'
const SuccessDialog = () => {
    const [userName, setUserName] = useState([]);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('SignupData'));
        const Name = userData.FirstName + ' ' + userData.LastName;
        setUserName(Name);
    });
    return (

        <section class="login_page d-flex align-items-center justify-content-center position-relative">
            <div className='confirmation_modal p-lg-5 p-md-4 p-3'>
                <div className='card_title text-center'>
                    <h2 className='text-center m-0'>Welcome aboard!</h2>
                    <img src={modal_confirm} alt="" className='my-4' />
                    <h6 className='m-0 text-center'>Account created for <b>{userName}</b> successfully!</h6>
                </div>
            </div>
        </section>
    )
}

export default SuccessDialog