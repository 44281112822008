import React, { useEffect } from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import Axios from "axios";
import config from "../../config.js";
import Cardsection from "./Cardsection";
import Swal from 'sweetalert2'
import { useGlobalContext } from "../../context/context";
import { test } from "./App.jsx";
import '../spinner.css';
import { useState } from "react";
import LoadingSpinner from "../LoadingSpinner";
import { Navigate, useNavigate } from "react-router-dom";
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
// import right_arrow from "../assets/right_arrow.png";
import right_arrow from "../../assets/right_arrow.png";
const CheckoutForm = (props) => {

  const navigate = useNavigate()

  const finalTotal = localStorage.getItem("total");


  const [load, setLoad] = useState(false);
  const [lastUserOrderData, setLastUserOrderData] = useState([]);

  const [cartData, setCartData] = useState([]);
  const [totalAmmount, setTotalAmmount] = useState(0);
  const [userName, setUserName] = useState([]);
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const TotalAmount = localStorage.getItem("TotalAmount1");
    const finalAmmount = localStorage.getItem("total");
    setTotalAmmount(finalAmmount);
    const tempCart = localStorage.getItem("tempCart");
    const PersistedCart = JSON.parse(tempCart);
    if (TotalAmount > 0) {
      const itemsToStore = PersistedCart.filter(
        (item) =>
          (item.id === 2 || item.id === 1 || item.id === 3 || item.id === 4 || item.id === 5) && item.amount > 0
      );
      setCartData(itemsToStore);
    }
  }, []);


  useEffect(() => {
    let userId = 0
    const userDataByAccounts = localStorage.getItem('social_login_ID');
    const userByGoogleLogin = JSON.parse(localStorage.getItem('socialObj'));
    const userDataByEmail = JSON.parse(localStorage.getItem('UserData'));
    const tempCart = localStorage.getItem("tempCart");
    const PersistedCart = JSON.parse(tempCart);
    if (userDataByAccounts) {
      userId = userDataByAccounts
      const Name = userByGoogleLogin.FirstName + ' ' + userByGoogleLogin.LastName;
      setUserName(Name);
      setUserEmail(userByGoogleLogin.EmailAddress);
    }
    if (userDataByEmail) {
      userId = userDataByEmail.Data.UserId;
      setUserName(userDataByEmail.Data.UserName);
      setUserEmail(userDataByEmail.Data.EmailAddress);
    }
    getLastUserOrderData(userId)
    getActiveCardDetails(userId)
  }, []);

  const getOrderDetails = async (e, cartData, totalAmmount, userName, lastUserOrderData) => {
    e.preventDefault();
    try {
      const obj = {
        cartData: cartData,
        totalAmmount: totalAmmount,
        userName: userName,
        lastUserOrderData: lastUserOrderData,
        email : userEmail
      };

      Axios.post(
        "https://gokepiqof3.execute-api.us-east-1.amazonaws.com/prod/api/admin/order-details",
        // "http://localhost:4000/api/admin/order-details",
        obj
      );

    } catch (error) {
      // console.error('Error updating profile:', error);
    }
  };

  const getLastUserOrderData = async (userId) => {
    try {
      const obj = {
        Url: "/get-last-order",
        UserId: parseInt(userId),
      };
      let api = `${config.BASE_URL}`
      const response = await Axios.post(
        api, obj
      );
      setLastUserOrderData(response.data?.OrderDetailModelList[0])
    } catch (error) {
      // console.error('Error updating profile:', error);
    }
  };


  const getActiveCardDetails = async (userId) => {
    try {
      const obj = {
        Url: "/get-active-card-details",
        UserId: parseInt(userId),
      };
      let api = `${config.BASE_URL}`
      const response = await Axios.post(
        api, obj
      );
    } catch (error) {
      // console.error('Error updating profile:', error);
    }
  };


  const handleSubmit = async (event) => {
    console.log('finalTotal',finalTotal);
    event.preventDefault();
    let value = "";
    setLoad(true);
    const { stripe, elements } = props;
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      setLoad(false);
      Swal.fire({
        icon: 'warning',
        title: result.error.message,
        text: "Unable to process your payment!",
        showCancelButton: false,
        confirmButtonText: 'Try Again',

      })
    } else {
      console.log("Result Token: ", result.token);
      value = result.token.id;
      const obj = {
        "Url": "/do-rapid-pay",
        "CartRequest": {
          "TotelAmount": 50,
          "OrderId": 11,
          "TokenId": "tok_1LQpCXLocYDVe0iYkbQE7woQ"
        }
      }
      obj["CartRequest"]["OrderId"] = lastUserOrderData.Id;
      // obj["CartRequest"]["TotelAmount"] = parseFloat(finalTotal) * 100;
      // obj["CartRequest"]["TotelAmount"] = (parseFloat(1)) * 100;
      obj["CartRequest"]["TotelAmount"] = (parseFloat(finalTotal) + 10) * 100;
      obj["CartRequest"]["TokenId"] = value;


      let api = `${config.BASE_URL}`
      console.log("Value: ", obj);
      Axios.post(api, obj).then(async res => {
        setLoad(false);
        if (res.data.IsSuccess === true) {
          Swal.fire({
            icon: 'success',
            title: 'Payment Added!',
            text: "Order Placed Successfully! Press Continue to Track your Order",
            showConfirmButton: false,
            timer: 2500,
          });
          // localStorage.setItem("grandTotal", 0);
          // localStorage.removeItem("tempCart");
          // localStorage.setItem("total", 0);
          // localStorage.removeItem("TotalAmount1");
          await getOrderDetails(event, cartData, totalAmmount, userName, lastUserOrderData);
          navigate('/Thankyou', { state: { name: lastUserOrderData.UserName, address: lastUserOrderData.AddressLine, orderId: lastUserOrderData.Id } });
        }
        else {
          Swal.fire({
            icon: 'warning',
            title: 'Something went wrong',
            text: "Unable to process your payment!",
            showCancelButton: false,
            confirmButtonText: 'Try Again',

          })
        }

      })
    }
  };




  return (
    <section className="login_page checkout_page inner-banner-section sign_up_outer d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
            <ul class="card_breadcrumb">
              <li onClick={() => navigate('/mainCheckOut')}>
                <a >
                  Cart
                </a>
              </li>
              <li onClick={() => navigate('/Shipping')}>
                <a >
                  Shipping
                </a>
              </li>
              <li class="active" onClick={() => navigate('/Payment')}>
                <a>
                  Checkout
                </a>
              </li>
              <button
                class="btn btn-outline-primary w-auto ms-4 d-lg-inline-block d-md-inline-block d-none" onClick={() => navigate("/product")}>Cancel
                order</button>
              <button style={{ width: '100%', maxWidth: '229px', minWidth: '229px' }}
                class="btn btn-primary w-auto ms-4 d-lg-inline-block d-md-inline-block d-none" onClick={(e) => {
                  // e.preventDefault();
                  handleSubmit(e)
                  // formik.handleSubmit();
                }}>
                {load ? (
                  <div className='w-100 align-items-center d-flex justify-content-center'>
                    <h1
                      class="loader"
                      disabled={load}
                      style={{
                        // color: "#101A36",
                        margin: "-5px",
                        width: '30px',
                        height: '30px',
                      }}
                    ></h1>
                  </div>
                ) : (
                  `Proceed to pay $${parseInt(totalAmmount) + 10}`
                )}</button>
            </ul>
          </div>
        </div>
        <div class="row my-4 py-lg-2">
          <div class="col-12">
            <div class="card_title">
              <h6 class="m-0"><b
                class="d-flex align-items-center gap-4">
                <img src={right_arrow} class="d-lg-block d-md-block d-none" onClick={() => navigate('/Shipping')} alt="" />
                Payment details
              </b></h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12 col-12">
            <div class="form_feild card_title">
              <form >
                <div class="row">
                  <h6 class="mb-lg-4 mb-3">Credit/Debit
                    card</h6>
                  <div
                    class="col-12 mb-lg-4 mb-3">
                    <label for class="w-100">Name on card
                      :</label>
                    <input type="text"
                      className="form-control"
                      name="Name"
                      placeholder="Name on Card"
                    />

                  </div>
                  <div
                    class="col-12 mb-lg-4 mb-3">
                    <label for class="w-100">Card number
                      :</label>
                    <CardNumberElement />

                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-sm-6 col-6 mb-lg-4 mb-3 pe-lg-3">
                    <label for class="w-100">Expires on
                      :</label>
                    <CardExpiryElement />

                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-sm-6 col-6 mb-lg-4 md-3 ps-lg-3">
                    <label for class="w-100">CVV / CVC
                      :</label>
                    <CardCvcElement />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-6 col-sm-12 col-12 ps-lg-4 mt-lg-0 mt-md-0 mt-3">
            <div class="summary_card d-lg-block d-md-block d-block">
              <div class="card_title">
                <h6 class="mb-lg-4 mb-4"><b>Order
                  summary</b></h6>
                <>
                  {cartData.map((item, index) => (
                    <div key={index}>
                      <p className="mb-2 text-start">{item.main_header}</p>
                      <div className="row">
                        <div className="col-lg-6">
                          <p className="d-flex align-items-center justify-content-between mb-2">Quantity: <span>{item.amount}</span></p>
                          <p className="d-flex align-items-center justify-content-between mb-0">Price: <span>${item.price.toFixed(2) * item.amount.toFixed(2)}</span></p>
                        </div>
                      </div>
                      {index !== cartData.length - 1 && <hr className="my-lg-4" />}
                    </div>
                  ))}
                </>
                <hr class="my-lg-3 my-md-3 my-3 " style={{backgroundColor:'#03529C', opacity:'1',border:'none'}} />
                  {/* <div class="row">
                    <div class="col-lg-6 col-12">
                      <p
                        class="d-flex align-items-center justify-content-between mb-1">Shipping
                        : <span>$10.00</span></p>
                    </div>
                  </div>
                  <p class="mb-0 text-start">4-7 business days</p>
                  <hr class="my-lg-3" /> */}
                <div class="row">
                  <div class="col-lg-6 col-12">
                    <p
                      class="d-flex align-items-center justify-content-between mb-1">Total
                      : <span><b>${parseInt(totalAmmount) + 10}</b></span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row pt-lg-3 pt-md-3 pt-2 d-lg-none d-md-none d-block">
          <div class="col-12 mt-4">
            <button
              class="btn btn-primary w-100 ms-0" onClick={(e) => {
                // e.preventDefault();
                handleSubmit(e)
                // formik.handleSubmit();
              }}>
                {load ? (
                  <div className='w-100 align-items-center d-flex justify-content-center'>
                    <h1
                      class="loader"
                      disabled={load}
                      style={{
                        // color: "#101A36",
                        margin: "-5px",
                        width: '30px',
                        height: '30px',
                      }}
                    ></h1>
                  </div>
                ) : (
                  `Proceed to pay $${parseInt(totalAmmount) + 10}`
                )}</button>
            <button
              class="btn btn-outline-primary w-100 ms-0 mt-3" onClick={() => navigate("/product")}>Cancel
              order</button>
          </div>
        </div>
      </div>
    </section>
  );
}


export default function InjectedCheckoutForm(props) {
  return (

    <ElementsConsumer>
      {({ stripe, elements }) => (

        <CheckoutForm stripe={stripe} elements={elements} />
      )}

    </ElementsConsumer>
  );
}