import React from "react";
import styled from "styled-components";
import new_hero_img from "../assets/new_hero_img.png";
import Hero_mobile_img from "../assets/Hero_mobile_img.png";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <section class="home-section" id="home">
        <div class="background-img">
          {/* <Navbar /> */}
          <div class="home-page-text">
            <div class="container-fluid p-0">
              <div class="col">
                <p class="home-heading">
                  Look inside your <br /> brain with <br />{" "}
                  <span>SensifyAware.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .container-fluid {
    width: 63%;
  }
  .background-img {
    background-image: url(${isMobile ? Hero_mobile_img : new_hero_img});
    background-size: cover;
    background-position: top;
    height: 100vh;
  }
  .home-button {
    background-color: #22669c;
    box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3);
    border-radius: 8px;
    border: none;
    padding: 15px 20px;
    color: white;
    margin-top: 130px;
  }
  .home-heading {
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0em;
    padding-top: 300px;
    color: rgba(255, 255, 255, 0.5);
    font-family: "WorkSans";
  }
  p.home-heading span {
    color: #fff;
  }
  .home-text {
    font-size: var(--font-size-16px);
    font-weight: var(--font-weight-300);
    line-height: 19px;
    letter-spacing: 0em;
    color: var(--font-color-for-header);
    padding-bottom: 15px;
    color: white;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    p.home-heading {
      font-size: 36px !important;
      line-height: 55px !important;
      padding-top: 100px;
      width : 100%
    }
    .container-fluid {
      height: 97vw;
      width: 84%;
    }
    .background-img {
      height: auto;
    }
    p.home-heading br {
      display: block !important;
  }
  }
`;

export default Hero;
