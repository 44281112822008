import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import resetPasswordLeft from "../../assets/resetPasswordLeft.svg";
import outline from "../../assets/outline.svg";
import { useState, useCallback } from "react";
import Swal from "sweetalert2";
import Axios from "axios";
import { isMobile } from "react-device-detect";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import config from "../../config";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Nav from "../Navbar";
import * as Yup from "yup";
import { useFormik } from "formik";
import { SpaRounded } from "@mui/icons-material";

const initialValues = {
  Email: "",
  Password: "",
};

function Signin({ closeModal,onSignInSuccess }) {

  var obj = {};
  const [passworderror, setPassworderror] = useState("");
  const [load, setLoad] = useState(false);
  const [showLogoutBtn, setShowLogoutBtn] = useState(false);
  const [socialResp, setSocialResp] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const validationSchema = Yup.object({
    Email: Yup.string().email("Invalid email address").required("Email is required!"),
    Password: Yup.string()
      .required("Password is required!")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,16}$/,
        {
          message: "Password must be 8-16 characters and contain at least one number, one uppercase letter, one lowercase letter, and one special character, and cannot contain spaces.",
          excludeEmptyString: true,
        }
      )
      .matches(
        /^\S*$/,
        "Password cannot contain spaces."
      ),
  });

  function handleSocialLoginRequest(response, btn) {
    console.log("responsebtn", response, btn);
    var tempBtn = btn;
    var api = `${config.BASE_URL}`;

    const googleLoginObj = {
      EmailAddress: response?.profileObj?.email,
      SocialMedia: "Google",
      FirstName: response?.profileObj?.givenName,
      LastName: response?.profileObj?.familyName,
      SocialId: response?.googleId,
      Url: "/signup",
    };

    const faceBookLoginObj = {
      EmailAddress: response?.email,
      SocialMedia: "Facebook",
      FirstName: response.name?.split(" ").slice(0, -1).join(" "),
      LastName: response.name?.split(" ").slice(-1).join(" "),
      SocialId: response?.id,
      Url: "/signup",
    };
    console.log(tempBtn, api, "tempBtn");
    axios
      .post(api, tempBtn == "Google" ? googleLoginObj : faceBookLoginObj)
      .then(
        (resp) =>
          resp.data.IsSuccess &&
          SocialLoginSuccessfully(
            tempBtn == "Google" ? googleLoginObj : faceBookLoginObj,
            resp
          )
      );
  }

  function SocialLoginSuccessfully(SocialObj, resp) {
    localStorage.setItem("social_login_ID", resp.data.Data.UserId);
    console.log(resp, "resp_SocialLoginSuccessfully");
    var socilaLogIn = localStorage.getItem("socilaLogIn");
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: `Logged In by ${SocialObj.SocialMedia}`,
      showConfirmButton: false,
      timer: 3500,
    });
    const userName = SocialObj.FirstName +" "+ SocialObj.LastName ;
    onSignInSuccess(userName);
    closeModal()
    window.scrollTo(0, 0)
    navigate("/");
    // window.location.reload();
    localStorage.setItem("socilaLogIn", true);
    localStorage.setItem("socialObj", JSON.stringify(SocialObj));
  }

  function handleClickSignIn(event) {

    if (formik.isValid) {
      setPassworderror('')
      setLoad(true);
      obj = {
        emailaddress: "Haroonahmad@highbitsol.com",
        Password: "Harr77@!",
        Url: "/login",
      };

      obj["emailAddress"] = event.Email;
      obj["Password"] = event.Password;

      //localStorage.setItem("Check_User_Login", 0);
      var stat;

      let api = `${config.BASE_URL}`
      Axios.post(api, obj)
        .then((res) => {
          console.log("Axios Response: ", res.data);
          stat = res.data;

          console.log("stat", stat["StatusCode"]);


          if (res.data.IsSuccess) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: `Logged In...`,
              showConfirmButton: false,
              timer: 3500,
            });

            var obj_local = {
              userid: stat.Data.UserId,
              email: event.Email,
              password: event.Password,
            };

            localStorage.setItem(
              "userid-local_storage",
              JSON.stringify(obj_local)
            );
            localStorage.setItem("local_id_store", stat.Data.UserId);
            localStorage.setItem("Check_User_Login", res.data);
            localStorage.setItem("Current_Login_user", event.Email);
            localStorage.setItem("UserData", JSON.stringify(res.data));
            let data = localStorage.getItem("Check_User_Login");
            console.log("data local", data);
            localStorage.setItem("Check_User_Login", "true");

            setLoad(false);
            onSignInSuccess(res.data.Data.UserName);
            closeModal()
            window.scrollTo(0, 0)
            // window.location.reload();
            // navigate("/");
          } else {
            Swal.fire({
              icon: "warning",
              title: "Invalid Credentials",
              text: "Invalid Details Added, Please Enter Again!",
              showCancelButton: false,
              confirmButtonText: "Try Again!",
            });
            setLoad(false);
          }
        })
        .catch((e) => {
          Swal.fire({
            icon: "warning",
            title: "Error!",
            text: e.Message,
            showCancelButton: false,
            confirmButtonText: "Try Again!",
          });
        })
        .finally(() => {
          setLoad(false);
        });
    }
  }

  const handleClickbtn = () => {
    // // 👇️ navigate programmatically
    navigate("/forget_Password");
  };

  const responseFacebook = (response) => {
    if (response.accessToken) {
      handleSocialLoginRequest(response, "FB");
    }
    console.log(response, "responseFacebook");
  };

  const onSuccess = (response) => {
    console.log(response, "response");
    if (response.tokenId) {
      setSocialResp(response);
      setShowLogoutBtn(true);
      handleSocialLoginRequest(response, "Google");
    }
    console.log(response, "onSuccess_responseGoogle");
  };

  const onFailure = (response) => {
    setShowLogoutBtn(false);
    console.log(response, "onFailure_responseGoogle");
  };

  const onLogoutSuccess = (response) => {
    console.log(response, "onLogoutSuccess");
    setShowLogoutBtn(false);
  };
  function onRequest() {
    console.log(socialResp, "socialResp");
    handleSocialLoginRequest(socialResp, "Google");
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleClickSignIn,
  });
  return (
    <>
      {/* <Nav /> */}   
      <section class="login_page d-flex align-items-center px-lg-0 px-md-0 px-3 position-relative">
        <div class="container px-lg-0 position-relative">
          <button className="close_button" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M13.4099 12.0002L19.7099 5.71019C19.8982 5.52188 20.004 5.26649 20.004 5.00019C20.004 4.73388 19.8982 4.47849 19.7099 4.29019C19.5216 4.10188 19.2662 3.99609 18.9999 3.99609C18.7336 3.99609 18.4782 4.10188 18.2899 4.29019L11.9999 10.5902L5.70994 4.29019C5.52164 4.10188 5.26624 3.99609 4.99994 3.99609C4.73364 3.99609 4.47824 4.10188 4.28994 4.29019C4.10164 4.47849 3.99585 4.73388 3.99585 5.00019C3.99585 5.26649 4.10164 5.52188 4.28994 5.71019L10.5899 12.0002L4.28994 18.2902C4.19621 18.3831 4.12182 18.4937 4.07105 18.6156C4.02028 18.7375 3.99414 18.8682 3.99414 19.0002C3.99414 19.1322 4.02028 19.2629 4.07105 19.3848C4.12182 19.5066 4.19621 19.6172 4.28994 19.7102C4.3829 19.8039 4.4935 19.8783 4.61536 19.9291C4.73722 19.9798 4.86793 20.006 4.99994 20.006C5.13195 20.006 5.26266 19.9798 5.38452 19.9291C5.50638 19.8783 5.61698 19.8039 5.70994 19.7102L11.9999 13.4102L18.2899 19.7102C18.3829 19.8039 18.4935 19.8783 18.6154 19.9291C18.7372 19.9798 18.8679 20.006 18.9999 20.006C19.132 20.006 19.2627 19.9798 19.3845 19.9291C19.5064 19.8783 19.617 19.8039 19.7099 19.7102C19.8037 19.6172 19.8781 19.5066 19.9288 19.3848C19.9796 19.2629 20.0057 19.1322 20.0057 19.0002C20.0057 18.8682 19.9796 18.7375 19.9288 18.6156C19.8781 18.4937 19.8037 18.3831 19.7099 18.2902L13.4099 12.0002Z" fill="#031A40" />
            </svg>
          </button>
          <div class="login_outer_inner ">
            <div class="row justify-content-center">
              <div class="col-lg-8 col-md-7 col-sm-12 col-12 d-lg-block d-md-block d-none">
                <div class="login_bg">
                </div>
              </div>
              <div
                class="col-lg-4 col-md-5 col-sm-12 col-12 px-lg-5 ps-md-0 d-flex align-items-center">
                <div
                  class="login_outer_m position-relative px-lg-4 px-md-0 px-4 py-lg-3 py-md-0 py-4">
                  <div class="login_outer form_feild px-lg-2">
                    <div class="card_title mb-4">
                      <h2
                        class="text-center m-0">Greetings!</h2>
                    </div>
                    <form action>
                      <div class="row">
                        <div class="col-12 mb-lg-4 mb-3">
                          <label for class="w-100">Email
                            ID
                            :</label>
                          <input type="mail"
                            className={`form-control ${formik.touched.Email && formik.errors.Email
                              ? "is-invalid"
                              : ""
                              }`}
                            name="Email"
                            placeholder="Email"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            // onChange={(e) => setEmail(e.target.value)} 
                            onBlur={formik.handleBlur}
                            value={formik.values.Email}
                            isValid={formik.isValid}
                            isTouched={formik.touched.Email}
                            invalidFeedback={formik.errors.Email} />
                          <div className="invalid-feedback">
                            {formik.errors.Email}
                          </div>
                          {/* <div className="text-danger">{emailerror}</div> */}
                        </div>
                        <div
                          class="col-12 mb-lg-2 mb-2 position-relative">
                          <label for
                            class="w-100">Password
                            :</label>
                          <div class="position-relative">
                            <input type={showPassword ? "text" : "password"}
                              className={`form-control ${formik.touched.Password &&
                                formik.errors.Password
                                ? "is-invalid"
                                : ""
                                }`}
                              name="Password"
                              placeholder="Password"
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.Password}
                              isValid={formik.isValid}
                              isTouched={formik.touched.Password}
                              invalidFeedback={formik.errors.Password}
                            // onChange={(e) => setPassword(e.target.value)}
                            />

                            <div class="eye">

                              {showPassword ? (
                                <VisibilityIcon
                                  onClick={() => setShowPassword(!showPassword)}
                                  className="password-icon"
                                />
                              ) : (
                                <VisibilityOffIcon
                                  onClick={() => setShowPassword(!showPassword)}
                                  className="password-icon"
                                />
                              )}
                            </div>
                            {/* <div className="text-danger">{passworderror}</div> */}
                            <div className="invalid-feedback">
                              {formik.errors.Password}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mb-lg-4 mb-4 position-relative">
                          <span onClick={handleClickbtn} style={{ color: "#03529C", cursor: "pointer" }} className="forgot_pwd">Forgot password?</span>
                        </div>
                        <div class="col-12">
                          <button onClick={formik.handleSubmit} type="submit"
                            class="btn btn-primary mb-3">
                               {/* {isMobile ? (
                            <div>
                              {load ? (
                                <h1
                                  className={load ? "loader" : "LoginBtn"}
                                  disabled={load}
                                  style={{
                                    color: "blue",
                                    margin: "10px 0 150px 80px",
                                  }}
                                ></h1>
                              ) : (
                                "Log in"
                              )}
                            </div>
                          ) : (
                            <div>
                              {load ? (
                                <h1
                                  className={load ? "loader" : "LoginBtn"}
                                  disabled={load}
                                  style={{
                                    color: "blue",
                                    margin: "10px 0 150px 140px",
                                  }}
                                ></h1>
                              ) : (
                                "Log in"
                              )}
                            </div>
                          )} */}
                            {load ? (
                              <div className='w-100 align-items-center d-flex justify-content-center'>
                                <h1
                                  class="loader"
                                  disabled={load}
                                  style={{
                                    // color: "#101A36",
                                    margin: "-5px",
                                    width: '30px',
                                    height: '30px',
                                  }}
                                ></h1>
                              </div>
                            ) : (
                              "Log in"
                            )}
                            </button>
                         
                          <button
                            class="btn btn-outline-primary d-flex align-items-center justify-content-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25" height="24"
                              viewBox="0 0 25 24"
                              fill="none">
                              <g
                                clip-path="url(#clip0_3218_23)">
                                <path
                                  d="M24.4875 12.2245C24.4875 11.2413 24.4059 10.5237 24.2292 9.77963H12.7393V14.2176H19.4836C19.3477 15.3205 18.6134 16.9815 16.9817 18.0976L16.9588 18.2461L20.5917 20.9963L20.8434 21.0209C23.1549 18.9347 24.4875 15.8653 24.4875 12.2245Z"
                                  fill="#4285F4" />
                                <path
                                  d="M12.7391 23.9176C16.0433 23.9176 18.8171 22.8545 20.8432 21.0209L16.9815 18.0976C15.9481 18.8018 14.5611 19.2934 12.7391 19.2934C9.50291 19.2934 6.75622 17.2074 5.77711 14.324L5.63359 14.3359L1.85604 17.1927L1.80664 17.3269C3.81906 21.2334 7.95273 23.9176 12.7391 23.9176Z"
                                  fill="#34A853" />
                                <path
                                  d="M5.77726 14.324C5.51892 13.5799 5.3694 12.7826 5.3694 11.9588C5.3694 11.1349 5.51892 10.3377 5.76367 9.59359L5.75683 9.43512L1.93194 6.5324L1.8068 6.59057C0.977385 8.21166 0.501465 10.0321 0.501465 11.9588C0.501465 13.8855 0.977385 15.7058 1.8068 17.3269L5.77726 14.324Z"
                                  fill="#FBBC05" />
                                <path
                                  d="M12.7391 4.62403C15.0371 4.62403 16.5871 5.59402 17.471 6.40461L20.9248 3.10928C18.8036 1.1826 16.0433 2.38419e-07 12.7391 2.38419e-07C7.95273 2.38419e-07 3.81906 2.68406 1.80664 6.59056L5.76351 9.59359C6.75622 6.7102 9.50291 4.62403 12.7391 4.62403Z"
                                  fill="#EB4335" />
                              </g>
                              <defs>
                                <clippath
                                  id="clip0_3218_23">
                                  <rect width="24"
                                    height="24"
                                    fill="white"
                                    transform="translate(0.5)" />
                                </clippath>
                              </defs>
                            </svg>
                            {/* <span>Continue with
                              Google</span> */}
                            <GoogleLogin
                              // onRequest={onRequest}
                              clientId="251997598489-2en61bset3ti5uakmnc5370fvma3v0sl.apps.googleusercontent.com"
                              // buttonText="Login"
                              onSuccess={onSuccess}
                              onFailure={onFailure}
                              // isSignedIn={true}
                              autoLoad={false}
                              cookiePolicy={"single_host_origin"}
                              render={(renderProps) => (
                                // <button onClick={renderProps.onClick}>
                                //   Continue with Google
                                // </button>
                                <button onClick={(e) => {
                                  e.preventDefault();
                                  renderProps.onClick();
                                }}>
                                  Continue with Google
                                </button>
                              )}
                              buttonText="Continue with Google"
                            />
                          </button>
                        </div>
                        <div class="mt-4 pt-lg-2 pt-2">
                          <div
                            class="dont_account text-center">
                            <span
                              class="w-100 d-block">Don’t
                              have an
                              account?</span>
                            <span onClick={() => (navigate('/Signup'))}
                              class=" w-auto mt-2"><b style={{ color: '#031A40', cursor: 'pointer' }}>Sign
                                up now</b></span>
                            {/* <button onClick={() => (navigate('/Signup'))}
                              class="btn btn-primary w-auto mt-2">Sign
                              up now</button> */}
                          </div>
                        </div>
                        {/* <div class="mt-4 pt-lg-2">
                              <div
                                class="dont_account text-center">
                                <a >I forgot my
                                  password!</a>
                              </div>
                            </div> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>);
}
const Wrapper = styled.section`

.left_Container {
    position: absolute;
// width: 628px;
// height: 781px;
// left: 0px;
// top: 0px;

background: #26649D;
}

.left-card {
  position: absolute;
width: 355px;
height: 580px;
left: 260px;
top: 151px;

background: #26649D;
box-shadow: 0px 4px 32px 16px rgba(30, 30, 30, 0.25);
border-radius: 8px;
}


.eye-icon svg {
  position: absolute;
  top: 310px;
  right: 80px;
}

.right-card {
    position: relative;
    width: 580px;
    height: 580px;
    left: 603px;
    top: 151px;

background: #FFFFFF;
box-shadow: 0px 4px 32px 16px rgba(30, 30, 30, 0.25);
border-radius: 8px;
}
.img-resetPasswordLeft {
  position: absolute;
width: 347.59px;
height: 499.96px;
left: 0px;
top: 42px;
}

.yourcart {
  position: absolute;
  width: 225px;
  height: 28px;
  left: 84px;
  font-family: 'WorkSans';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #4E4E4E;
}
.social-buttons{
  display:none
}

.label-align {
  position: absolute;
  top:130px;
  left:110px;
      padding-top: 22px;
      font-family: WorkSans;
      font-size: 16px;
      font-weight: 500;
      line-height: 40px;
    }

    .label-align-password{
      position: absolute;
  top:240px;
  left:110px;
      padding-top: 22px;
      font-family: WorkSans;
      font-size: 16px;
      font-weight: 500;
      line-height: 40px;
    }

    input[type="email"] {
          box-sizing: border-box;
         position: absolute;
          width: 388px;
          height: 48px;
          left: 122px;
          top: 190px;
          background: #FFFFFF;
          border: 1px solid #26649D;
          border-radius: 8px;
          padding: 0px 10px;
        }
      
        input[type="password"] {
          box-sizing: border-box;
         position: absolute;
          width: 388px;
          height: 48px;
          left: 122px;
          top: 300px;
          background: #FFFFFF;
          border: 1px solid #26649D;
          border-radius: 8px;
          padding: 0px 10px;
        }

        input[type="text"] {
          box-sizing: border-box;
         position: absolute;
          width: 388px;
          height: 48px;
          left: 122px;
          top: 290px;
          background: #FFFFFF;
          border: 1px solid #26649D;
          border-radius: 8px;
        }

  .forget-password{
    position:absolute;
    left:350px;
    top:347px;
  }

  .continue {
    position: absolute;
    top: 400px;
    left: 122px;
      }
      .continue button {
        background-color: #22669c;
        border: none;
        padding: 10px 0px;
        color: white;
        width:390px;
      }

      .continue1 {
        position: absolute;
        top: 460px;
        left: 122px;
        
          
          }
          .continue1 button {
            background: #FFFFFF;
          box-shadow: 0px 4px 8px rgba(78, 78, 78, 0.5);
            border: none;
            padding: 10px 0px;
            color: white;
            width:390px;
          }

    .btn-facebook{
      position: absolute;
        top: 400px;
        left: 37px;
    }
    .btn-facebook button{
      background-color: white;
      color: rgb(38, 100, 157) !important;
      font-weight: 500;
      font-size:14px;
      text-transform: none;
      border-radius: 10px;
      padding: 10px 0px;
      color: white;
      width:280px;
      
    }

    

    .btn-Google{
      position: absolute;
        top: 460px;
        left: 37px;
        
    }
    .btn-Google button {
      background-color: #EA4335;
       box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3); 
      padding: 10px 0px;
      color: white;
      width:280px;
      font-weight: 500;
      font-size:14px;
    }
    .social-text{
      display:none
    }

    .email-error{
      color:red;
      fontsize:12px;
      position:absolute;
      top:240px;
      left:122px;
      
    }
    .password-error{
      color:red;
      fontsize:12px;
      position:absolute;
      top:350px;
      left:122px;
      width: 70%;
    }
  
    @media Screen and (min-width: 996px) and (max-width: 1150px) {
      .left-card {
        width: 265px;
        height: 580px;
        left: 222px;
      }
      .right-card {
        width: 455px;
        height: 580px;
        left: 475px;
    }

    input[type="email"] {
      width: 320px;
      height: 48px;
      left: 50px;
    }
    input[type="password"] {
      width: 320px;
      left: 50px;
    }
    input[type="text"] {
      width: 320px;
      height: 48px;
      margin: 0;
      left: 50px;
    top: 297px;
    }
    .label-align-password,.label-align {
      left: 50px;
    }
    .btn-facebook, .btn-Google{
      left: 15px;
    }
    .btn-facebook button{
      width: 230px;
    }
    .btn-Google button{
      width: 230px;
    }
    .continue, .continue1{
      left: 50px;
      width:320px;
    }
    .continue button, .continue1 button{
      left: 50px;
      width:320px;
    }
    .forget-password{
      left: 200px;
    }
    .img-resetPasswordLeft{
      width: 264.59px;
    }
    .email-error{
      color:red;
      fontsize:12px;
      position:absolute;
      top: 239px;
    left: 53px;
      
    }
    .password-error{
      color:red;
      fontsize:12px;
      position:absolute;
      top: 350px;
      left: 45px;
  
    }

    }

    
    @media Screen and (min-width: 320px) and (max-width: 995px) {
      .left_Container{
        display:none
      }
      .right-card {
        position: unset;
        // width: 320px;
        width: 80%;
        // height: 920px;
        left: 10%;
        right: 10%;
        margin-top: 110px;
        margin-left: auto;
        margin-right: auto;
        // border: 1px solid;
      background: #FFFFFF;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      border-radius: 0px;
      }

      .password-error, .email-error, .continue, input[type="email"],.label-align-password, input[type="password"]
       ,input[type="text"],.forget-password, .continue1,
       .btn-facebook, .btn-Google,
       .social-text,
       .label-align{
        position: unset;
      }
      input[type="email"] {
       position: unset;
       width:100%;

      }
      .label-align{
        margin-top: 20px;
      }
      .label-align-password{
        position: unset;
        width:100%;
      }
      input[type="password"]{
        position: unset;
        width:100%;
      }
      input[type="text"] {
        width:100%;
        height: 48px;
        margin: 0;
      }
      .forget-password{
        position: unset;
        text-align: right;
      }
      .social-buttons{
        display:block;
        text-align: center;
       }
       .social-text{
      display: block important!
      font-family: 'WorkSans';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #4E4E4E;
      margin-bottom:30px;

      }
      .btn-Google button {
  background-color: #EA4335;
   box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3);
  padding: 10px 0px;
  color: white;
  width:300px;
  margin-bottom: 10px;
}
.btn-facebook button{
  background-color: white;
   box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3); 
  padding: 10px 0px;
  color: white;
  width:300px;
  borfer-radius:10px;
  margin-bottom: 10px;
}
.hr-line{
  position: unset;
  width: 250px;
  height: 0px;
 margin: auto auto;
  border: 1px solid #7E7E7E;
  margin-top:50px;
  margin-bottom:50px;

}
.alignment-order{
  text-align: center;
  margin-top: 20px;
}
.continue button, .continue1 button{
  width: 50%;
}
.yourcart img{
  display:none
}
.yourcart {
  position: unset;
  width: 220px;
  height: 28px;
  left: 0px;
  font-family: 'WorkSans';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #4E4E4E;
}
.eye-icon { position: relative;
}
.eye-icon svg {position: absolute;
  right: 20px;
  top: 10px;}

  .email-error{
    color:red;
    fontsize:12px;
  
    
  }
  .password-error{
    color:red;
    fontsize:12px;

  
  }

}



.body{
  height: 100vh
  width: 100%
}



// .spinner-border {
//   color: #fff;

// }

// .login-content { 
//   color: var(--font-color-for-content);
//   font-weight: var(--font-weight-300);
//   font-size: var(--font-size-16px);
//   color: var(--color-white);
//   line-height: 19px;
//   height: 100vh;
//   overflow: hidden;
// }
// .login-content p {
  
// }


// .img-here {
//   height: 100%;
//   width: 100%;
//   margin-left: 0px;
//   margin-top: 0px;
// }
// .new{
//     background-color: #26649d;
//     margin-left: -15px;
//     padding-bottom:1000px;
//     padding-right: 0px;
// }

// .frgtdiv{
// text-align: right;
// margin-right:90px;
// }

// .pad-right{
//      margin-right: 10px;
//     padding-left: 10%;
//     margin-top: 120px;
//     display items: center;
//     background-color: white;

// }

// .LoginBtn{
//   width: 388px;
//   height: 48px;
//   left: 730px;
//   top: 548px;
//   filter: drop-shadow(0px 4px 8px rgba(38, 100, 157, 0.25));
// }

// .LoadBtn{
//   width: 388px;
//   height: 48px;
//   left: 730px;
//   top: 548px;
//   filter: drop-shadow(0px 4px 8px rgba(38, 100, 157, 0.25));
//   background-color: grey;
// }

// .SignupBtn{
//   width: 388px;
//   height: 48px;
//   left: 730px;
//   top: 548px;
//   filter: drop-shadow(0px 4px 8px rgba(38, 100, 157, 0.25));
//   //background-color: var(--color-white);
// }

// .word{

    
//   width: 225px;
//   //height: 19px;
//  // left: 730px;
//   top: 283px;
  
//   font-family: 'WorkSans';
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 19px;
  
//   color: #4E4E4E;

// }

// .forgt_pw{

// }

// .yourcart {
//     padding-top: 80px;
//     padding-left: -10px;
//     margin-bottom: 10px;

//     font-size: var(--font-size-24px);
//     font-weight: var(--font-weight-500);
//     line-height: 28px;
//     color: black;
//   }
//   input[type="text"] {
//     box-sizing: border-box;
//    // position: absolute;
//     width: 388px;
//     height: 48px;
//     left: 730px;
//     top: 385px;
//     background: #FFFFFF;
//     border: 1px solid #26649D;
//     border-radius: 8px;
//   }

//   input[type="email"] {
//     box-sizing: border-box;
// //    position: absolute;
//     width: 388px;
//     height: 48px;
//     left: 730px;
//     top: 385px;
//     background: #FFFFFF;
//     border: 1px solid #26649D;
//     border-radius: 8px;
//   }

//   input[type="password"] {
//     box-sizing: border-box;
//   //  position: absolute;
//     width: 388px;
//     height: 48px;
//     left: 730px;
//     top: 385px;
//     background: #FFFFFF;
//     border: 1px solid #26649D;
//     border-radius: 8px;
//   }

//   .shipping p {
//     font-family: WorkSans;
//     font-size: 12px;
//     font-weight: 300;
//     line-height: 14px;
//   }


//   .continue {
//     margin-top: 10%;
//     margin-left:10%;
//     margin-right:10%;
//   }
//   .continue button {
//     background-color: #22669c;
//     /* box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3); */
    
//     border: none;
//     padding: 10px 0px;
//     color: white;
//     width:100%;
//   }

//   .continue1 {
//     margin-top: 5%;
//     margin-left:10%;
//     margin-right:10%;
//   }
//   .continue1 button {
//     background-color: white;
//     /* box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3); */
    
//     border: none;
//     padding: 10px 0px;
//     color: white;
//     width:100%;
//   }

//   .Remove{
    
//     //margin-right:20px;
//     width: 225px;
//   height: 19px;
//   left: 893px;
//   top: 473px;

// font-family: 'WorkSans';
// font-style: normal;
// font-weight: 500;
// font-size: 16px;
// line-height: 19px;
// /* identical to box height */

// text-align: right;
// text-decoration-line: underline;

// color: #4E4E4E;
//   }

//   .Remove:hover{
//     cursor: pointer;
//   }

//   .label-align {
//     padding-top: 22px;
//     font-family: WorkSans;
//     font-size: 16px;
//     font-weight: 500;
//     line-height: 40px;
//   }
  
//   .alignment-order {
//     padding-bottom: 10px;
//     margin-right: 100px;
//   }

.text-center {text-align: center}



`;

export default Signin;
