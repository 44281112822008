import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, Link } from "react-router-dom";
import logo from './../assets/logo1.png'
import Banner from './../assets/banner_img.png'
import Comprehensive from './../assets/comprehensive_vec.png'
import Enhanced from './../assets/Enhanced.png'
import Multimodal from './../assets/Multimodal.png'
import Urine from './../assets/urine.png'
import Renaware from './../assets/renaware.png'
import RenawareDevice from './../assets/renaware_device.png'
import IntelliVista_card_img from './../assets/IntelliVista_card_img.jpeg'
import HarnessCollage from './../assets/harness-collage.png'
import Header from './Reuseable Components/Header';
import NewFooter from './Reuseable Components/NewFooter';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Modal from 'react-modal';
import SuccessDialog from './Modal/SuccessDialog';
import PreOrderModal from './Modal/PreOrderModal';
const RenAware = () => {
    const sectionRef = useRef(null);

    // Function to handle button click
    const handleClick = () => {
        // Scroll to the section
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState('testStrips');

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const handleButtonClick = (content) => {
        setModalContent(content);
    };

    return (
        <>
            <body className="renaware" style={{ backgroundColor: "#031A40" }}>
                <Header />
                <section
                    className="banner-section RaInner_banner renware_banner new_home_banner d-flex align-items-end">
                    <div className="container position-relative" style={{ zIndex: 9 }}>
                        <div className="row g-4 justify-content-center">
                            <div
                                className="col-xl-7 col-lg-8 col-md-8 col-sm-12 col-12 d-flex align-items-center">
                                <div className="section_content">
                                    <h2 className="mb-4"
                                        style={{ fontWeight: '300', textTransform: "none" }}>Tracking
                                        personal health
                                        and wellness, with metabolites.</h2>
                                    <div className="card_title">
                                        <h6 className="text-white m-0">Metabolite levels are
                                            consistent in blood
                                            but
                                            vary in urine, revealing key insights into
                                            the
                                            body's metabolism.</h6>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-xl-4 col-lg-4 offset-xl-1 col-md-4 col-sm-12 col-12 d-flex align-items-center justify-content-lg-end">
                                <div>
                                    <img src={Banner} className='img-fluid' alt='' />
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="welness_section">
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-xl-6 col-lg-7 col-md-10 col-sm-12 col-12 pe-lg-4">
                                <div className="welness_card card_title">
                                    <h2 className="mb-lg-4 mb-md-4 mb-4 text-white">Meet
                                        RenAware,<br />
                                        your at-Home solution <br />for personal health
                                        insights</h2>
                                    <p
                                        className="mb-lg-4 mb-md-4 mb-4 text-start text-white">RenAware
                                        offers a convenient solution for tracking your
                                        health status right from home, making it easier
                                        than ever to stay informed about your
                                        well-being.</p>

                                    <button onClick={handleClick}
                                        className="btn btn-outline-primary w-auto ps-3 pe-4 d-flex jusitfy-content-center align-items-center gap-3">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            width="24" height="24" viewBox="0 0 24 24"
                                            fill="none">
                                            <path
                                                d="M3.48392 3.48357C3.82886 3.14099 4.29527 2.94873 4.78142 2.94873C5.26757 2.94873 5.73399 3.14099 6.07892 3.48357L17.3852 14.7936V8.90608C17.3852 8.66526 17.4326 8.42681 17.5248 8.20433C17.6169 7.98185 17.752 7.7797 17.9223 7.60942C18.0925 7.43914 18.2947 7.30407 18.5172 7.21191C18.7397 7.11976 18.9781 7.07233 19.2189 7.07233C19.4597 7.07233 19.6982 7.11976 19.9207 7.21191C20.1431 7.30407 20.3453 7.43914 20.5156 7.60942C20.6859 7.7797 20.8209 7.98185 20.9131 8.20433C21.0052 8.42681 21.0527 8.66526 21.0527 8.90608V19.1998C21.0547 19.4418 21.0088 19.6817 20.9178 19.9059C20.8269 20.1301 20.6925 20.3341 20.5225 20.5062C20.3524 20.6783 20.1501 20.8152 19.927 20.9089C19.704 21.0026 19.4646 21.0513 19.2227 21.0523H8.90642C8.42008 21.0523 7.95366 20.8591 7.60977 20.5152C7.26587 20.1713 7.07267 19.7049 7.07267 19.2186C7.07267 18.7322 7.26587 18.2658 7.60977 17.9219C7.95366 17.578 8.42008 17.3848 8.90642 17.3848H14.7939L3.48392 6.07857C3.14134 5.73364 2.94908 5.26722 2.94908 4.78108C2.94908 4.29493 3.14134 3.82851 3.48392 3.48357Z"
                                                fill="white" />
                                        </svg>
                                        <span>Discover More</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="key_feature_section" ref={sectionRef}>
                    <div className="container">
                        <div className="row g-4">
                            <div
                                className="col-lg-9 col-md-12 col-sm-12 col-12 mb-lg-0 mb-md-3">
                                <div className="card_title text-start">
                                    <h2 className="mb-lg-4 mb-md-4 mb-4">Key features</h2>
                                </div>
                            </div>
                            <div className='col-12 mt-0'>

                                <div className='row mt-lg-5 mt-md-4 mt-4'>
                                    <div className='col-12'>
                                        <OwlCarousel className='owl-theme' nav={false} dots={false} margin={24}
                                            responsive={{
                                                0: {
                                                    items: 1,
                                                },
                                                576: {
                                                    items: 1,
                                                },
                                                768: {
                                                    items: 2,
                                                },
                                                992: {
                                                    items: 2,
                                                },
                                                1250: {
                                                    items: 3,
                                                },
                                            }}>
                                            <div class='item'>
                                                <div
                                                    className="feature_card card_title text-center p-lg-4 p-md-4 p-4">
                                                    <img src={Comprehensive} alt='' />
                                                    <h6 className="text-center my-lg-4 my-md-4 my-4">
                                                        Comprehensive urinary<br /> biomarker panel
                                                    </h6>
                                                    <p className="m-0 text-center">RenAware analyzes twelve
                                                        urinary biomarkers providing valuable insights
                                                        into your overall<br />health.</p>
                                                </div>
                                            </div>
                                            <div class='item'>
                                                <div
                                                    className="feature_card card_title text-center p-lg-4 p-md-4 p-4">
                                                    <img src={Enhanced} alt='' />
                                                    <h6
                                                        className="text-center my-lg-4 my-md-4 my-4">Enhanced
                                                        Accuracy</h6>
                                                    <p className="m-0 text-center">Use your smart phone for
                                                        convenient usage or use the low-cost RenAware
                                                        reader for enhanced accuracy </p>
                                                </div>
                                            </div>
                                            <div class='item'>
                                                <div
                                                    className="feature_card card_title text-center p-lg-4 p-md-4 p-4">
                                                    <img src={Multimodal} alt='' />
                                                    <h6
                                                        className="text-center my-lg-4 my-md-4 my-4">Multimodal
                                                        Analysis</h6>
                                                    <p className="m-0 text-center">Ability to combine with
                                                        cognitive, sensory and retinal biomarkers for a
                                                        multimodal analysis to get a complete picture of
                                                        health and wellness</p>
                                                </div>
                                            </div>
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-0">
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt-lg-0">
                                <div
                                    className="feature_card card_title text-center p-lg-4 p-md-4 p-4">
                                    <img src={Enhanced} alt='' />
                                    <h6
                                        className="text-center my-lg-4 my-md-4 my-4">Enhanced
                                        Accuracy</h6>
                                    <p className="m-0 text-center">Use your smart phone for
                                        convenient usage or use the low-cost RenAware
                                        reader for enhanced accuracy </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt-lg-0">
                                <div
                                    className="feature_card card_title text-center p-lg-4 p-md-4 p-4">
                                    <img src={Multimodal} alt='' />
                                    <h6
                                        className="text-center my-lg-4 my-md-4 my-4">Multimodal
                                        Analysis</h6>
                                    <p className="m-0 text-center">Ability to combine with
                                        cognitive, sensory and retinal biomarkers for a
                                        multimodal analysis to get a complete picture of
                                        health and wellness</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>

                <section className="medically-known loss">
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-lg-9 col-md-12 col-sm-12 col-12 mb-lg-0 mb-md-3">
                                <div className="card_title text-start">
                                    <h2 className="mb-lg-5 mb-md-4 mb-4">Get comprehensive
                                        insights with 12 biomarkers</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-lg-4 gy-lg-0 g-4 d-lg-flex d-md-none d-none">
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <div
                                    className="medically_card card_title d-flex align-items-start gap-3 px-xl-3 px-lg-0 px-md-0 px-0">
                                    <div className="biomark_circle">

                                    </div>
                                    <div>
                                        <h6 className="mb-lg-3 mb-md-4 blue mb-3">Blood</h6>
                                        <p className="text-start m-0 blue">Blood in urine may be
                                            due to a problem in your kidneys or parts of
                                            the urinary tract. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <div
                                    className="medically_card card_title d-flex align-items-start gap-3 px-xl-3 px-lg-0 px-md-0 px-0">
                                    <div className="biomark_circle biomark_circle_2">

                                    </div>
                                    <div>
                                        <h6 className="mb-lg-3 mb-md-4 blue mb-3">Ketone</h6>
                                        <p className="text-start m-0 blue">Ketones accumulate
                                            when the body uses fats for energy due to a
                                            lack of sugars or carbs.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <div
                                    className="medically_card card_title d-flex align-items-start gap-3 px-xl-3 px-lg-0 px-md-0 px-0">
                                    <div className="biomark_circle biomark_circle_3">

                                    </div>
                                    <div>
                                        <h6 className="mb-lg-3 mb-md-4 blue mb-3">Creatinine</h6>
                                        <p className="text-start m-0 blue">Creatinine, a waste
                                            product from exercise and meat consumption,
                                            is filtered by healthy kidneys and excreted
                                            in urine, serving as a marker of kidney
                                            health.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <div
                                    className="medically_card card_title d-flex align-items-start gap-3 px-xl-3 px-lg-0 px-md-0 px-0">
                                    <div className="biomark_circle biomark_circle_4">

                                    </div>
                                    <div>
                                        <h6 className="mb-lg-3 mb-md-4 blue mb-3">Specific
                                            Gravity</h6>
                                        <p className="text-start m-0 blue">Specific Gravity
                                            measure of the concentration of solutes in
                                            the urine and could be indicative of
                                            dehydration.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <hr className="my-4" />
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <div
                                    className="medically_card card_title d-flex align-items-start gap-3 px-xl-3 px-lg-0 px-md-0 px-0">
                                    <div className="biomark_circle biomark_circle_5">

                                    </div>
                                    <div>
                                        <h6 className="mb-lg-3 mb-md-4 blue mb-3">Ascorbate</h6>
                                        <p className="text-start m-0 blue">Ascorbic acid in urine
                                            may be a result of consuming fruits and
                                            veggies. Normally ascorbate levels are low
                                            in urine, but its presence may interfere
                                            with urinalysis.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <div
                                    className="medically_card card_title d-flex align-items-start gap-3 px-xl-3 px-lg-0 px-md-0 px-0">
                                    <div className="biomark_circle biomark_circle_6">

                                    </div>
                                    <div>
                                        <h6 className="mb-lg-3 mb-md-4 blue mb-3">Glucose</h6>
                                        <p className="text-start m-0 blue">Normally, urine
                                            contains very little or no glucose. High
                                            urine glucose means high blood glucose and
                                            could be indicative of diabetes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <div
                                    className="medically_card card_title d-flex align-items-start gap-3 px-xl-3 px-lg-0 px-md-0 px-0">
                                    <div className="biomark_circle biomark_circle_7">

                                    </div>
                                    <div>
                                        <h6 className="mb-lg-3 mb-md-4 blue mb-3">Protein</h6>
                                        <p className="text-start m-0 blue">A high protein level
                                            in urine indicates kidney damage.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <div
                                    className="medically_card card_title d-flex align-items-start gap-3 px-xl-3 px-lg-0 px-md-0 px-0">
                                    <div className="biomark_circle biomark_circle_8">

                                    </div>
                                    <div>
                                        <h6 className="mb-lg-3 mb-md-4 blue mb-3">pH</h6>
                                        <p className="text-start blue m-0">Some types of kidney
                                            stones or bacterial infection may lead to
                                            alkaline or acidic urine.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <hr className="my-4" />
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <div
                                    className="medically_card card_title d-flex align-items-start gap-3 px-xl-3 px-lg-0 px-md-0 px-0">
                                    <div className="biomark_circle biomark_circle_9">

                                    </div>
                                    <div>
                                        <h6 className="mb-lg-3 mb-md-4 blue mb-3">Nitrite</h6>
                                        <p className="text-start m-0 blue">Nitrites in urine may
                                            be a sign of a urinary tract infection.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <div
                                    className="medically_card card_title d-flex align-items-start gap-3 px-xl-3 px-lg-0 px-md-0 px-0">
                                    <div className="biomark_circle biomark_circle_10">

                                    </div>
                                    <div>
                                        <h6 className="mb-lg-3 mb-md-4 blue mb-3">Leukocytes</h6>
                                        <p className="text-start m-0 blue">Leukocytes in urine
                                            may be a sign of a urinary tract
                                            infection</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <div
                                    className="medically_card card_title d-flex align-items-start gap-3 px-xl-3 px-lg-0 px-md-0 px-0">
                                    <div className="biomark_circle biomark_circle_11">

                                    </div>
                                    <div>
                                        <h6 className="mb-lg-3 mb-md-4 blue mb-3">Bilirubin</h6>
                                        <p className="text-start m-0 blue">Elevated bilirubin
                                            levels, resulting from the breakdown of red
                                            blood cells, can signal liver or bile duct
                                            issues.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <div
                                    className="medically_card card_title d-flex align-items-start gap-3 px-xl-3 px-lg-0 px-md-0 px-0">
                                    <div className="biomark_circle biomark_circle_12">

                                    </div>
                                    <div>
                                        <h6
                                            className="mb-lg-3 mb-md-3 blue mb-3">Urobilinogen</h6>
                                        <p className="text-start m-0 blue">Excess urobilinogen in
                                            urine may indicate liver diseases.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row d-lg-none d-flex'>
                            <div className='col-12'>
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <div className='accordion-header' id="blood">
                                            <div
                                                className="medically_card card_title d-flex align-items-center accordion-button collapsed gap-3 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBlood" aria-expanded="true" aria-controls="collapseBlood">
                                                <div className="biomark_circle">
                                                </div>
                                                <div>
                                                    <p className='m-0'><b>Blood</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapseBlood" class="accordion-collapse collapse" aria-labelledby="blood" data-bs-parent="#accordionExample">
                                            <div class="accordion-body ps-3 pt-2 pb-0">
                                                <div className='card_title ps-4'>
                                                    <p className="text-start m-0 blue">Blood in urine may be
                                                        due to a problem in your kidneys or parts of
                                                        the urinary tract. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item mt-4">
                                        <div className='accordion-header' id="ketone">
                                            <div
                                                className="medically_card card_title d-flex align-items-center accordion-button collapsed gap-3 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseKetone" aria-expanded="false" aria-controls="collapseKetone">
                                                <div className="biomark_circle biomark_circle_2">
                                                </div>
                                                <div>
                                                    <p className='m-0'><b>Ketone</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapseKetone" class="accordion-collapse collapse" aria-labelledby="ketone" data-bs-parent="#accordionExample">
                                            <div class="accordion-body ps-3 pt-2 pb-0">
                                                <div className='card_title ps-4'>
                                                    <p className="text-start m-0 blue">Ketones accumulate when the body uses fats for energy due to a lack of sugars or carbs.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item mt-4">
                                        <div className='accordion-header' id="careatin">
                                            <div
                                                className="medically_card card_title d-flex align-items-center accordion-button collapsed gap-3 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapsecareatin" aria-expanded="false" aria-controls="collapsecareatin">
                                                <div className="biomark_circle biomark_circle_3">
                                                </div>
                                                <div>
                                                    <p className='m-0'><b>Creatinine</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapsecareatin" class="accordion-collapse collapse" aria-labelledby="careatin" data-bs-parent="#accordionExample">
                                            <div class="accordion-body ps-3 pt-2 pb-0">
                                                <div className='card_title ps-4'>
                                                    <p className="text-start m-0 blue">Creatinine, a waste product from exercise and meat consumption, is filtered by healthy kidneys and excreted in urine, serving as a marker of kidney health.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item mt-4">
                                        <div className='accordion-header' id="gravity">
                                            <div
                                                className="medically_card card_title d-flex align-items-center accordion-button collapsed gap-3 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapsegravity" aria-expanded="false" aria-controls="collapsegravity">
                                                <div className="biomark_circle biomark_circle_4">
                                                </div>
                                                <div>
                                                    <p className='m-0'><b>Specific Gravity</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapsegravity" class="accordion-collapse collapse" aria-labelledby="gravity" data-bs-parent="#accordionExample">
                                            <div class="accordion-body ps-3 pt-2 pb-0">
                                                <div className='card_title ps-4'>
                                                    <p className="text-start m-0 blue">Specific Gravity measure of the concentration of solutes in the urine and could be indicative of dehydration.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item mt-4">
                                        <div className='accordion-header' id="ascorbate">
                                            <div
                                                className="medically_card card_title d-flex align-items-center accordion-button collapsed gap-3 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseascorbate" aria-expanded="false" aria-controls="collapseascorbate">
                                                <div className="biomark_circle biomark_circle_5">
                                                </div>
                                                <div>
                                                    <p className='m-0'><b>Ascorbate</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapseascorbate" class="accordion-collapse collapse" aria-labelledby="ascorbate" data-bs-parent="#accordionExample">
                                            <div class="accordion-body ps-3 pt-2 pb-0">
                                                <div className='card_title ps-4'>
                                                    <p className="text-start m-0 blue">Ascorbic acid in urine may be a result of consuming fruits and veggies. Normally ascorbate levels are low in urine, but its presence may interfere with urinalysis.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item mt-4">
                                        <div className='accordion-header' id="glucose">
                                            <div
                                                className="medically_card card_title d-flex align-items-center accordion-button collapsed gap-3 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseglucose" aria-expanded="false" aria-controls="collapseglucose">
                                                <div className="biomark_circle biomark_circle_6">
                                                </div>
                                                <div>
                                                    <p className='m-0'><b>Glucose</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapseglucose" class="accordion-collapse collapse" aria-labelledby="glucose" data-bs-parent="#accordionExample">
                                            <div class="accordion-body ps-3 pt-2 pb-0">
                                                <div className='card_title ps-4'>
                                                    <p className="text-start m-0 blue">Normally, urine contains very little or no glucose. High urine glucose means high blood glucose and could be indicative of diabetes.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item mt-4">
                                        <div className='accordion-header' id="protein">
                                            <div
                                                className="medically_card card_title d-flex align-items-center accordion-button collapsed gap-3 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseprotein" aria-expanded="false" aria-controls="collapseprotein">
                                                <div className="biomark_circle biomark_circle_7">
                                                </div>
                                                <div>
                                                    <p className='m-0'><b>Protein</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapseprotein" class="accordion-collapse collapse" aria-labelledby="protein" data-bs-parent="#accordionExample">
                                            <div class="accordion-body ps-3 pt-2 pb-0">
                                                <div className='card_title ps-4'>
                                                    <p className="text-start m-0 blue">A high protein level in urine indicates kidney damage.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item mt-4">
                                        <div className='accordion-header' id="pH">
                                            <div
                                                className="medically_card card_title d-flex align-items-center accordion-button collapsed gap-3 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapsepH" aria-expanded="false" aria-controls="collapsepH">
                                                <div className="biomark_circle biomark_circle_8">
                                                </div>
                                                <div>
                                                    <p className='m-0'><b>pH</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapsepH" class="accordion-collapse collapse" aria-labelledby="pH" data-bs-parent="#accordionExample">
                                            <div class="accordion-body ps-3 pt-2 pb-0">
                                                <div className='card_title ps-4'>
                                                    <p className="text-start m-0 blue">Some types of kidney stones or bacterial infection may lead to  alkaline or acidic urine.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item mt-4">
                                        <div className='accordion-header' id="Nitrite">
                                            <div
                                                className="medically_card card_title d-flex align-items-center accordion-button collapsed gap-3 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNitrite" aria-expanded="false" aria-controls="collapseNitrite">
                                                <div className="biomark_circle biomark_circle_9">
                                                </div>
                                                <div>
                                                    <p className='m-0'><b>Nitrite</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapseNitrite" class="accordion-collapse collapse" aria-labelledby="Nitrite" data-bs-parent="#accordionExample">
                                            <div class="accordion-body ps-3 pt-2 pb-0">
                                                <div className='card_title ps-4'>
                                                    <p className="text-start m-0 blue">Nitrites in urine may be a sign of a urinary tract infection.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item mt-4">
                                        <div className='accordion-header' id="Leukocytes">
                                            <div
                                                className="medically_card card_title d-flex align-items-center accordion-button collapsed gap-3 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLeukocytes" aria-expanded="false" aria-controls="collapseLeukocytes">
                                                <div className="biomark_circle biomark_circle_10">
                                                </div>
                                                <div>
                                                    <p className='m-0'><b>Leukocytes</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapseLeukocytes" class="accordion-collapse collapse" aria-labelledby="Leukocytes" data-bs-parent="#accordionExample">
                                            <div class="accordion-body ps-3 pt-2 pb-0">
                                                <div className='card_title ps-4'>
                                                    <p className="text-start m-0 blue">Leukocytes in urine may be a sign of a urinary tract infection</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item mt-4">
                                        <div className='accordion-header' id="Bilirubin">
                                            <div
                                                className="medically_card card_title d-flex align-items-center accordion-button collapsed gap-3 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBilirubin" aria-expanded="false" aria-controls="collapseBilirubin">
                                                <div className="biomark_circle biomark_circle_11">
                                                </div>
                                                <div>
                                                    <p className='m-0'><b>Bilirubin</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapseBilirubin" class="accordion-collapse collapse" aria-labelledby="Bilirubin" data-bs-parent="#accordionExample">
                                            <div class="accordion-body ps-3 pt-2 pb-0">
                                                <div className='card_title ps-4'>
                                                    <p className="text-start m-0 blue">Elevated bilirubin levels, resulting from the breakdown of red blood cells, can signal liver or bile duct issues.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item mt-4">
                                        <div className='accordion-header' id="Urobilinogen">
                                            <div
                                                className="medically_card card_title d-flex align-items-center accordion-button collapsed gap-3 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseUrobilinogen" aria-expanded="false" aria-controls="collapseUrobilinogen">
                                                <div className="biomark_circle biomark_circle_12">
                                                </div>
                                                <div>
                                                    <p className='m-0'><b>Urobilinogen</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapseUrobilinogen" class="accordion-collapse collapse" aria-labelledby="Urobilinogen" data-bs-parent="#accordionExample">
                                            <div class="accordion-body ps-3 pt-2 pb-0">
                                                <div className='card_title ps-4'>
                                                    <p className="text-start m-0 blue">Excess urobilinogen in urine may indicate liver diseases.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="key_feature_section pt-4">
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-lg-9 col-md-12 col-sm-12 col-12 mb-lg-0 mb-md-3">
                                <div className="card_title text-start">
                                    <h2 className="mb-lg-5 mb-md-4 mb-4">How does it
                                        work?</h2>
                                </div>
                            </div>
                        </div>
                        <div
                            className="row flex-lg-row flex-md-row flex-column pt-lg-3 g-4 pt-md-0 pt-3">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <img src={Urine} width="100%" height="100%"
                                    alt='' />
                            </div>
                            <div
                                className="col-lg-5 col-md-6 col-sm-12 col-12 d-flex align-items-center offset-lg-1">
                                <div className="card_title">
                                    <h6 className="mb-lg-4 mb-md-4 blue mb-3">1. Urine Test
                                        Strips</h6>
                                    <p className="m-0 text-start">Easy to use color-changing
                                        strips for 12 biomarkers. Dip the test-strip in
                                        a cup with the urine or urinate on the
                                        test-strip. Color changes occur within 90
                                        seconds. </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="row flex-lg-row flex-md-row flex-column-reverse g-4 pt-lg-0 mt-lg-0 mt-md-0 pt-md-0 pt-3 mt-4">
                            <div
                                className="col-lg-5 col-md-6 col-sm-12 col-12 d-flex align-items-center">
                                <div className="card_title">
                                    <h6 className="mb-lg-4 mb-md-4 blue mb-3">2. RenAware test on
                                        SensifyAware App</h6>
                                    <p className="m-0 text-start">Use the RenAware test
                                        feature in the SensifyAware mobile app to scan
                                        test strips directly with your phone. The app
                                        lets you evaluate your renal health, energy
                                        balance, immunity, liver health and
                                        hydration.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 offset-lg-1 mt-0">
                                <img src={Renaware} width="100%"
                                    height="100%" alt='' />
                            </div>
                        </div>
                        <div
                            className="row flex-lg-row flex-md-row flex-column pt-lg-0 pt-md-0 pt-4 mt-0 g-4">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <img src=
                                    {RenawareDevice} width="100%"
                                    height="100%" alt='' />
                            </div>
                            <div
                                className="col-lg-5 col-md-6 col-sm-12 col-12  offset-lg-1 d-flex align-items-center">
                                <div className="card_title">
                                    <h6 className="mb-lg-4 mb-md-4 blue mb-3">3. RenAware
                                        device</h6>
                                    <p className="m-0 text-start">Read the strips using
                                        RenAware reader, for clinical grade results. The
                                        RenAware reader sends results directly to the
                                        IntelliVista Cloud Infrastructure.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-lg-5 mt-md-4 mt-4 pt-lg-3">
                            <div
                                className="col-lg-10 pe-lg-5 col-md-12 col-sm-12 col-12 mb-lg-0 mb-md-3">
                                <div className="card_title pe-lg-4 text-start">
                                    <p className=" text-start mb-0 d-flex gap-4"><b>Note:</b>
                                        <span>RenAware is
                                            designed for self-monitoring
                                            and research use, not for diagnosing
                                            diseases or health conditions.</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="harness_section py-lg-5 py-md-5 py-4">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 col-md-12 col-sm-12 col-12">
                                <div class="card_title text-start">
                                    <h2 class="text-start font-300 mb-4 text-white">Harness the power of multimodal digital analysis using urinary biomarkers, with IntelliVista.</h2>
                                    <Link to='/IntelliVista' class="btn btn-primary bg-white blue w-auto" onClick={() => window.scrollTo(0, 0)}>
                                        Learn more
                                    </Link>
                                    {/* <a href="#" class="btn btn-primary bg-white blue w-auto">Learn more</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="solution py-5">
                    <div className="container my-lg-2">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card_title">
                                    <h2
                                        className="mb-lg-5 mb-md-4 mb-3">Optimize your
                                        lifestyle</h2>
                                    <h6 className="mb-lg-5 mb-md-4 mb-3">Diet, exercise and
                                        hydration are key to wellness<br />
                                        Ready to take control of your health with
                                        RenAware?<br />
                                        Pre-order your kit today and start monitoring
                                        your well-being with confidence.</h6>
                                    <a href="#"
                                        className="btn btn-outline-primary w-auto">Pre-order
                                        your kit NOW</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="solution py-5">
                    <div className="container my-lg-2">
                        <div className="row">
                            <div className="col-lg-11 col-md-12 col-sm-12 col-12 pe-lg-5">
                                <div className="card_title">
                                    <h2
                                        className="mb-0">Harness the power
                                        of multimodal digital analysis by including
                                        urinary biomarkers </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-lg-5 mt-md-4 mt-4 g-lg-4 g-4">
                            <div
                                className="col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-center harness_multimode">
                                <img src={HarnessCollage} alt='' />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div
                                    className="solutions_card solutions_card_two position-relative">
                                    <img src={IntelliVista_card_img}
                                        width="100%" alt='' />
                                    <div className="solution_card_inner p-lg-4 p-md-4 p-3">
                                        <h3 className="mb-2">IntelliVista</h3>
                                        <p className="mb-0">Data management software for
                                            research and organizations.</p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-lg-end justify-content-start">
                                <div className="for_consumer">
                                    <ul>
                                        <li>
                                            For consumers
                                        </li>
                                        <li>
                                            For researchers
                                        </li>
                                        <li>
                                            for physicians
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="solution py-lg-5 py-md-5 py-4">
                    <div className="container my-lg-2">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card_title">
                                    <h2
                                        className="mb-lg-5 mb-md-4 mb-3">Get started
                                        today!</h2>
                                    <h6 className="mb-lg-5 mb-md-4 mb-4 pb-lg-0 pb-md-0 pb-2">Diet, exercise and hydration are key to wellness. Ready to take control of your health with RenAware?
                                        Pre-order your kit today and start monitoring your well-being with confidence.</h6>
                                    <div className="d-lg-flex d-md-flex d-none align-items-center gap-4 flex-wrap flex-lg-row flex-md-row flex-columng">
                                        {/* <a
                                            className="btn btn-outline-primary w-auto"
                                            onClick={openModal}>Pre-order Urine Test strips</a>
                                        <a
                                            className="btn btn-outline-primary w-auto">Pre-order your RenAware device</a> */}
                                        <a className="btn btn-outline-primary w-auto"
                                            onClick={() => {
                                                handleButtonClick('testStrips');
                                                openModal()
                                            }}>Pre-order Urine Test strips</a>
                                        <a className="btn btn-outline-primary w-auto"
                                            onClick={() => {
                                                handleButtonClick('device');
                                                openModal()
                                            }}>Pre-order your RenAware device</a>
                                    </div>
                                    <div className="d-lg-none d-md-none d-flex align-items-center gap-4 flex-wrap flex-lg-row flex-md-row flex-columng">
                                        <a className="btn btn-outline-primary w-auto">Pre-order your kit NOW</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <NewFooter />
            </body>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                className='login_modal'
            >
                <PreOrderModal closeModal={closeModal} modalContent={modalContent} />
            </Modal>
        </>
    )
}

export default RenAware