import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../components/Reuseable Components/Header';
import NewFooter from '../components/Reuseable Components/NewFooter';
import specilization from '../assets/custom-digital-img.png'
import specilization2 from '../assets/custom-digital-img-12.png'
import visual from '../assets/visual_test.png'
import speech from '../assets/speech.png'
import mood from '../assets/mood.png'
import Phone from '../assets/phone1.png'
import balance from '../assets/balance.png'
import map from '../assets/map.png'
import axios from 'axios';
import { message } from "antd";
import ContactFormSection from './Reuseable Components/ContactFormSection';

const CustomDigital = () => {

    return (
        <>
            <body className="renaware" style={{ backgroundColor: "#031A40" }}>
                <Header />
                <section
                    className="banner-section custom_digital scentware_benner renware_banner new_home_banner d-flex align-items-center">
                    <div className="container position-relative" style={{ zIndex: 9 }}>
                        <div className="row g-lg-4 justify-content-center">
                            <div
                                className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                <div className="section_content">
                                    <h2 className="mb-0 font-300">Looking to digitize your</h2>
                                    <h2 className="mb-lg-0 mb-md-4 mb-4 font-300">biomarker for research ?</h2>
                                </div>
                            </div>
                            <div
                                className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-lg-end">
                                <div>
                                    <div className="card_title">
                                        <h6 className="text-white m-0">We offer custom software solutions for large group clinical trials as well as academic research.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="we_are_sensify specialize">
                    <div className="container">
                        <div className="row g-lg-4 g-md-4 g-0 justify-content-center flex-lg-row flex-md-row flex-column">
                            <div
                                className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center">
                                <img src={specilization} className="img-fluid"
                                    alt="" />
                            </div>
                            <div
                                className="col-xl-5 col-lg-5 col-md-12 col-sm-12 co-12 d-flex align-items-center">
                                <div className="card_title">
                                    <h6 className="m-0 text-start text-dark">At Sensify, we specialize in developing bespoke digital biomarker solutions that transform mobile devices into powerful research tools. Whether you’re conducting large-scale clinical trials or detailed academic studies, we offer custom applications designed to meet your specific needs.</h6>
                                </div>
                            </div>
                            <div
                                className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 d-lg-flex d-md-flex d-none align-items-center justify-content-center">
                                <img src={specilization2} className="img-fluid"
                                    alt="" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="key_feature_section solution_custom">
                    <div className="container">
                        <div className="row g-4">
                            <div
                                className="col-lg-11 col-md-12 col-sm-12 col-12 mb-lg-0 mb-md-0">
                                <div className="card_title text-start">
                                    <h2 className="mb-0 font-300">Solutions designed for both Android and iOS devices, ensuring broad accessibility.</h2>
                                </div>
                            </div>
                        </div>
                        <div className='row pt-lg-5 g-4 pt-md-4 pt-4'>
                            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                                <div
                                    className="feature_card card_title justify-content-start text-center py-lg-4 py-md-4 py-3 px-lg-4 px-md-4 px-4">
                                    <img src={visual} alt='' />
                                    <h6 className="text-center my-lg-4 my-md-4 my-2">Visual Tests
                                    </h6>
                                    <p className="m-0 text-center">Color blindness<br />
                                        Contrast Sensitivity<br />
                                        Visual acuity</p>
                                </div>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                                <div
                                    className="feature_card card_title justify-content-start text-center py-lg-4 py-md-4 py-3 px-lg-4 px-md-4 px-4">
                                    <img src={speech} alt='' />
                                    <h6 className="text-center my-lg-4 my-md-4 my-2">Speech and Language Tests</h6>
                                    <p className="m-0 text-center">Speech and language test capabilities are useful in linguistic studies and neurodevelopmental research.</p>
                                </div>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                                <div
                                    className="feature_card card_title justify-content-start text-center py-lg-4 py-md-4 py-3 px-lg-4 px-md-4 px-4">
                                    <img src={mood} alt='' />
                                    <h6 className="text-center my-lg-4 my-md-4 my-2">Mood and Emotion Checks</h6>
                                    <p className="m-0 text-center">Mood and emotion check tests can be integrated into daily monitoring for participants, providing real-time, actionable data.</p>
                                </div>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                                <div
                                    className="feature_card card_title justify-content-start text-center py-lg-4 py-md-4 py-3 px-lg-4 px-md-4 px-4">
                                    <img src={balance} alt='' />
                                    <h6 className="text-center my-lg-4 my-md-4 my-2">Balance</h6>
                                    <p className="m-0 text-center">Balance testing is crucial for studies on motor functions and rehabilitation. These tests assess stability and coordination through interactive tasks performed on mobile devices.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='development_roadmap py-lg-5 py-md-5 py-5'>
                    <div className='container py-lg-5 py-md-4'>
                        <div className="row g-4">
                            <div
                                className="col-lg-11 col-md-12 col-sm-12 col-12 mb-lg-0 mb-md-0">
                                <div className="card_title text-start">
                                    <h2 className="mb-0 font-300 text-white">Development roadmap</h2>
                                </div>
                            </div>
                        </div>
                        <div className='row pt-lg-5 pt-md-5 pt-4 g-3'>
                            <div className='col-lg-3 col-md-4 col-sm-12 col-12 p-lg-4'>
                                <div className='card_title py-lg-0 py-md-0 py-4'>
                                    <div className='dis'>
                                        <h6 className='mb-0'>Step 1</h6>
                                    </div>
                                    <h6 className='py-lg-4 py-md-4 py-2 mb-0 text-white'>Consultation</h6>
                                    <p className='m-0 text-start text-white'>Work with our experts to outline your specific research needs.</p>
                                </div>
                            </div>
                            <div className='col-lg-1 d-lg-flex d-md-none d-none d-flex align-items-center justify-content-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                    <path d="M25.3318 15.5146L33.6008 23.9999M33.6008 23.9999L25.3318 32.4853M33.6008 23.9999H15.4322M4.80078 36L4.80079 11.9998C4.80079 8.02335 8.02434 4.7998 12.0008 4.7998L36.0008 4.79981C39.9772 4.79981 43.2008 8.02336 43.2008 11.9998V36C43.2008 39.9765 39.9772 43.2 36.0008 43.2H12.0008C8.02433 43.2 4.80078 39.9765 4.80078 36Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-12 col-12 p-lg-4'>
                                <div className='card_title py-lg-0 py-md-0 py-4'>
                                    <div className='dis'>
                                        <h6 className='mb-0'>Step 2</h6>
                                    </div>
                                    <h6 className='py-lg-4 py-md-4 py-2 mb-0 text-white'>Development</h6>
                                    <p className='m-0 text-start text-white'>We build a tailored app that integrates seamlessly across Android and iOS platforms.</p>
                                </div>
                            </div>
                            <div className='col-lg-1 d-lg-flex d-md-none d-none d-flex align-items-center justify-content-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                    <path d="M25.3318 15.5146L33.6008 23.9999M33.6008 23.9999L25.3318 32.4853M33.6008 23.9999H15.4322M4.80078 36L4.80079 11.9998C4.80079 8.02335 8.02434 4.7998 12.0008 4.7998L36.0008 4.79981C39.9772 4.79981 43.2008 8.02336 43.2008 11.9998V36C43.2008 39.9765 39.9772 43.2 36.0008 43.2H12.0008C8.02433 43.2 4.80078 39.9765 4.80078 36Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-12 col-12 p-lg-4'>
                                <div className='card_title py-lg-0 py-md-0 py-4'>
                                    <div className='dis'>
                                        <h6 className='mb-0'>Step 3</h6>
                                    </div>
                                    <h6 className='py-lg-4 py-md-4 py-2 mb-0 text-white'>Implementation</h6>
                                    <p className='m-0 text-start text-white'>Deploy your customized app in your study, supported by our full technical support.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="get_in_touch">
                    <div className="container">
                        <div className="row g-lg-3 g-md-3 g-4">
                            <div
                                className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-start">
                                <div className="card_title pe-lg-5 pb-lg-0 pb-md-0 pb-5">
                                    <h2 className="mb-0">Get in touch with us.</h2>
                                    <h6 className='my-lg-5 my-md-4 my-4'>Reach out to start developing your personalized research application today.</h6>
                                    <div
                                        className="innovation_card mt-lg-5 mt-md-4 mt-4 d-flex align-items-center gap-lg-4 gap-md-4 gap-4">
                                        <div style={{ flex: "0 0 auto" }}>
                                            <img src={map} alt="" />
                                        </div>
                                        <div>
                                            <h6 className="text-dark m-0">251 E 32nd St.
                                                7C<br />
                                                NY, 10016</h6>
                                        </div>
                                    </div>
                                    <div
                                        className="innovation_card mt-lg-5 mt-md-4 mt-4 d-flex align-items-center gap-lg-4 gap-md-4 gap-4">
                                        <div style={{ flex: "0 0 auto" }}>
                                            <img src={Phone} alt="" />
                                        </div>
                                        <div>
                                            <h6 className="text-dark m-0">857 272 5666 | 646 285
                                                8736</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 pe-lg-5 col-md-6 col-sm-12 col-12 me-lg-5">
                               
                                <form className="query_form w-100" onSubmit={handleSubmit}>
                                    <div className="form_input mb-3 pb-lg-1">
                                        <input
                                            type="text"
                                            placeholder="Your name"
                                            id='name'
                                            className="form-control"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form_input mb-3 pb-lg-1">
                                        <input
                                            type="text"
                                            placeholder="Organization"
                                            id='organization'
                                            className="form-control"
                                            value={formData.organization}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form_input mb-3 pb-lg-1">
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            id='email'
                                            className="form-control"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form_input mb-3 pb-lg-1">
                                        <textarea
                                            name="challenging"
                                            id='message'
                                            className="form-control"
                                            placeholder="What’s challenging you?"
                                            value={formData.message}
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                    <div className="d-flex align-items-end justify-content-end">
                                        <button className="btn btn-primary" type="submit">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section> */}
                <ContactFormSection />
                <NewFooter />
            </body>
        </>
    )
}

export default CustomDigital