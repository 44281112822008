import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { message } from "antd";
import Phone from '../../assets/phone1.png'
import map from '../../assets/map.png'
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation } from 'react-router-dom';

const initialValues = {
    name: '',
    organization: '',
    email: '',
    message: ''
};

const ContactFormSection = () => {
    const [load, setLoad] = useState(false);
    const location = useLocation();
    const isHomeRoute = location.pathname === '/';
    const isCustomDigitalRoute = location.pathname === '/custom-digital';
    const isOCTDirectRoute = location.pathname === '/oct-direct';

    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required!"),
        organization: Yup.string().required("Organization is required!"),
        email: Yup.string().email("Invalid email address").required("Email is required!"),
        message: Yup.string().required("Message is required!"),
    });


    const handleSubmit = async (values) => {
        setLoad(true)
        try {
            const data = {
                Name: values.name,
                Email: values.email,
                Organisation: values.organization,
                Message: values.message,
            };
            message.open({
                type: "success",
                content: "Thank you for submitting Query",
                style: {
                    marginTop: "150px",
                },
            });
            const headers = {
                "Content-Type": "application/json",
            };
            const response = await axios.post(
                "https://gokepiqof3.execute-api.us-east-1.amazonaws.com/prod/api/admin/contact-form",
                data,
                { headers }
            );
            formik.resetForm();
            setLoad(false)

        } catch (error) {
            console.log("error", error)
            setLoad(false)
        } finally {
            setLoad(false);
        }

    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });
    return (
        <section className="get_in_touch">
            <div className="container">
                <div className="row g-lg-3 g-md-3 g-4">
                    <>
                        {isHomeRoute && (
                            <div
                                className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-start">
                                <div className="card_title pe-lg-5 pb-lg-0 pb-md-0 pb-5">
                                    <h2 className="mb-0 font-300">Let’s get in touch.</h2>
                                    {/* <div
                                        className="innovation_card mt-lg-5 mt-md-4 mt-4 d-flex align-items-center gap-lg-4 gap-md-4 gap-4">
                                        <div style={{ flex: "0 0 auto" }}>
                                            <img src={Map} alt="" />
                                        </div>
                                        <div>
                                            <h6 className="text-dark m-0">251 E 32nd St.
                                                7C<br />
                                                NY, 10016</h6>
                                        </div>
                                    </div>
                                    <div
                                        className="innovation_card mt-lg-5 mt-md-4 mt-4 d-flex align-items-center gap-lg-4 gap-md-4 gap-4">
                                        <div style={{ flex: "0 0 auto" }}>
                                            <img src={Phone} alt="" />
                                        </div>
                                        <div>
                                            <h6 className="text-dark m-0">857 272 5666 | 646 285
                                                8736</h6>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        )}
                        {isCustomDigitalRoute && (
                            <div
                                className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-start">
                                <div className="card_title pe-lg-5 pb-lg-0 pb-md-0 pb-5">
                                    <h2 className="mb-0">Get in touch with us.</h2>
                                    <h6 className='my-lg-5 my-md-4 my-4'>Reach out to start developing your personalized research application today.</h6>
                                    {/* <div
                                        className="innovation_card mt-lg-5 mt-md-4 mt-4 d-flex align-items-center gap-lg-4 gap-md-4 gap-4">
                                        <div style={{ flex: "0 0 auto" }}>
                                            <img src={map} alt="" />
                                        </div>
                                        <div>
                                            <h6 className="text-dark m-0">251 E 32nd St.
                                                7C<br />
                                                NY, 10016</h6>
                                        </div>
                                    </div>
                                    <div
                                        className="innovation_card mt-lg-5 mt-md-4 mt-4 d-flex align-items-center gap-lg-4 gap-md-4 gap-4">
                                        <div style={{ flex: "0 0 auto" }}>
                                            <img src={Phone} alt="" />
                                        </div>
                                        <div>
                                            <h6 className="text-dark m-0">857 272 5666 | 646 285
                                                8736</h6>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        )}

                        {isOCTDirectRoute && (
                            <div
                                className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-start">
                                <div className="card_title pe-lg-5 pb-lg-0 pb-md-0 pb-5">
                                    <h2 className="mb-0">Get in touch with us.</h2>
                                    <h6 className='my-lg-5 my-md-4 my-4'>Contact us today to learn more about OCTDirect and how it can benefit your practice.</h6>
                                    {/* <div
                                        className="innovation_card mt-lg-5 mt-md-4 mt-4 d-flex align-items-center gap-lg-4 gap-md-4 gap-4">
                                        <div style={{ flex: "0 0 auto" }}>
                                            <img src={map} alt="" />
                                        </div>
                                        <div>
                                            <h6 className="text-dark m-0">251 E 32nd St.
                                                7C<br />
                                                NY, 10016</h6>
                                        </div>
                                    </div>
                                    <div
                                        className="innovation_card mt-lg-5 mt-md-4 mt-4 d-flex align-items-center gap-lg-4 gap-md-4 gap-4">
                                        <div style={{ flex: "0 0 auto" }}>
                                            <img src={Phone} alt="" />
                                        </div>
                                        <div>
                                            <h6 className="text-dark m-0">857 272 5666 | 646 285
                                                8736</h6>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        )}
                    </>
                    <div
                        className="col-lg-4 ps-lg-5 col-md-6 col-sm-12 col-12 ms-lg-5">
                        <form className="query_form w-100" onSubmit={formik.handleSubmit}>
                            <div className="form_input mb-3 pb-lg-1">
                                <input
                                    type="text"
                                    placeholder="Your name"
                                    id='name'
                                    className={`form-control ${formik.touched.name && formik.errors.name
                                        ? "is-invalid"
                                        : ""
                                        }`}
                                    name="name"
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                    isValid={formik.isValid}
                                    isTouched={formik.touched.name}
                                    invalidFeedback={formik.errors.name} />
                                <div className="invalid-feedback">
                                    {formik.errors.name}
                                </div>
                            </div>
                            <div className="form_input mb-3 pb-lg-1">
                                <input
                                    type="text"
                                    placeholder="Organization"
                                    id='organization'
                                    className={`form-control ${formik.touched.organization && formik.errors.organization
                                        ? "is-invalid"
                                        : ""
                                        }`}
                                    name="organization"
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.organization}
                                    isValid={formik.isValid}
                                    isTouched={formik.touched.organization}
                                    invalidFeedback={formik.errors.organization} />
                                <div className="invalid-feedback">
                                    {formik.errors.organization}
                                </div>
                            </div>
                            <div className="form_input mb-3 pb-lg-1">
                                <input
                                    type="email"
                                    placeholder="Email"
                                    id='email'
                                    name="email"
                                    className={`form-control ${formik.touched.email && formik.errors.email
                                        ? "is-invalid"
                                        : ""
                                        }`}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    isValid={formik.isValid}
                                    isTouched={formik.touched.email}
                                    invalidFeedback={formik.errors.email} />
                                <div className="invalid-feedback">
                                    {formik.errors.email}
                                </div>
                            </div>
                            <div className="form_input mb-3 pb-lg-1">
                                <textarea
                                    type='text'
                                    name="message"
                                    id='message'
                                    placeholder="Your message"
                                    className={`form-control ${formik.touched.message && formik.errors.message
                                        ? "is-invalid"
                                        : ""
                                        }`}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.message}
                                    isValid={formik.isValid}
                                    isTouched={formik.touched.message}
                                    invalidFeedback={formik.errors.message}
                                ></textarea>
                                <div className="invalid-feedback">
                                    {formik.errors.message}
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-end">
                                <button className="btn btn-primary" type="submit">
                                    {load ? (
                                        <div className='w-100 align-items-center d-flex justify-content-center'>
                                            <h1
                                                class="loader"
                                                disabled={load}
                                                style={{
                                                    // color: "#101A36",
                                                    margin: "-5px",
                                                    width: '30px',
                                                    height: '30px',
                                                }}
                                            ></h1>
                                        </div>
                                    ) : (
                                        "Submit"
                                    )}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactFormSection